import React, { useEffect, useState, useContext } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { useStyles } from "../../../assets/styles/CustomStyles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import InputBase from "@material-ui/core/InputBase"
import axios from "../../../utils/axios";
import {
  notificacionError,
  alertWarningError,
  notificacionWarning,
} from "../../Notificaciones";
import IconButton from '@material-ui/core/IconButton';
import Search from "@material-ui/icons/Search";
import BuscadorClienteDialog from "../../BuscadorClienteDialog";
import RemisionContext from "../../../utils/remisionContext/RemisionContext";
import CircularProgress from "@material-ui/core/CircularProgress";

const initialValue = {
  destinatario: {
    id: "",
    nroContrato: "",
    idcliente: "",
    ruc: "",
    nombre: "",
    direccion: "",
  }

};

export default function Destinatario(props) {
 const { textContrato, textRuc} = props?.select;

 console.log(textRuc)
  const classes = useStyles();
  const remisionContext = useContext(RemisionContext);
  const [contratos, setContratos] = useState({
    content: [],
  });

  const [inputValues, setInputValues] = useState(initialValue);
  const [isLoadingCliente, setIsLoadiongCliente] = useState(false);
  const [isLoadingContratos, setIsLoadingContratos] = useState(false);
  const [openCliente, setOpenCliente] = useState(false);

  useEffect(() => {
    setInputValues(remisionContext.cabeceraDestino);
    getContratos()
    // se utiliza para actualizar input values cuando el contexto ha sido actualizado
  }, []);

  const getContratos = async (props) => {
    setIsLoadingContratos(true);
    let url = props ? props : "contrato/funListadoGeneral.php?estado=activo";
    try {
      const response = await axios.get(url);
      let status = response.status
      if (status === 200) {
        const contrato = response.data;

        setContratos({ ...contratos, content: contrato });

        setIsLoadingContratos(false);
      }

    } catch (error) {
      setIsLoadingContratos(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    }
  };


  const onSelectContrato = (e, value) => {
   
    if (value?.nroContrato !== inputValues.destinatario.nroContrato) {
      let copyInput = {
        ...inputValues,
        destinatario: {
          id: value?.id,
          nroContrato: value?.nroContrato,
          idcliente: value?.cliente,
          ruc: value?.ruc,
          nombre: value?.nombre,
          direccion: value?.direccion
        },
      };
      setInputValues(copyInput);
      remisionContext.updateRemisionCabeceraDestino(copyInput);
    }
    if(value === null){
      let copyInput = {
        ...inputValues,
        destinatario: {
          id: "",
          nroContrato: "",
          idcliente: "",
          ruc: "",
          nombre: "",
          direccion: ""
        },
      };
      setInputValues(copyInput);
      remisionContext.updateRemisionCabeceraDestino(copyInput);
    }
  };


  const handleChangeCliente = (value) => {
    let copyInput = {
      ...inputValues,
      destinatario: {
        ruc: value.target.value,
      },
    };

    setInputValues(copyInput);
    remisionContext.updateRemisionCabeceraDestino(copyInput)
  };


  const handleCliente = (value) => {

    let copyInput = {
      ...inputValues,
      destinatario: {
        id: null,
        nroContrato: null,
        idcliente: value?.id,
        ruc: value?.ruc,
        nombre: value?.nombre,
        direccion: value?.direccion
      },
    };

    setInputValues(copyInput);
    remisionContext.updateRemisionCabeceraDestino(copyInput)
  };


  const loadCliente = async () => {
    let ruc = inputValues.destinatario.ruc;
    if (ruc) {
      try {
        setIsLoadiongCliente(true);

        const response = await axios.get(`cliente/funConsultaIndividual.php?ruc=${ruc}`);

        let data = response.data;
        let status = response.status
        if (status === 200) {

          if (data.length < 0) {
            notificacionWarning("No se encontró el cliente con código " + ruc);
            return;
          }

          if (data.length > 1) {
            notificacionWarning("Se encontró más de un resultado para el cliente con código " + ruc);
            return;
          }

          // document.getElementById("nroContrato").focus();
          handleCliente(data[0]);
        }


      } catch (error) {
        if (error.response) {
          alertWarningError(error.response);
        }
      } finally {
        setIsLoadiongCliente(false);
      }
    } else {
      
      let copyInput = {
        ...inputValues,
        destinatario: {
          id: "",
          nroContrato: "",
          idcliente: "",
          ruc: "",
          nombre: "",
          direccion: ""
        },
      };
      setInputValues(copyInput);
      remisionContext.updateRemisionCabeceraDestino(copyInput)

    }
  };

  const handleCloseCliente = (value) => {
    setOpenCliente(false);
  };
  const handleOpenCliente = () => {
    setOpenCliente(true);
  };
  return (
    <>
      <Paper className={classes.padding2}>
        <Grid container direction="row" justify="center" spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Paper className={classes.rootPaper}>
                  <InputBase
                    className={classes.input}
                    id="ruc"
                    name="ruc"
                    placeholder="RUC/CI"
                    fullWidth
                    // autoFocus
                    disabled={inputValues.destinatario.nroContrato ? true : false}

                    onChange={(e) => handleChangeCliente(e)}
                    value={
                      inputValues.destinatario.ruc && inputValues.destinatario.ruc
                    }
                    onBlur={() => loadCliente()}
                    inputProps={{
                      "aria-label": "ruc",
                    }}
                  />
                 {isLoadingCliente ? (
                    <CircularProgress color="primary" size={20} />
                  ) : (
                    <IconButton
                    disabled={inputValues.destinatario.nroContrato ? true : false}
                      style={{
                        color: "#396b99",
                        textAlign: "center",
                        minWidth: 45,
                        padding: "1px 8px 1px 8px",
                      }}
                      className={classes.iconButton}
                      aria-label="search"
                      onClick={() => handleOpenCliente()}
                    >
                      <Search />
                    </IconButton>
                  )}

                </Paper>

              </Grid>


              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <TextField
                  id="DENOMINACION"
                  label="DENOMINACION"
                  name="DENOMINACION"
                  variant="outlined"
                  color="secondary"
                  error={textRuc}
                  helperText={textRuc ? "Campo obligatorio" : null}
                  InputProps={{
                    readOnly: inputValues.destinatario.nroContrato ? true : false,
                  }}
                  value={
                    inputValues.destinatario.nombre &&
                    inputValues.destinatario.nombre
                  }
                  fullWidth
                  size="small"
                />
              </Grid>


              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  id="DOMICILIO"
                  label="DOMICILIO"
                  name="DIRECCION"
                  variant="outlined"
                  color="secondary"
                  error={textRuc}
                  helperText={textRuc ? "Campo obligatorio" : null}
                  InputProps={{
                    readOnly: inputValues.destinatario.nroContrato ? true : false,
                  }}

                  value={
                    inputValues.destinatario.direccion && inputValues.destinatario.direccion
                  }
                  fullWidth
                  size="small"
                />

              </Grid>


  

            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid container spacing={2} justify="center" alignItems="center">

 
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Autocomplete
                  fullWidth
                  
                  value={inputValues.destinatario || "" }
                  onChange={onSelectContrato}
                  size="small"
                  id="CONTRATO"
                  options={contratos.content}
                  getOptionLabel={(option) => option.nroContrato && option.nroContrato  }
                  //getOptionSelected={(option, value) => option.nroContrato === value.nroContrato}
                  loading={isLoadingContratos}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        required
                        label="NRO. CONTRATO"
                        variant="outlined"
                        color="secondary"
                        error={textContrato}
                         helperText={
                           textContrato ? "Campo obligatorio" : null
                          }
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isLoadingContratos ? (
                                <CircularProgress color="secondary" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    );
                  }}
                />
              </Grid>
 
            </Grid>
          </Grid>
        </Grid>
      </Paper>


      <BuscadorClienteDialog
        open={openCliente}
        onClose={handleCloseCliente}
        onSetData={handleCliente}
      />
    </>
  );
}
