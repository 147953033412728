import React, { useState, useEffect } from "react";
import UsersContext from "../user/UserContext";
import { getUserPrincipal, logout } from "../user/UserService";

const defaultState = {
  nombreUsu: "",
  apellidoUsu: "",
  correo: "",
  accesos: [],
};

const UserProvider = ({ children }) => {
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    updateUser();
  }, []);

  const updateUser = async () => {
    let user = getUserPrincipal();
    if (user) {
      setState({
        nombreUsu: user.nombreUsu,
        apellidoUsu: user.apellidoUsu,
        correo: user.correo,
        accesos: user.accesos,
      });
    } else{
      setState(defaultState);
    }
  };

  const clearUser = () => {
    setState(defaultState);
    logout();
  };

  return (
    <UsersContext.Provider value={{ state, updateUser, clearUser }}>
      {children}
    </UsersContext.Provider>
  );
};

export default UserProvider;
