import React, { useEffect, useState, useContext } from "react";
import { MakeTables } from "../../components/MaterialTables/MakeTables";
import { useStyles } from "../../assets/styles/CustomStyles";
import { useHistory } from "react-router-dom";
import axios from "../../utils/axios";
import { Typography } from "@material-ui/core";
import { alertWarningError, alertaAceptarCancelar } from "../../components/Notificaciones";
import BackdropCustom from "../../components/BackdropCustom";
import IconButton from '@material-ui/core/IconButton';
import Add from '@material-ui/icons/AddBox';
import Tooltip from '@material-ui/core/Tooltip';
import AccesoDenegado from "../../components/AccesoDenegado";
import UserContext from "../../utils/user/UserContext";

export default function RemisionListaRemitidos() {
    const history = useHistory();
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({ content: [] });
    const userContext = useContext(UserContext);

    useEffect(() => {
        getRemisiones()
    }, []);

    const getRemisiones = async (props) => {
        setIsLoading(true);
        let url = props ? props : "remision/funListadoGeneral.php?estado=remitido";
        try {
            const response = await axios.get(url);

            const remisiones = response.data;
            setData({ ...data, content: remisiones });

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            if (error.response) {
                alertWarningError(error.response);
            }
        }
    };

    const title = (
        <>
            <Typography variant="body1" display="block">
                LISTA NOTA DE REMISIONES REMITIDAS
                <Tooltip title="Nueva Remisión">
                    <IconButton aria-label="delete" className={classes.button} onClick={() => history.push("./generar/")} >
                        <Add fontSize="inherit" color="action" />
                    </IconButton>
                </Tooltip>
            </Typography>

        </>
    );
    const columns = [
        {
            title: "Fecha",
            field: "fecha_activacion",
            width: "10%",
        },
        {
            title: "N° Remisión",
            field: "nro_factura",
            width: "15%"
        },
        {
            title: "Denominación",
            field: "nombre",

        },
        {
            title: "RUC/CI",
            field: "ruc",
            width: "15%"

        },
        {
            title: "N° Contrato",
            field: "nro_contrato",
            width: "15%"

        },
        {
            title: "N° Factura",
            field: "nro_factura",
            width: "15%"

        },


    ];


    const options = {
        filtering: false,
        exportButton: false,
        exportAllData: false,
        headerStyle: { position: "sticky", top: 0 },
        maxBodyHeight: "65vh",
        paging: true,
        draggable: false,
        rowStyle: {
            fontSize: "small",
        },
    };

    const anular = (event, props) => {
        alertaAceptarCancelar({
            text:
                `¿Estás seguro de anular la Nota de Remisión N° ${props.data?.id}?`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await axios.delete(`/remision/funAnular.php?id=` + Number(props.data.id));
                    let status = response.status
                    if (status === 200) {
                        getRemisiones()
                    }

                } catch (error) {
                    console.log(error);
                    if (error.response) {
                        alertWarningError(error.response);
                    }
                }
            }
        });
    }
    const detalle = (event, props) => {
        //corta el evento el onRowClick
        event.stopPropagation();
        history.push("./detalle/", {
            id: props.data.id,
        });
    };

    const actions = [
        {
            icon: "save",
            tooltip: "place-holder",
            onClick: (event, rowData) => alert("You saved " + rowData.name),
            hidden: true,
        },
        {
            icon: "save",
            tooltip: "FreeActions-place-holder",
            isFreeAction: true,
            onClick: (event, rowData) => alert("You saved " + rowData.name),
            hidden: true,
        },
    ];


    return (
        <>
            {Number(userContext.state.accesos.remision) === 1 ?
                <>

                    <MakeTables
                        //onRowClick={detalle}
                        title={title}
                        columns={columns}
                        data={data?.content}
                        actions={actions}
                        isLoading={isLoading}
                        classes={classes}
                        options={options}
                        componentsAssets={{ classes, detalle, anular }}
                    />

                </>
                : <AccesoDenegado />}

        </>
    );
}
