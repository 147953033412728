import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "../../assets/styles/CustomStyles";
import { BotonVerde, BotonGris } from "../../assets/styles/StyledButtons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import { useForm } from "react-hook-form";
import {
    notificacionError,
    alertWarningError,
    notificacionExitosa,
    notificacionExitosaV2,
} from "../../components/Notificaciones";
import axios from "../../utils/axios";
import TypographyBold from "../../components/TypographyBold"
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import BackdropCustom from "../../components/BackdropCustom";
import Box from "@material-ui/core/Box";
import CardActions from '@material-ui/core/CardActions';
import SaveIcon from '@material-ui/icons/Save';
import Button from "@material-ui/core/Button";
import CardHeader from '@material-ui/core/CardHeader';
import Close from "@material-ui/icons/Close";
import IconButton from '@material-ui/core/IconButton';
import Paper from "@material-ui/core/Paper";
import AccesoDenegado from "../../components/AccesoDenegado";
import UserContext from "../../utils/user/UserContext";

const initialValue = {
    nombreUsu: "",
    apellidoUsu: "",
    nombre: "",
    pass: "",
    correo: "",
    accesos: {
        contrato: false,
        remision: false,
        facturacion: false,
        administracion: false,
        reportes: false,
        stock: false,
    },
};

export default function AgregarUsuario() {
    const classes = useStyles();
    const history = useHistory();
    const { register, handleSubmit } = useForm();
    const [inputValues, setInputValues] = useState(initialValue);
    const [isLoading, setIsLoading] = useState(false);
    const userContext = useContext(UserContext);

    useEffect(() => {

    }, []);

    const { contrato, remision, facturacion, administracion, reportes, stock } = inputValues.accesos;
    const handleChangeAccesos = (event) => {
        console.log(event.target.name, event.target.checked)

        let copyInput = {
            ...inputValues,
            accesos: { ...inputValues.accesos, [event.target.name]: event.target.checked },
        };
        setInputValues(copyInput);
    };


    const handleNombre = (event) => {
        if (
            event.target.value &&
            event.target.value !== inputValues.nombreUsu
        ) {
            let copyInput = {
                ...inputValues,
                nombreUsu: event.target.value.toUpperCase(),
            };
            setInputValues(copyInput);
        }
    };

    const handleApellido = (event) => {
        if (
            event.target.value &&
            event.target.value !== inputValues.apellidoUsu
        ) {
            let copyInput = {
                ...inputValues,
                apellidoUsu: event.target.value.toUpperCase(),
            };
            setInputValues(copyInput);
        }
    };

    const handleUsuario = (event) => {
        if (
            event.target.value &&
            event.target.value !== inputValues.nombre
        ) {
            let copyInput = {
                ...inputValues,
                nombre: event.target.value.toUpperCase(),
            };
            setInputValues(copyInput);
        }
    };

    const handlePass = (event) => {
        if (
            event.target.value &&
            event.target.value !== inputValues.pass
        ) {
            let copyInput = {
                ...inputValues,
                pass: event.target.value.toUpperCase(),
            };
            setInputValues(copyInput);
        }
    };

    const handleCorreo = (event) => {
        if (
            event.target.value &&
            event.target.value !== inputValues.correo
        ) {
            let copyInput = {
                ...inputValues,
                correo: event.target.value.toUpperCase(),
            };
            setInputValues(copyInput);
        }
    };


    const handleGuardar = async () => {
        console.log(inputValues)

        try {

            const response = await axios.post("sesiones/funAgregar.php", inputValues);

            const status = response.status;
            if (status === 200) {
                notificacionExitosaV2({
                    text: "Usuario agregado exitosamente.",
                    afterWork: () => {
                        history.goBack();
                    },
                });
            }
        } catch (error) {
            if (error.response) {
                alertWarningError(error.response);
            } else {
                notificacionError("Por favor vuelva a intentarlo...");
            }
        }
    };
    return (
        <>
            {Number(userContext.state.accesos.administracion) === 1 ?
                <>
                    <BackdropCustom open={isLoading} />
                    <Grid
                        container
                        direction="row"
                        alignItems="flex-start"
                        justify="center"
                        style={{ marginBottom: 10 }}
                    >
                        <Card className={classes.root}>
                            <CardHeader
                                action={<>

                                    <IconButton aria-label="exit" onClick={(e) => {
                                        history.goBack();
                                    }}>
                                        <Close />
                                    </IconButton>

                                </>

                                }
                                title={<Typography variant="body1" >
                                    NUEVO USUARIO
                                </Typography>}
                            />
                            <CardContent>
                                <Paper >
                                    <Box p={1} mb={2} >
                                        <Box ml={1} >
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Grid container direction="row" spacing={2}>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                        <TextField
                                                            variant="outlined"
                                                            id="nombre"
                                                            name="nombre"
                                                            label="NOMBRE"
                                                            type="text"
                                                            defaultValue={inputValues.nombre && ""}
                                                            onChange={handleNombre}
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                        <TextField
                                                            variant="outlined"
                                                            id="apellido"
                                                            name="apellido"
                                                            label="APELLIDO"
                                                            type="text"
                                                            onChange={handleApellido}
                                                            fullWidth
                                                            size="small"
                                                        />

                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                                        <TextField
                                                            label="USUARIO"
                                                            id="usuario"
                                                            name="usuario"
                                                            variant="outlined"
                                                            color="secondary"
                                                            onChange={handleUsuario}
                                                            fullWidth
                                                            size="small"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                        <TextField
                                                            label="CONTRASEÑA"
                                                            id="pass"
                                                            name="pass"
                                                            variant="outlined"
                                                            color="secondary"
                                                            type="password"
                                                            onChange={handlePass}
                                                            fullWidth
                                                            size="small"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                                        <TextField
                                                            label="CORREO"
                                                            id="correo"
                                                            name="correo"
                                                            variant="outlined"
                                                            color="secondary"
                                                            onChange={handleCorreo}
                                                            fullWidth
                                                            size="small"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <TypographyBold variant="body2" display="block" color="textSecondary">
                                                            ACCESOS
                                                        </TypographyBold>
                                                        <FormControl component="fieldset" className={classes.formControl}>
                                                            <FormGroup row>
                                                                <FormControlLabel
                                                                    control={<Checkbox checked={contrato} onChange={handleChangeAccesos} name="contrato" />}
                                                                    label="Contrato"
                                                                />
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={remision}
                                                                            onChange={handleChangeAccesos}
                                                                            name="remision" />}
                                                                    label="Nota de Remisión"
                                                                />
                                                                <FormControlLabel
                                                                    control={<Checkbox checked={facturacion} onChange={handleChangeAccesos} name="facturacion" />}
                                                                    label="Facturación"
                                                                />
                                                                <FormControlLabel
                                                                    control={<Checkbox checked={administracion} onChange={handleChangeAccesos} name="administracion" />}
                                                                    label="Administración"
                                                                />
                                                                <FormControlLabel
                                                                    control={<Checkbox checked={stock} onChange={handleChangeAccesos} name="stock" />}
                                                                    label="Stock"
                                                                />
                                                                <FormControlLabel
                                                                    control={<Checkbox checked={reportes} onChange={handleChangeAccesos} name="reportes" />}
                                                                    label="Reportes"
                                                                />
                                                            </FormGroup>
                                                        </FormControl>

                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Paper>


                            </CardContent>
                            <CardActions >
                                <Box p={1} className={classes.botonDerecha}>
                                    <Button
                                        size="small"
                                        color="secondary"

                                        variant="contained"
                                        startIcon={<SaveIcon />}
                                        onClick={() => handleGuardar()}

                                    >
                                        GUARDAR
                                    </Button>
                                </Box>

                            </CardActions>
                        </Card>
                    </Grid>
                </>
                : <AccesoDenegado />}
        </>
    );
}
