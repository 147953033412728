import React, { useState } from "react";
import FacturacionContext from "../facturacionContext/FacturacionContext";

const defaultCabecera = {
  idRemision: "",
  cliente: "",
  ruc: "",
  direccion: "",
  nombre: "",
  nro_factura: "",
  nroContrato:"",
  idContrato: "",
  timbrado: "",
  nro_remision: "",
  nro_remision_impresion: "",
  fecha: "",
  iva_5: "0",
  iva_10: "0",
  total: "",
  tipo_de_pago: "CREDITO",
  estado: ""
};

const defaultCabeceraMaxItem = {
  maxItem: 50
}
const defaultDetalle = {
  dataDetalle: [],
};

const FacturacionProvider = ({ children }) => {
  const [cabecera, setCabecera] = useState(defaultCabecera);
  const [cabeceraMaxItem, setCabeceraMaxItem] = useState(defaultCabeceraMaxItem);
  const [detalle, setDetalle] = useState(defaultDetalle);

  const updateFacturaCabecera = (props) => {
    if (props) {
      setCabecera(props);
    }
  };

  const updateFacturaCabeceraMaxItem = (props) => {
    if (props) {
      setCabeceraMaxItem(props);
    }
  };


  const updateFacturaDetalle = (props) => {
    if (props) {
      setDetalle({ ...detalle, dataDetalle: props });
    }
  };

  const clearFactura = () => {
    setCabecera(defaultCabecera);
    setCabeceraMaxItem(defaultCabeceraMaxItem);
    setDetalle(defaultDetalle);
  };

  return (
    <FacturacionContext.Provider
      value={{
        cabecera,
        cabeceraMaxItem,
        detalle,
        updateFacturaCabecera,
        updateFacturaCabeceraMaxItem,
        updateFacturaDetalle,
        clearFactura,
      }}
    >
      {children}
    </FacturacionContext.Provider>
  );
};

export default FacturacionProvider;
