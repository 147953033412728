import SearchIcon from '@material-ui/icons/Search';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import VisibilityOffIcon from '@material-ui/icons/PersonAddDisabled';
import React from 'react';

export function Detalle() {
  return <SearchIcon style={{ color: '#43a047' }} />;
}

export function Editar() {
  return <Edit style={{ color: '#43a047' }} />;
}

export function Eliminar() {
  return <Delete style={{ color: '#EE273E' }} />;
}

export function Desactivar() {
  return <VisibilityOffIcon style={{ color: '#EE273E' }} />;
}
