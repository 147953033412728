import { useHistory } from "react-router-dom";
import React, { useEffect, useState, useContext } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "../../assets/styles/CustomStyles";
import axios from "../../utils/axios";
import Box from "@material-ui/core/Box";
import {
  notificacionAlerta,
  alertWarningError,
  notificacionExitosaV2
} from "../../components/Notificaciones";
import MaterialTable from "material-table";
import Localizacion from "../../components/MaterialTables/Localization";
import TableIcons from "../../components/MaterialTables/TableIcons";
import Chip from "@material-ui/core/Chip";
import Close from "@material-ui/icons/Close";
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import CardActions from '@material-ui/core/CardActions';
import SaveIcon from '@material-ui/icons/Save';
import Button from "@material-ui/core/Button";
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import DialogNuevoTimbrado from "../../components/DialogNuevoTimbrado";
import DialogBajaTimbrado from "../../components/DialogBajaTimbrado";
import AccesoDenegado from "../../components/AccesoDenegado";
import UserContext from "../../utils/user/UserContext";

const tableColumns = [
  { field: "idtimbrado_remision", title: "ID", width: "1%", hidden: true },
  { field: "nro_timbrado", title: "N° Timbrado", width: "10%" },
  { field: "inicio_numeracion", title: "N° Inicio", width: "5%" },
  { field: "final_numeracion", title: "N° Final", width: "5%" },
  { field: "vigencia_desde", title: "Vigencia Desde", width: "10%" },
  { field: "vigencia_hasta", title: "Vigencia Hasta", width: "10%" },
  { field: "numeracion_actual", title: "N° Actual", width: "5%" },
  { field: "ruc", title: "RUC", width: "10%" },
  { field: "cant_minima_facturas", title: "Cantidad min.", width: "10%" },
  { field: "datos_imprenta", title: "Datos imprenta",  },
  {
    field: "estado", title: "Estado",
    render: rowData => rowData.estado === "activo" ? <Chip color="primary" size="small" label="ACTIVO" /> : <Chip size="small" label="INACTIVO" />,
    width: "10%"
  },
];

const TimbradoRemision = () => {
  const history = useHistory();
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openBaja, setOpenBaja] = useState(false);
  const [timbrado, setTimbrado] = useState([]);
  const userContext = useContext(UserContext);
  const localization = Localizacion;
  const tableIcons = TableIcons;

  useEffect(() => {

    if (Number(userContext.state.accesos.administracion) === 1) {
      getTimbrado();
    }
  }, []);

  const getTimbrado = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get("timbradoRemision/funHistorial.php");

      let status = response.status
      if (status === 200) {
        setTimbrado(response.data);
      }

    } catch (error) {
      if (error.response) {
        alertWarningError(error.response);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenTimbrado = () => {
    setOpen(true);
  };

  const handleCloseTimbrado = (value) => {
    setOpen(false);
  };



  const handleOpenTimbradoBaja = () => {
    setOpenBaja(true);
  };

  const handleCloseTimbradoBaja = (value) => {
    setOpenBaja(false);
  };


  const handleGenerarModelo = async () => {
    let tokenCookies = sessionStorage.getItem("jwt-jr");

    let url = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}extensiones/tcpdf/pdf/vRemisionTimbrado.php?p=${JSON.parse(tokenCookies).token}`
    var win = window.open(url, '_blank');
    win.focus();
  }

  const handleTimbradoNuevo = async (value) => {
    console.log(value)
    setIsLoading(true)
    try {
      const response = await axios.post("timbradoRemision/funAgregar.php", value);

      let status = response.status
      if (status === 200) {
        setIsLoading(false)
        notificacionExitosaV2({
          text: "¡Timnbrado asignado con exito!",
          afterWork: () => {
            getTimbrado()
          },
        });
        handleGenerarModelo()
      }
    } catch (error) {
      setIsLoading(false)
      if (error) {
        notificacionAlerta(error);
      }
    }

  }

  const handleTimbradoBaja = async (value) => {
    //solo debe enviar nro timbrado
    console.log(value)
    setIsLoading(true)
    try {
      const response = await axios.post("timbradoRemision/funBajaEspecifico.php", value);

      let status = response.status
      if (status === 200) {
        setIsLoading(false)
        notificacionExitosaV2({
          text: "¡Baja de timnbrado con exito!",
          afterWork: () => {
            getTimbrado()
          },
        });
      }
    } catch (error) {
      setIsLoading(false)
      if (error) {
        notificacionAlerta(error);
      }
    }
  }
  return (
    <>
      {Number(userContext.state.accesos.administracion) === 1 ?
        <>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ marginBottom: 10 }}

          >
            <Card className={classes.root}>
              <CardHeader
                action={<>

                  <IconButton aria-label="exit" onClick={(e) => {
                    history.goBack();
                  }}>
                    <Close />
                  </IconButton>

                </>

                }
                title={<Typography variant="body1" >
                  ADMINISTRACION DE TIMBRADO DE NOTA DE REMISION
                </Typography>}
              />
              <CardContent >
                <Box mt={1}>
                  <MaterialTable

                    columns={tableColumns}
                    data={timbrado}
                    localization={localization}
                    icons={tableIcons}
                    isLoading={isLoading}
                    options={{
                      toolbar: false,
                      filtering: false,
                      search: false,
                      headerStyle: { position: "sticky", top: 0 },
                      maxBodyHeight: "55vh",
                      paging: false,
                      draggable: false,
                      rowStyle: {
                        fontSize: "small",
                      },
                    }}

                  />
                </Box>
              </CardContent>
              <CardActions >
                <Box className={classes.botonDerecha}>
                  <Button
                    size="small"
                    color="primary"
                    className={classes.buttonFiltro}
                    variant="contained"
                    startIcon={<SaveIcon />}
                    onClick={() => handleOpenTimbrado()}

                  >
                    NUEVO TIMBRADO
                  </Button>
                  <Button
                    size="small"
                    color="secondary"
                    className={classes.buttonFiltro}
                    variant="contained"
                    startIcon={<AssignmentReturnedIcon />}
                    onClick={() => handleOpenTimbradoBaja()}

                  >
                    BAJA DE TIMBRADO
                  </Button>
                </Box>
              </CardActions>


            </Card>
          </Grid>
          <DialogNuevoTimbrado
            open={open}
            onClose={handleCloseTimbrado}
            onSetData={handleTimbradoNuevo}
          />

          <DialogBajaTimbrado
            open={openBaja}
            onClose={handleCloseTimbradoBaja}
            onSetData={handleTimbradoBaja}
          />
        </>

        : <AccesoDenegado />}
    </>
  );
};

export default TimbradoRemision;
