import React, { useContext, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Destinatario from "./Destinatario";
import Detalle from "./Detalle";
import Traslado from "./Traslado";
import Transporte from "./Transporte";

import {
  notificacionError,
  alertWarningError,
} from "../../Notificaciones";
import { BotonGris } from "../../../assets/styles/StyledButtons";
import RemisionContext from "../../../utils/remisionContext/RemisionContext";
import { useStyles } from "../../../assets/styles/CustomStyles";
import Alert from "@material-ui/lab/Alert";

import ExitToApp from "@material-ui/icons/ExitToApp";
import axios from "../../../utils/axios";
import pdfConverter from "../../../utils/pdfConverter";
import { useHistory } from "react-router-dom";
import BackdropCustom from "../../BackdropCustom";
import DetalleLibre from "./DetalleLibre";

const defaultValue = {
  textValue: {
    textContrato: false,
    textRuc: false,
    //textComprobanteVenta: false,
    //textNroComprobanteVenta: false,
    textFechaInicioTraslado: false,
    textFechaFinTraslado: false,
    textDireccionPartida: false,
    textCiudadPartida: false,
    textDepartamentoPartida: false,
    textDireccionLlegada: false,
    textCiudadLlegada: false,
    textDepartamentoLlegada: false,
    textMarcaVehiculo: false,
    textRua: false,
    textNombreTrasporte: false,
    textRucTrasporte: false
  },
};
export default function StepperRemision() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const remisionContext = useContext(RemisionContext);
  const [textValue, setTextValue] = useState(defaultValue);
  const steps = getSteps();
  const history = useHistory();
  const [claveRemision, setClaveRemision] = useState("")
  const [isLoading, setIsLoading] = useState(false);
  const [nroNotaRemision, setNroNotaRemision] = useState();

  function getSteps() {
    return [
      "Destinatario de la mercadería",
      "Datos del traslado",
      "Datos del transporte",
      "Detalle",
      "Confirmar",
      "Impresión",
    ];
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return DestinatarioStep(textValue);
      case 1:
        return TrasladoStep(textValue);
      case 2:
        return TransporteStep(textValue);
      case 3:
        return DetalleStep();
      case 4:
        return Confirmar();
      case 5:
        return Impresion();
      default:
        return "";
    }
  }

  const DestinatarioStep = (props) => {
    return (
      <>
        <Destinatario
          select={props}
        />
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          className={classes.button}
          size="small"
        >
          Anterior
        </Button>
        <Button variant="contained" color="primary" size="small" onClick={handleNextDestinatario}>
          Siguiente
        </Button>
      </>
    );
  };

  const TrasladoStep = (props) => {
    return (
      <>
        <Traslado select={props} />
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          className={classes.button}
          size="small"
        >
          Anterior
        </Button>
        <Button variant="contained" color="primary" size="small" onClick={handleNextTraslado}>
          Siguiente
        </Button>
      </>
    );
  };

  const TransporteStep = (props) => {
    return (
      <>
        <Transporte select={props} />
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          className={classes.button}
          size="small"
        >
          Anterior
        </Button>
        <Button variant="contained" color="primary" size="small" onClick={handleNextTrasporte}>
          Siguiente
        </Button>
      </>
    );
  };

  const DetalleStep = () => {
    console.log(remisionContext.cabeceraDestino.destinatario.nroContrato)
    return (
      <>
       {remisionContext.cabeceraDestino.destinatario.nroContrato != null ? <Detalle /> : <DetalleLibre />}
  
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          className={classes.button}
          size="small"
        >
          Anterior
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleGuardarRemision}
          size="small"
        >
          Guardar Remisión
        </Button>

        <Button
          variant="contained"
          color="primary"
          style={{float:"right", marginTop:9}}
          onClick={handleVistaPrevia}
          size="small"
        >
        Vista Previa
        </Button>
      </>
    );
  };

  const Confirmar = () => {
    return (
      <div>
        <Typography variant="h6" component="h6">
          <Alert severity="success">Se guardo exitosamente la remisión Nro. {claveRemision}</Alert>
        </Typography>
        <Button onClick={handleReset} size="small">Cargar nueva remisión</Button>

        <Button
          onClick={() => handleConfirmar()}
          variant="contained"
          color="primary"
          size="small"
        >
          CONFIRMAR REMISIÓN
        </Button>
        <BotonGris
          startIcon={<ExitToApp />}
          onClick={(e) => {
            history.goBack();
          }}
          children="SALIR"
        />
      </div>
    );
  };
  const Impresion = () => {
    return (
      <div>
        <Typography variant="h6" component="h6">
          <Alert severity="success">Se confirmó exitosamente la remisión Nro. {nroNotaRemision}</Alert>
        </Typography>

        <Button
          onClick={() => handleGenerarImpresion()}
          variant="contained"
          color="primary"
          size="small"
        >
          IMPRIMIR REMISIÓN
        </Button>
        <BotonGris
          startIcon={<ExitToApp />}
          onClick={(e) => {
            history.goBack();
          }}
          children="SALIR"
        />
      </div>
    );
  };

  const handleNextDestinatario = () => {
    if (
      remisionContext.cabeceraDestino.destinatario.ruc !== ""
    ) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    //validamos cada input requerido

    let copy = { ...textValue };


    if (remisionContext.cabeceraDestino.destinatario.ruc === "") {
      copy.textRuc = true;
    } else {
      copy.textRuc = false;
    }

    setTextValue(copy);

  };

  const handleNextTraslado = () => {
    if (
      remisionContext.cabeceraTraslado.comprobante_de_venta !== "" &&
      remisionContext.cabeceraTraslado.nro_comprobante_de_venta !== "" &&
      remisionContext.cabeceraTraslado.fecha_de_inicio_de_traslado !== "" &&
      remisionContext.cabeceraTraslado.fecha_de_termino_de_traslado !== "" &&
      remisionContext.cabeceraTraslado.direccion_partida !== "" &&
      remisionContext.cabeceraTraslado.ciudad_partida !== "" &&
      remisionContext.cabeceraTraslado.departamento_partida !== "" &&

      remisionContext.cabeceraTraslado.direccion_llegada !== "" &&
      remisionContext.cabeceraTraslado.ciudad_llegada !== "" &&
      remisionContext.cabeceraTraslado.departamento_llegada !== ""

    ) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    //validamos cada input requerido

    let copy = { ...textValue };

    if (remisionContext.cabeceraDestino.destinatario.id === "") {
      copy.textContrato = true;
    } else {
      copy.textContrato = false;
    }
/*
    if (remisionContext.cabeceraTraslado.comprobante_de_venta === "") {
      copy.textComprobanteVenta = true;
    } else {
      copy.textComprobanteVenta = false;
    }

    if (remisionContext.cabeceraTraslado.nro_comprobante_de_venta === "") {
      copy.textNroComprobanteVenta = true;
    } else {
      copy.textNroComprobanteVenta = false;
    }
*/
    if (remisionContext.cabeceraTraslado.fecha_de_inicio_de_traslado === "") {
      copy.textFechaInicioTraslado = true;
    } else {
      copy.textFechaInicioTraslado = false;
    }

    if (remisionContext.cabeceraTraslado.fecha_de_termino_de_traslado === "") {
      copy.textFechaFinTraslado = true;
    } else {
      copy.textFechaFinTraslado = false;
    }

    if (remisionContext.cabeceraTraslado.direccion_partida === "") {
      copy.textDireccionPartida = true;
    } else {
      copy.textDireccionPartida = false;
    }

    if (remisionContext.cabeceraTraslado.ciudad_partida === "") {
      copy.textCiudadPartida = true;
    } else {
      copy.textCiudadPartida = false;
    }

    if (remisionContext.cabeceraTraslado.departamento_partida === "") {
      copy.textDepartamentoPartida = true;
    } else {
      copy.textDepartamentoPartida = false;
    }

    if (remisionContext.cabeceraTraslado.direccion_llegada === "") {
      copy.textDireccionLlegada = true;
    } else {
      copy.textDireccionLlegada = false;
    }

    if (remisionContext.cabeceraTraslado.ciudad_llegada === "") {
      copy.textCiudadLlegada = true;
    } else {
      copy.textCiudadLlegada = false;
    }

    if (remisionContext.cabeceraTraslado.departamento_llegada === "") {
      copy.textDepartamentoLlegada = true;
    } else {
      copy.textDepartamentoLlegada = false;
    }
    setTextValue(copy);

  };

  const handleNextTrasporte = () => {
    if (
      remisionContext.cabeceraTrasporte.marca_del_vehiculo !== "" &&
      remisionContext.cabeceraTrasporte.nro_de_rua !== "" &&
      remisionContext.cabeceraTrasporte.nombre_transporte !== "" &&
      remisionContext.cabeceraTrasporte.ruc_transporte !== ""

    ) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    //validamos cada input requerido

    let copy = { ...textValue };

    if (remisionContext.cabeceraTrasporte.marca_del_vehiculo === "") {
      copy.textMarcaVehiculo = true;
    } else {
      copy.textMarcaVehiculo = false;
    }

    if (remisionContext.cabeceraTrasporte.nro_de_rua === "") {
      copy.textRua = true;
    } else {
      copy.textRua = false;
    }

    if (remisionContext.cabeceraTrasporte.nombre_transporte === "") {
      copy.textNombreTrasporte = true;
    } else {
      copy.textNombreTrasporte = false;
    }

    if (remisionContext.cabeceraTrasporte.ruc_transporte === "") {
      copy.textRucTrasporte = true;
    } else {
      copy.textRucTrasporte = false;
    }
    setTextValue(copy);
    console.log(textValue)
    console.log(remisionContext.cabeceraTrasporte.marca_del_vehiculo)

  };

  const handleNext = () => {
    if (
      remisionContext.cabeceraDestino.destinatario.id !== "" &&
      remisionContext.cabeceraTraslado.comprobante_de_venta !== "" &&
      remisionContext.cabeceraTraslado.nro_comprobante_de_venta !== "" &&
      remisionContext.cabeceraTraslado.fecha_de_inicio_de_traslado !== "" &&
      remisionContext.cabeceraTraslado.fecha_de_termino_de_traslado !== "" &&
      remisionContext.cabeceraTraslado.direccion_partida !== "" &&
      remisionContext.cabeceraTraslado.ciudad_partida !== "" &&
      remisionContext.cabeceraTraslado.departamento_partida !== "" &&

      remisionContext.cabeceraTraslado.direccion_llegada !== "" &&
      remisionContext.cabeceraTraslado.ciudad_llegada !== "" &&
      remisionContext.cabeceraTraslado.departamento_llegada !== ""

    ) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    //validamos cada input requerido

    let copy = { ...textValue };

    if (remisionContext.cabeceraDestino.destinatario.id === "") {
      copy.textContrato = true;
    } else {
      copy.textContrato = false;
    }
/*
    if (remisionContext.cabeceraTraslado.comprobante_de_venta === "") {
      copy.textComprobanteVenta = true;
    } else {
      copy.textComprobanteVenta = false;
    }

    if (remisionContext.cabeceraTraslado.nro_comprobante_de_venta === "") {
      copy.textNroComprobanteVenta = true;
    } else {
      copy.textNroComprobanteVenta = false;
    }
*/
    if (remisionContext.cabeceraTraslado.fecha_de_inicio_de_traslado === "") {
      copy.textFechaInicioTraslado = true;
    } else {
      copy.textFechaInicioTraslado = false;
    }

    if (remisionContext.cabeceraTraslado.fecha_de_termino_de_traslado === "") {
      copy.textFechaFinTraslado = true;
    } else {
      copy.textFechaFinTraslado = false;
    }

    if (remisionContext.cabeceraTraslado.direccion_partida === "") {
      copy.textDireccionPartida = true;
    } else {
      copy.textDireccionPartida = false;
    }

    if (remisionContext.cabeceraTraslado.ciudad_partida === "") {
      copy.textCiudadPartida = true;
    } else {
      copy.textCiudadPartida = false;
    }

    if (remisionContext.cabeceraTraslado.departamento_partida === "") {
      copy.textDepartamentoPartida = true;
    } else {
      copy.textDepartamentoPartida = false;
    }

    if (remisionContext.cabeceraTraslado.direccion_llegada === "") {
      copy.textDireccionLlegada = true;
    } else {
      copy.textDireccionLlegada = false;
    }

    if (remisionContext.cabeceraTraslado.ciudad_llegada === "") {
      copy.textCiudadLlegada = true;
    } else {
      copy.textCiudadLlegada = false;
    }

    if (remisionContext.cabeceraTraslado.departamento_llegada === "") {
      copy.textDepartamentoLlegada = true;
    } else {
      copy.textDepartamentoLlegada = false;
    }
    setTextValue(copy);

  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    remisionContext.clearRemision();
    setActiveStep(0);
  };

  const handleGuardarRemision = async () => {

    try {
      setIsLoading(true);
      let dataObject = {

        cliente: remisionContext.cabeceraDestino.destinatario.idcliente,
        contrato: remisionContext.cabeceraDestino.destinatario.id,
        motivo_del_traslado: remisionContext.cabeceraTraslado.motivo_del_traslado,
        comprobante_de_venta: remisionContext.cabeceraTraslado.comprobante_de_venta,
        nro_comprobante_de_venta: remisionContext.cabeceraTraslado.nro_comprobante_de_venta,
        nro_de_timbrado: remisionContext.cabeceraTraslado.nro_de_timbrado,
        fecha_de_expedicion: remisionContext.cabeceraTraslado.fecha_de_expedicion,
        fecha_de_inicio_de_traslado: remisionContext.cabeceraTraslado.fecha_de_inicio_de_traslado,
        fecha_de_termino_de_traslado: remisionContext.cabeceraTraslado.fecha_de_termino_de_traslado,
        direccion_partida: remisionContext.cabeceraTraslado.direccion_partida,
        ciudad_partida: remisionContext.cabeceraTraslado.ciudad_partida,
        departamento_partida: remisionContext.cabeceraTraslado.departamento_partida,
        direccion_llegada: remisionContext.cabeceraTraslado.direccion_llegada,
        ciudad_llegada: remisionContext.cabeceraTraslado.ciudad_llegada,
        departamento_llegada: remisionContext.cabeceraTraslado.departamento_llegada,
        kilometros_de_recorrido: remisionContext.cabeceraTraslado.kilometros_de_recorrido,
        cambio_de_fecha: remisionContext.cabeceraTraslado.cambio_de_fecha,
        motivo: remisionContext.cabeceraTraslado.motivo,

        marca_del_vehiculo: remisionContext.cabeceraTrasporte.marca_del_vehiculo,
        nro_de_rua: remisionContext.cabeceraTrasporte.nro_de_rua,
        nro_rua_remolque: remisionContext.cabeceraTrasporte.nro_rua_remolque,
        nombre_transporte: remisionContext.cabeceraTrasporte.nombre_transporte,
        ruc_transporte: remisionContext.cabeceraTrasporte.ruc_transporte,
        domicilio_transporte: remisionContext.cabeceraTrasporte.domicilio_transporte,
        nro_factura: remisionContext.cabeceraTrasporte.nro_factura,
        observacion: remisionContext.cabeceraTrasporte.observacion,
        items: remisionContext.detalle.dataDetalle,
      };

      const response = await axios.post("remision/funAgregar.php", dataObject);

      let status = response.status;
      let data = response.data;
      if (status === 200) {
        setClaveRemision(data.id);
        handleNext();
      }

    } catch (error) {
      if (error.response) {
        alertWarningError(error.response);
      }
    } finally {
      setIsLoading(false);
    }

  };

  const handleVistaPrevia = async () => {

    try {
      setIsLoading(true);
      let dataObject = {

        cliente: remisionContext.cabeceraDestino.destinatario.idcliente,
        contrato: remisionContext.cabeceraDestino.destinatario.id,
        motivo_del_traslado: remisionContext.cabeceraTraslado.motivo_del_traslado,
        comprobante_de_venta: remisionContext.cabeceraTraslado.comprobante_de_venta,
        nro_comprobante_de_venta: remisionContext.cabeceraTraslado.nro_comprobante_de_venta,
        nro_de_timbrado: remisionContext.cabeceraTraslado.nro_de_timbrado,
        fecha_de_expedicion: remisionContext.cabeceraTraslado.fecha_de_expedicion,
        fecha_de_inicio_de_traslado: remisionContext.cabeceraTraslado.fecha_de_inicio_de_traslado,
        fecha_de_termino_de_traslado: remisionContext.cabeceraTraslado.fecha_de_termino_de_traslado,
        direccion_partida: remisionContext.cabeceraTraslado.direccion_partida,
        ciudad_partida: remisionContext.cabeceraTraslado.ciudad_partida,
        departamento_partida: remisionContext.cabeceraTraslado.departamento_partida,
        direccion_llegada: remisionContext.cabeceraTraslado.direccion_llegada,
        ciudad_llegada: remisionContext.cabeceraTraslado.ciudad_llegada,
        departamento_llegada: remisionContext.cabeceraTraslado.departamento_llegada,
        kilometros_de_recorrido: remisionContext.cabeceraTraslado.kilometros_de_recorrido,
        cambio_de_fecha: remisionContext.cabeceraTraslado.cambio_de_fecha,
        motivo: remisionContext.cabeceraTraslado.motivo,

        marca_del_vehiculo: remisionContext.cabeceraTrasporte.marca_del_vehiculo,
        nro_de_rua: remisionContext.cabeceraTrasporte.nro_de_rua,
        nro_rua_remolque: remisionContext.cabeceraTrasporte.nro_rua_remolque,
        nombre_transporte: remisionContext.cabeceraTrasporte.nombre_transporte,
        ruc_transporte: remisionContext.cabeceraTrasporte.ruc_transporte,
        domicilio_transporte: remisionContext.cabeceraTrasporte.domicilio_transporte,
        nro_factura: remisionContext.cabeceraTrasporte.nro_factura,
        observacion: remisionContext.cabeceraTrasporte.observacion,
        items: remisionContext.detalle.dataDetalle,
      };

      const response = await axios.post("remision/funCargarRemisionInforme.php", dataObject);

      let status = response.status;
      let data = response.data;
      if (status === 200) {

        let tokenCookies = sessionStorage.getItem("jwt-jr");
        let url = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}extensiones/tcpdf/pdf/vRemisionVistaPrevia.php?p=${JSON.parse(tokenCookies).token}`
        var win = window.open(url, '_blank');
        win.focus();
      }

    } catch (error) {
      if (error.response) {
        alertWarningError(error.response);
      }
    } finally {
      setIsLoading(false);
    }

  };


  const handleGenerarImpresion = async () => {
    handleNext();

    let tokenCookies = sessionStorage.getItem("jwt-jr");
    let url = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}extensiones/tcpdf/pdf/vRemision.php?nroFactura=${claveRemision}&p=${JSON.parse(tokenCookies).token}`
    var win = window.open(url, '_blank');
    win.focus();
  }
  
  const handleConfirmar = async () => {

    try {
      setIsLoading(true);

      const response = await axios.post(`/remision/funActivar.php?id=` + Number(claveRemision));

      let status = response.status;
      let data = response.data;
      if (status === 200) {
        setNroNotaRemision(data.nroFactura);
        handleNext();
      }

    } catch (error) {
      if (error.response) {
        alertWarningError(error.response);
      }
    } finally {
      setIsLoading(false);
    }

  };

  return (
    <>
      <BackdropCustom open={isLoading} />

      <div>
        <Stepper activeStep={activeStep} alternativeLabel >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel color="secondary">{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography variant="h6" component="h6">
                <Alert severity="success">Impresión exitosa.</Alert>
              </Typography>
              <Button onClick={handleReset} size="small">Cargar nueva remisión</Button>

              <BotonGris
                startIcon={<ExitToApp />}
                onClick={(e) => {
                  history.goBack();
                }}
                children="SALIR"
              />
            </div>
          ) : (
            <div>
              <Typography variant="h6" component="h6">
                {getStepContent(activeStep)}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
