import React, { useEffect, useState, useContext } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { useStyles } from "../../../assets/styles/CustomStyles";
import TextField from "@material-ui/core/TextField";
import { Typography } from "@material-ui/core";
import axios from "../../../utils/axios";
import {
  alertWarningError,
  notificacionWarning,
} from "../../Notificaciones";
import ContratoContext from "../../../utils/contratoContext/ContratoContext";
import BuscadorClienteDialog from "../../BuscadorClienteDialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import { InputBase } from "@material-ui/core";

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import IconButton from '@material-ui/core/IconButton';
import Search from "@material-ui/icons/Search";

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const initialValue = {
  nroContrato: "",
  cliente: { id: "", ruc: "", denominacion: "", domicilio: "" },
  facturar: false,
  fechaInicio: new Date(),
  fechaFin: new Date(),
};


export default function DatosContrato(props) {
  const { ruc, nroContrato, fechaInicio, fechaFin } = props?.select;
  const classes = useStyles();
  const contratoContext = useContext(ContratoContext);
  const [inputValues, setInputValues] = useState(initialValue);
  const [isLoadingCliente, setIsLoadiongCliente] = useState(false);
  const [openCliente, setOpenCliente] = useState(false);

  useEffect(() => {
    setInputValues(contratoContext.cabecera);
    // fechaHoy();
    // se utiliza para actualizar input values cuando el contexto ha sido actualizado
  }, [contratoContext.cabecera]);

  const fechaHoy = () => {
    var MyDate = new Date();
    var MyDateString;

    MyDate.setDate(MyDate.getDate());
    MyDateString =
      MyDate.getFullYear() +
      "-" +
      ("0" + (MyDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + MyDate.getDate()).slice(-2);

    let copyInput = {
      ...inputValues,
      fechaInicio: MyDateString,
      fechaFin: MyDateString,
    };
    setInputValues(copyInput)
  };

  const handleChangeCliente = (value) => {
    let copyInput = {
      ...inputValues,
      cliente: {
        id: "",
        ruc: value.target.value,
        denominacion: "",
        domicilio: "",
      },
    };
    setInputValues(copyInput);
    contratoContext.updateContratoCabecera(copyInput)
  };

  const handleChangeContrato = (value) => {
    let copyInput = {
      ...inputValues,
      nroContrato: value.target.value
    };
    setInputValues(copyInput);
    contratoContext.updateContratoCabecera(copyInput)
  };

  const handleChangeFechaInicio = (value) => {
    let copyInput = {
      ...inputValues,
      fechaInicio: value
    };
    setInputValues(copyInput);
    contratoContext.updateContratoCabecera(copyInput)
  };

  const handleChangeFechaFin = (value) => {
    let copyInput = {
      ...inputValues,
      fechaFin: value
    };
    setInputValues(copyInput);
    contratoContext.updateContratoCabecera(copyInput)
  };
  
  const handleChangeEsFacturar = (value) => {

    let copyInput = {
      ...inputValues,
      facturar: value.target.checked ? 1 : 0,
    };
    setInputValues(copyInput);
    contratoContext.updateContratoCabecera(copyInput)
  };

  const loadCliente = async () => {
    let ruc = inputValues.cliente?.ruc;
    if (ruc) {
      try {
        setIsLoadiongCliente(true);

        const response = await axios.get(
          `cliente/funConsultaIndividual.php?ruc=${ruc}`
        );

        let data = response.data;
        let status = response.status
        if (status === 200) {

          if (data.length < 0) {
            notificacionWarning(
              "No se encontró el artículo con código " + ruc
            );
            return;
          }

          if (data.length > 1) {
            notificacionWarning(
              "Se encontró más de un resultado para el artículo con código " +
              ruc
            );
            return;
          }

          document.getElementById("nroContrato").focus();
          handleCliente(data[0]);
        }


      } catch (error) {
        if (error.response) {
          alertWarningError(error.response);
        }
      } finally {
        setIsLoadiongCliente(false);
      }
    }
  };

  const handleCliente = (value) => {
    console.log(value)
    let copyInput = {
      ...inputValues,
      cliente: {
        id: value?.id,
        ruc: value?.ruc,
        denominacion: value?.nombre,
        domicilio: value?.direccion,
      },
    };
    setInputValues(copyInput);
  };
  const handleCloseCliente = (value) => {
    setOpenCliente(false);
  };
  const handleOpenCliente = () => {
    setOpenCliente(true);
  };



  return (
    <>
      <Paper className={classes.padding2}>
        <Grid container direction="row" alignItems="flex-start" justify="flex-start" spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid container spacing={2} justify="flex-start" alignItems="flex-start">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="button" display="block">
                  Datos del cliente
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Paper className={classes.rootPaper}>
                  <InputBase
                    className={classes.input}
                    id="ruc"
                    name="ruc"
                    placeholder="RUC/CI"
                    fullWidth
                    autoFocus
                    error={ruc}
                    helperText={
                      nroContrato ? "Campo obligatorio" : null
                    }
                    onChange={(e) => handleChangeCliente(e)}
                    value={
                      inputValues.cliente?.ruc &&
                      inputValues.cliente?.ruc
                    }
                    onBlur={() => loadCliente()}
                    inputProps={{
                      "aria-label": "ruc",
                    }}
                  />
                  {isLoadingCliente ? (
                    <CircularProgress color="primary" size={20} />
                  ) : (
                    <IconButton
                      style={{
                        color: "#396b99",
                        textAlign: "center",
                        minWidth: 45,
                        padding: "1px 8px 1px 8px",
                      }}
                      className={classes.iconButton}
                      aria-label="search"
                      onClick={() => handleOpenCliente()}
                    >
                      <Search />
                    </IconButton>
                  )}

                </Paper>


              </Grid>

              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <TextField
                  id="DENOMINACION"
                  label="DENOMINACION"
                  name="DENOMINACION"
                  variant="outlined"
                  color="secondary"
                  InputProps={{
                    readOnly: true,

                  }}
                  helperText={
                    nroContrato ? "Campo obligatorio" : null
                  }
                  value={
                    inputValues.cliente?.denominacion && inputValues.cliente?.denominacion
                  }
                  fullWidth
                  size="small"

                />

              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  id="DOMICILIO"
                  label="DOMICILIO"
                  name="DOMICILIO"
                  variant="outlined"
                  color="secondary"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={
                    inputValues.cliente?.domicilio && inputValues.cliente?.domicilio
                  }
                  helperText={
                    nroContrato ? "Campo obligatorio" : null
                  }
                  fullWidth
                  size="small"
                />

              </Grid>

            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid container spacing={2} justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="button" display="block">
                  Datos del contrato
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  id="nroContrato"
                  label="N° CONTRATO"
                  name="nroContrato"
                  variant="outlined"
                  color="secondary"
                  error={nroContrato}
                  helperText={
                    nroContrato ? "Campo obligatorio" : null
                  }
                  onChange={(e) => handleChangeContrato(e)}
                  value={
                    inputValues.nroContrato && inputValues.nroContrato
                  }
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
                <FormGroup row>
                  <FormControlLabel
                   
                    control={
                      <Checkbox
                      
                        checked={inputValues.facturar}
                        onChange={(value) => handleChangeEsFacturar(value)}
                        name="facturar"
                        color="primary"
                      />
                    }
                    label="Para Facturar"
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    id="FECHAINICIO"
                    label="FECHA INICIO"
                    value={inputValues.fechaInicio}
                    fullWidth
                    size="small"
                    helperText={
                      fechaFin ? "Campo obligatorio" : null
                    }
                    onChange={(e) => handleChangeFechaInicio(e)}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    id="fechaFin"
                    label="FECHA FIN"
                    value={inputValues.fechaFin}
                    fullWidth
                    size="small"
                    helperText={
                      fechaFin ? "Campo obligatorio" : null
                    }
                    onChange={(e) => handleChangeFechaFin(e)}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <BuscadorClienteDialog
        open={openCliente}
        onClose={handleCloseCliente}
        onSetData={handleCliente}
      />
    </>
  );
}
