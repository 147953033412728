import React, { useContext, useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Detalle from "./Detalle";

import {
  notificacionError,
  alertWarningError,
} from "../../Notificaciones";
import { BotonGris } from "../../../assets/styles/StyledButtons";
import RemisionContext from "../../../utils/remisionContext/RemisionContext";
import { useStyles } from "../../../assets/styles/CustomStyles";
import Alert from "@material-ui/lab/Alert";

import ExitToApp from "@material-ui/icons/ExitToApp";
import axios from "../../../utils/axios";
import pdfConverter from "../../../utils/pdfConverter";
import { useHistory } from "react-router-dom";
import BackdropCustom from "../../BackdropCustom";
import Cabecera from "./Cabecera";


export default function StepperRemision() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const remisionContext = useContext(RemisionContext);

  const steps = getSteps();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [nroNotaRemision, setNroNotaRemision] = useState();
  const [remision, setRemision] = useState({
    content: []
  });
  const dataProps = history.location.state;

  useEffect(() => {
    getRemision();
  }, [dataProps]);

  const getRemision = async () => {

    try {
      setIsLoading(true);
      const response = await axios.get("remision/funConsultaIndividual.php", {
        params: {
          id: dataProps.id,
        },
      });
      let status = response.status
      if (status === 200) {
        let data = response.data[0]

        let cabeceraDestino = {
          destinatario: { contrato: data?.contrato, nroContrato: "", cliente: data?.idcliente, ruc: data?.ruc, denominacion: data?.nombre_cliente, direccion: data?.direccion, items: [] },

        }
        let cabeceraTraslado = {
          motivo_del_traslado: data?.motivo_del_traslado, comprobante_de_venta: data?.comprobante_de_venta, nro_comprobante_de_venta: data?.nro_comprobante_de_venta, nro_de_timbrado: data?.nro_de_timbrado, fecha_de_expedicion: data?.fecha_de_expedicion, fecha_de_inicio_de_traslado: data?.fecha_de_inicio_de_traslado, fecha_de_termino_de_traslado: data?.fecha_de_termino_de_traslado, direccion_partida: data?.direccion_partida, ciudad_partida: data?.ciudad_partida, departamento_partida: data?.departamento_partida, direccion_llegada: data?.direccion_llegada, ciudad_llegada: data?.ciudad_llegada, departamento_llegada: data?.departamento_llegada, kilometros_de_recorrido: data?.kilometros_de_recorrido, cambio_de_fecha: data?.cambio_de_fecha, motivo: data?.motivo
        }


        let cabeceraTrasporte = {
          marca_del_vehiculo: data?.marca_del_vehiculo, nro_de_rua: data?.nro_de_rua, nro_rua_remolque: data?.nro_rua_remolque, nombre_transporte: data?.nombre_transporte, ruc_transporte: data?.ruc_transporte, domicilio_transporte: data?.domicilio_transporte, observacion: data?.observacion,
        }

        let detalle = {
          dataDetalle: data?.items,
        };

        setIsLoading(false);
        setRemision(response.data[0]);
        remisionContext.updateRemisionCabeceraDestino(cabeceraDestino);
        remisionContext.updateRemisionCabeceraTraslado(cabeceraTraslado);
        remisionContext.updateRemisionCabeceraTrasporte(cabeceraTrasporte);
        remisionContext.updateRemisionDetalle(detalle.dataDetalle);
      }

    } catch (error) {
      if (error.response) {
        alertWarningError(error.response);
      }
    } finally {
      setIsLoading(false);
    }
  };

  function getSteps() {
    return [
      "Cabecera",
      "Detalle",
      "Impresión",
    ];
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return CabeceraStep();
      case 1:
        return DetalleStep(dataProps.id);
      case 2:
        return Impresion();
      default:
        return "";
    }
  }

  const CabeceraStep = (props) => {
    return (
      <>
        <Cabecera
        // select={props}
        />
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          className={classes.button}
          size="small"
        >
          Anterior
        </Button>
        <Button variant="contained" color="primary" size="small" onClick={handleNext}>
          Siguiente
        </Button>
      </>
    );
  };


  const DetalleStep = (props) => {
    return (
      <>
        <Detalle id={props} />
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          className={classes.button}
          size="small"
        >
          Anterior
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleConfirmar}
          size="small"
        >
          Procesar Remisión
        </Button>

        <Button
          variant="contained"
          color="primary"
          style={{ float: "right", marginTop: 9 }}
          onClick={handleVistaPrevia}
          size="small"
        >
          Vista Previa
        </Button>
      </>
    );
  };


  const setearFecha = (props) => {
    let fecha = props.split("/");
    let dia = fecha[0];
    let mes = fecha[1];
    let anho = fecha[2];
    let fechaConvertida = anho + "-" + (mes < 0 ? mes + 1 : mes) + "-" + dia;
    return fechaConvertida
  }
  const handleVistaPrevia = async () => {

    try {
      setIsLoading(true);
      let dataObject = {

        cliente: remisionContext.cabeceraDestino.destinatario.cliente,
        contrato: remisionContext.cabeceraDestino.destinatario.id,
        motivo_del_traslado: remisionContext.cabeceraTraslado.motivo_del_traslado,
        comprobante_de_venta: remisionContext.cabeceraTraslado.comprobante_de_venta,
        nro_comprobante_de_venta: remisionContext.cabeceraTraslado.nro_comprobante_de_venta,
        nro_de_timbrado: remisionContext.cabeceraTraslado.nro_de_timbrado,
        fecha_de_expedicion: setearFecha(remisionContext.cabeceraTraslado.fecha_de_expedicion),
        fecha_de_inicio_de_traslado: setearFecha(remisionContext.cabeceraTraslado.fecha_de_inicio_de_traslado),
        fecha_de_termino_de_traslado: setearFecha(remisionContext.cabeceraTraslado.fecha_de_termino_de_traslado),
        direccion_partida: remisionContext.cabeceraTraslado.direccion_partida,
        ciudad_partida: remisionContext.cabeceraTraslado.ciudad_partida,
        departamento_partida: remisionContext.cabeceraTraslado.departamento_partida,
        direccion_llegada: remisionContext.cabeceraTraslado.direccion_llegada,
        ciudad_llegada: remisionContext.cabeceraTraslado.ciudad_llegada,
        departamento_llegada: remisionContext.cabeceraTraslado.departamento_llegada,
        kilometros_de_recorrido: remisionContext.cabeceraTraslado.kilometros_de_recorrido,
        cambio_de_fecha: remisionContext.cabeceraTraslado.cambio_de_fecha,
        motivo: remisionContext.cabeceraTraslado.motivo,

        marca_del_vehiculo: remisionContext.cabeceraTrasporte.marca_del_vehiculo,
        nro_de_rua: remisionContext.cabeceraTrasporte.nro_de_rua,
        nro_rua_remolque: remisionContext.cabeceraTrasporte.nro_rua_remolque,
        nombre_transporte: remisionContext.cabeceraTrasporte.nombre_transporte,
        ruc_transporte: remisionContext.cabeceraTrasporte.ruc_transporte,
        domicilio_transporte: remisionContext.cabeceraTrasporte.domicilio_transporte,
        nro_factura: remisionContext.cabeceraTrasporte.nro_factura,
        observacion: remisionContext.cabeceraTrasporte.observacion,
        items: remisionContext.detalle.dataDetalle,
      };

      const response = await axios.post("remision/funCargarRemisionInforme.php", dataObject);

      let status = response.status;
      let data = response.data;
      if (status === 200) {

        let tokenCookies = sessionStorage.getItem("jwt-jr");
        let url = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}extensiones/tcpdf/pdf/vRemisionVistaPrevia.php?p=${JSON.parse(tokenCookies).token}`
        var win = window.open(url, '_blank');
        win.focus();
      }

    } catch (error) {
      if (error.response) {
        alertWarningError(error.response);
      }
    } finally {
      setIsLoading(false);
    }

  };

  const Impresion = () => {
    return (
      <div>
        <Typography variant="h6" component="h6">
          <Alert severity="success">Se confirmó exitosamente la remision Nro. {nroNotaRemision}</Alert>
        </Typography>

        <Button
          onClick={() => handleGenerarImpresion()}
          variant="contained"
          color="primary"
          size="small"
        >
          IMPRIMIR REMISIÓN
        </Button>
        <BotonGris
          startIcon={<ExitToApp />}
          onClick={(e) => {
            history.goBack();
          }}
          children="SALIR"
        />
      </div>
    );
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    remisionContext.clearRemision();
    setActiveStep(0);
  };


  const handleConfirmar = async () => {

    try {
      setIsLoading(true);

      const response = await axios.post(`/remision/funActivar.php?id=` + Number(dataProps.id));

      let status = response.status;
      let data = response.data;
      if (status === 200) {
        setNroNotaRemision(data.nroFactura);
        handleNext();
      }

    } catch (error) {
      if (error.response) {
        alertWarningError(error.response);
      }
    } finally {
      setIsLoading(false);
    }

  };


  const handleGenerarImpresion = async () => {
    handleNext();

    let tokenCookies = sessionStorage.getItem("jwt-jr");
    let url = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}extensiones/tcpdf/pdf/vRemision.php?nroFactura=${dataProps?.id}&p=${JSON.parse(tokenCookies).token}`
    var win = window.open(url, '_blank');
    win.focus();

  }


  return (
    <>
      <BackdropCustom open={isLoading} />

      <div>
        <Stepper activeStep={activeStep} alternativeLabel >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel color="secondary">{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography variant="h6" component="h6">
                <Alert severity="success">Impresión exitosa.</Alert>
              </Typography>
              <Button onClick={handleReset} size="small">Cargar nueva remisión</Button>

              <BotonGris
                startIcon={<ExitToApp />}
                onClick={(e) => {
                  history.goBack();
                }}
                children="SALIR"
              />
            </div>
          ) : (
            <div>
              <Typography variant="h6" component="h6">
                {getStepContent(activeStep)}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
