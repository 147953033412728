import React, { useEffect, useState } from "react";
import { MakeTables } from "../../components/MaterialTables/MakeTables";
import { useStyles } from "../../assets/styles/CustomStyles";
import { useHistory } from "react-router-dom";
import axios from "../../utils/axios";
import { Typography } from "@material-ui/core";
import { alertWarningError } from "../../components/Notificaciones";


export default function CobrarCredito() {
    const history = useHistory();
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({ content: [] });

    useEffect(() => {
        //  setData({ ...data, content: defaultValue})
       getCobrarCredito()
    }, []);

    const getCobrarCredito = async (props) => {
        setIsLoading(true);
        let url = props ? props : "facturacion/funConsultaCredito.php?estado=pendiente";
        try {
            const response = await axios.get(url);

            const cobrarCredito = response.data;
            setData({ ...data, content: cobrarCredito });

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            if (error.response) {
                alertWarningError(error.response);
            }
        }
    };

    const title = (
        <>
            <Typography variant="body1" display="block">
                COBRAR CREDITO
            </Typography>

        </>
    );
    const columns = [
        {
            title: "Fecha",
            field: "fecha",
            width: "15%",
        },
        {
            title: "N° Factura",
            field: "nro_factura",
            width: "10%"
        },
        {
            title: "RUC/CI",
            field: "ruc",
            width: "10%"
        },
        {
            title: "Denominación",
            field: "nombre",

        },
        {
            title: "IVA",
            field: "iva",
            width: "10%", align: "right"

        },
        {
            title: "Total",
            field: "total",
            width: "15%", align: "right"

        },

    ];

    const options = {
        filtering: false,
        exportButton: false,
        exportAllData: false,
        headerStyle: { position: "sticky", top: 0 },
        maxBodyHeight: "65vh",
        paging: true,
        draggable: false,
        rowStyle: {
            fontSize: "small",
        },
    };

    const detalle = (event, props) => {
        //corta el evento el onRowClick
        event.stopPropagation();
        history.push("./detalle/", {
            data: props.data,
            // fecRemision: props.data.fecRemision,
            // nroRemision: props.data.nroRemision,
        });
    };

    const notaCredito = (event, props) => {
        //corta el evento el onRowClick
        event.stopPropagation();
        history.push("./nota-credito/", {
            data: props.data,
            // fecRemision: props.data.fecRemision,
            // nroRemision: props.data.nroRemision,
        });
    };

    const actions = [
        {
            icon: "save",
            tooltip: "place-holder",
            onClick: (event, rowData) => alert("You saved " + rowData.name),
            hidden: true,
        },
        {
            icon: "save",
            tooltip: "FreeActions-place-holder",
            isFreeAction: true,
            onClick: (event, rowData) => alert("You saved " + rowData.name),
            hidden: true,
        },
    ];


    return (
        <>
            <MakeTables
                // onRowClick={detalle}
                title={title}
                columns={columns}
                data={data?.content}
                actions={actions}
                classes={classes}
                options={options}
                isLoading={isLoading}
                componentsAssets={{ classes, detalle, notaCredito }}
            />
        </>
    );
}
