import React, { useEffect, useState, useContext } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { useStyles } from "../../../assets/styles/CustomStyles";
import TextField from "@material-ui/core/TextField";
import { Typography } from "@material-ui/core";
import ContratoContext from "../../../utils/contratoContext/ContratoContext";


const initialValue = {
  nroContrato: "",
  cliente: { id: "", ruc: "", denominacion: "", domicilio: "" },
  fechaInicio: "",
  fechaFin: "",
};

export default function DatosContrato(props) {
  const { ruc, nroContrato, fechaInicio, fechaFin } = props?.select;
  const classes = useStyles();
  const contratoContext = useContext(ContratoContext);
  const [inputValues, setInputValues] = useState(initialValue);

  useEffect(() => {
    setInputValues(contratoContext.cabecera);
    // se utiliza para actualizar input values cuando el contexto ha sido actualizado
  }, [contratoContext.cabecera]);

  return (
    <>
      <Paper className={classes.padding2}>
        <Grid container direction="row" alignItems="flex-start" justify="flex-start" spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid container spacing={2} justify="flex-start" alignItems="flex-start">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="button" display="block">
                  Datos del cliente
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <TextField
                  id="ruc"
                  label="RUC/CI"
                  name="ruc"
                  variant="outlined"
                  color="secondary"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={
                    inputValues.cliente?.ruc && inputValues.cliente?.ruc
                  }
                  fullWidth
                  size="small"
                />


              </Grid>

              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <TextField
                  id="DENOMINACION"
                  label="DENOMINACION"
                  name="DENOMINACION"
                  variant="outlined"
                  color="secondary"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={
                    inputValues.cliente?.denominacion && inputValues.cliente?.denominacion
                  }
                  fullWidth
                  size="small"
                />

              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  id="DOMICILIO"
                  label="DOMICILIO"
                  name="DOMICILIO"
                  variant="outlined"
                  color="secondary"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={
                    inputValues.cliente?.domicilio && inputValues.cliente?.domicilio
                  }
                  fullWidth
                  size="small"
                />

              </Grid>

            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid container spacing={2} justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="button" display="block">
                  Datos del contrato
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  id="nroContrato"
                  label="N° CONTRATO"
                  name="nroContrato"
                  variant="outlined"
                  color="secondary"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={
                    inputValues.nroContrato && inputValues.nroContrato
                  }
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <TextField
                  id="facturar"
                  label="PARA FACTURAR"
                  name="facturar"
                  variant="outlined"
                  color="secondary"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={
                    inputValues.facturar ? "SI" : "NO"
                  }
                fullWidth
                size="small"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  id="FECHAINICIO"
                  label="FECHA INICIO"
                  name="fechaInicio"
                  variant="outlined"
                  color="secondary"
                  type="date"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={inputValues.fechaInicio}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  id="fechaFin"
                  label="FECHA FIN"
                  name="fechaFin"
                  variant="outlined"
                  color="secondary"
                  type="date"
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={inputValues.fechaFin}

                  fullWidth
                  size="small"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

    </>
  );
}
