import React, { useContext} from "react";
import Navegacion from "./utils/navegacion/Navegacion";
import Container from "@material-ui/core/Container";
import UserContext from "./utils/user/UserContext";
import NavBar from "./components/NavBar";
import Login from "./page/Login";
function AppLayout({ route, theme, memoizedtoggleTheme }) {
  const Content = route.component;
  const userContext = useContext(UserContext);
  return (
    <>
      {userContext.state.nombreUsu ?
       <>
       <NavBar theme={theme} toggleTheme={memoizedtoggleTheme} />
        {route.parent && <Navegacion route={route} />}
          <Container maxWidth="xl">
            <Content />
          </Container>
        </>
        : 
        
        <Login/>

      }
      

    </>
  );
}
export default AppLayout;
