import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import { useStyles } from "../assets/styles/CustomStyles";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TypographyBold from "./TypographyBold"
import LinearProgress from "@material-ui/core/LinearProgress";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import CardActions from '@material-ui/core/CardActions';
import SaveIcon from '@material-ui/icons/Save';
import { useForm } from 'react-hook-form';

DialogBajaTimbrado.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "#fff",
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


export default function DialogBajaTimbrado(props) {
  /**
   * @param {boolean} open muestra el dialogo en pantalla
   * @param {boolean} onClose muestra el dialogo en pantalla
   * @param {object} onSetData usamos si el dialogo tiene formulario, por este parametro le enviamos al padre los datos insertado
   */
  const { open, onClose, onSetData } = props;


  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit } = useForm();
  const classes = useStyles();


  const onsubmit = async (data, e) => {
    e.preventDefault();
    onClose()
    onSetData(data)
  };



  const Timbrado = () => {
    return (
      <>
        <DialogTitle
          id="form-dialog-timbrado"
          className={classes.DialogTitle}
          onClose={onClose}
        >
          <Typography variant="body1" display="block">
            BAJA TIMBRADO
          </Typography>
        </DialogTitle>
        <Divider />
        <form id="timbrado" onSubmit={handleSubmit(onsubmit)} noValidate>
          <DialogContent>
            <>
              <Paper >
                <Box p={1} >
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                  >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TypographyBold variant="body2" display="block" color="textSecondary">
                        DETALLE TIMBRADO
                      </TypographyBold>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TextField
                        label="N° TIMBRADO"
                        variant="outlined"
                        color="secondary"
                        {...register('nro_timbrado', { required: true })}
                        autoFocus
                        fullWidth
                        size="small"
                      />
                    </Grid>
                    {/** 
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextField
                        id="incioNumeracion"
                        label="INICIO NUMERACION"
                        name="incioNumeracion"
                        variant="outlined"
                        color="secondary"
                        type="number"
                        {...register('inicio_numeracion', { required: true })}
                        fullWidth
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextField
                        id="finalNumeracion"
                        label="FINAL NUMERACION"
                        name="finalNumeracion"
                        variant="outlined"
                        color="secondary"
                        type="number"
                        {...register('final_numeracion', { required: true })}
                        fullWidth
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>

                      <TextField
                        id="finalNumeracion"
                        label="FINAL NUMERACION"
                        name="finalNumeracion"
                        variant="outlined"
                        color="secondary"
                        type="number"
                        type="date"
                        {...register('vigencia_desde', { required: true })}
                        fullWidth
                        size="small"
                      />


                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <TextField
                        id="finalNumeracion"
                        label="FINAL NUMERACION"
                        variant="outlined"
                        color="secondary"
                        type="number"
                        type="date"
                        {...register('vigencia_hasta', { required: true })}
                        fullWidth
                        size="small"
                      />



                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <TextField
                        id="numeracionActual"
                        label="NUMERACION ACTUAL"
                        name="numeracionActual"
                        variant="outlined"
                        color="secondary"
                        type="number"
                        {...register('numeracion_actual', { required: true })}
                        fullWidth
                        size="small"
                      />
                    </Grid>
*/}
                  </Grid>
                </Box>
              </Paper >
            </>
          </DialogContent>
          <CardActions >
            <Box mr={2} className={classes.botonDerecha}>
              <Button
                size="small"
                color="secondary"

                variant="contained"
                startIcon={<SaveIcon />}
                type="submit"

              >
                GUARDAR
              </Button>
            </Box>

          </CardActions>
        </form>
        <Divider />
      </>
    );
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <Timbrado />

      {isLoading && <LinearProgress />}
    </Dialog>
  );
}
