import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Copyright } from '../components/CopyRight';
import Container from '@material-ui/core/Container';
import logo from '../assets/images/logoBorde.png';

import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  notificacionError,
  notificacionWarning, notificacionAlerta
} from '../components/Notificaciones';
import { login } from '../utils/authUtils';
import { useStyles } from '../assets/styles/CustomStyles';

export default function Login() {
  const classes = useStyles();

  const { register, handleSubmit } = useForm();

  const onsubmit = async (data, e) => {
    e.preventDefault();
    console.log(data)
    try {
      const response = await login(data);
      console.log(response);
      if (response.status === 'OK') {
        window.location.href = '/';
      } 
    } catch (error) {
      console.log(error);
      if (error) {
        notificacionAlerta(error);
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box mt={10}>
      <div className={classes.paper}>
        <img width="200px" alt="Logo" src={logo} />

        <form id="login" onSubmit={handleSubmit(onsubmit)} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            color="secondary"
            label="Usuario"
              {...register('nombre', { required: true })}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            color="secondary"
            fullWidth
         //   name="password"
            label="Contraseña"
            type="password"
         //   id="password"
           // inputRef={register({ required: true })}
              {...register('pass', { required: true })}
            autoComplete="current-password"
          />
 

          <Button
            fullWidth
            variant="contained"
            style={{
              backgroundColor: '#396b99',
              color: '#fff',
              fontWeight: 600,
            }}
            className={classes.marginContent}
            type="submit"
          >
            ACCEDER
          </Button>
        </form>

      </div>
      </Box>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
