import CryptoJS from 'crypto-js';

export function encriptar(props) {

    const encriptarCryptoJS = CryptoJS.AES.encrypt(props, process.env.REACT_APP_SECRET_KEY).toString()

    return encriptarCryptoJS

}

export function descodificar(props) {
    const descodificar = CryptoJS.AES.decrypt(props, process.env.REACT_APP_SECRET_KEY)
    const result = descodificar.toString(CryptoJS.enc.Utf8)
    return result
}