import React, { useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { useStyles } from "../../../assets/styles/CustomStyles";
import MaterialTable from "material-table";
import Localizacion from "../../MaterialTables/Localization";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { BotonVerde } from "../../../assets/styles/StyledButtons";
import AddIcon from "@material-ui/icons/AddCircle";
import Divider from "@material-ui/core/Divider";
import ContratoContext from "../../../utils/contratoContext/ContratoContext";
import NumberFormatCustom from "../../../utils/separadorMil";
import { notificacionWarning } from "../../Notificaciones";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

const initialValue = {
  descripcion: "",
  cantidad: 0,
  precio: 0
};


export default function DetalleContrato() {
  const classes = useStyles();

  const [articulo, setArticulo] = useState(initialValue);
  const localization = Localizacion;
  const contratoContext = useContext(ContratoContext);
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  useEffect(() => {
    setDetalle({ ...detalle, content: contratoContext.detalle.dataDetalle });

  }, [contratoContext.cabecera]);

  const [detalle, setDetalle] = React.useState({
    columnsElectro: [
      {
        field: "descripcion",
        title: "Descripción",
       // editable: "never",
        width: "60%",
      },
      {
        field: "precio",
        title: "Precio",
        width: "30%",
        align:"left"
       // editable: "never",
      //  type: "numeric",
      },
      {
        field: "cantidad",
        title: "Cantidad",
        width: "10%",
       // editable: "never",
        //type: "numeric",
      },
    ],
    content: [],
    totalElelemts: 0,
  });

  const handlePrecioArticulo = (value) => {
   let valueGs = parseFloat(value.target.value).toLocaleString('en')
    setArticulo({ ...articulo, precio: valueGs });
  };
  const handleCantidadArticulo = (value) => {
   let valueCantidad = parseFloat(value.target.value).toLocaleString('en')
    setArticulo({ ...articulo, cantidad: valueCantidad });
  };

  const handleMercaderia = (value) => {
    setArticulo({ ...articulo, descripcion: value.target.value });
  };

  const handleIncluir = (props) => {

    if (articulo.descripcion !== "" && articulo.cantidad !== "") {
      if (Number(articulo.cantidad) <= 0) {
        notificacionWarning("No puede insertar cantidad menor a 1.");
        setArticulo({ ...articulo, cantidad: 0 });
        return;
      }

      let copyInput = { ...detalle };
      let copyContent = [...copyInput.content];

      copyContent.push({
        cantidad: articulo.cantidad,
        descripcion: articulo.descripcion,
        precio: articulo.precio,
      });
      copyInput.content = copyContent;
      setDetalle(copyInput);
      contratoContext.updateContratoDetalle(copyInput.content);
      setArticulo(initialValue);
      document.getElementById("mercaderia").focus();
      //seteamos los campos editables de la cabecera
      contratoContext.updateContratoCabecera({
        ...contratoContext.cabecera,
      });
    } else {
      notificacionWarning("Por favor ingrese los datos para el articulo.");
    }
  };


  return (
    <>
      <Grid container direction="row" alignItems="center" justify="center" spacing={1}>
        {/** cod articulo */}
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

          <TextField
            variant="outlined"
            id="mercaderia"
            name="mercaderia"
            label="MERCADERIA"
            size="small"
            value={articulo.descripcion}
            onChange={(value) => handleMercaderia(value)}
            fullWidth
            autoFocus
          />
        </Grid>
        {/** precio */}
        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
          <TextField
            variant="outlined"
            id="precio"
            name="precio"
            label="Precio"
            size="small"
            value={articulo.precio}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            onChange={(value) => handlePrecioArticulo(value)}
            fullWidth
          />
        </Grid>
        {/** cantidad */}
        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
          <TextField
            variant="outlined"
            id="cantidad"
            name="cantidad"
            label="Cantidad"
            size="small"
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            value={articulo.cantidad}
            onChange={(value) => handleCantidadArticulo(value)}
            fullWidth
          />
        </Grid>

        {/** incluir */}
        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
          <Button
            size="small"
            color="secondary"
            fullWidth
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => handleIncluir()}
          //className={classes.input}
          >
            AGREGAR
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <MaterialTable
            title=""
            columns={detalle.columnsElectro}
            data={detalle.content}
            localization={localization}
            icons={tableIcons}
            options={{
              filtering: false,
              search: true,
              toolbar: false,
              headerStyle: { position: "sticky", top: 0 },
              maxBodyHeight: "55vh",
              paging: false,
              draggable: false,
              rowStyle: {
                fontSize: "small",
              },
            }}
            editable={{
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const dataDelete = [...detalle.content];
                    const index = oldData.tableData.id;
                    dataDelete.splice(index, 1);
                    setDetalle({ ...detalle, content: dataDelete });
                    contratoContext.updateContratoDetalle(dataDelete);
                    resolve();
                  }, 600);
                }),

                onRowUpdate: (newData, oldData) => 
                new Promise((resolve, reject) => {
                  setTimeout(() => {

                  let value =  {
                    cantidad: parseFloat(newData.cantidad.replace(",", "")).toLocaleString('en') , 
                    descripcion: newData.descripcion, 
                    precio: parseFloat(newData.precio.replace(",", "")).toLocaleString('en')  
                  }   
                  console.log(newData.cantidad.replace(",", ""))
                  console.log(value)               
                  const dataUpdate = [...detalle.content];
                  const index = oldData.tableData.id;
                  dataUpdate[index] = value;
                  setDetalle({ ...detalle, content: dataUpdate})
                    contratoContext.updateContratoDetalle(dataUpdate);
                  resolve();
                }, 600);
              }),
            }}
          />
        </Grid>
      </Grid>


    </>
  );
}
