import React, { useState, useEffect, useContext } from "react";
import { alertWarningError, alertaAceptarCancelar } from "../../components/Notificaciones";
import { useStyles } from "../../assets/styles/CustomStyles";
import { MakeTables } from "../../components/MaterialTables/MakeTables";
import { Typography } from "@material-ui/core";
import axios from "../../utils/axios";
import IconButton from '@material-ui/core/IconButton';
import Add from '@material-ui/icons/AddBox';
import Tooltip from '@material-ui/core/Tooltip';
import { useHistory } from "react-router-dom";
import AccesoDenegado from "../../components/AccesoDenegado";
import UserContext from "../../utils/user/UserContext";

export default function ListaUsuarios() {

    const classes = useStyles();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({ content: [] });
    const userContext = useContext(UserContext);

    useEffect(() => {

        if (Number(userContext.state.accesos.administracion) === 1) {
            getUsuario()
        }

    }, []);

    const getUsuario = async (props) => {
        setIsLoading(true);
        let url = props ? props : "sesiones/funListadoGeneral.php";
        try {
            const response = await axios.get(url);

            const usuario = response.data;
            setData({ ...data, content: usuario });

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            if (error.response) {
                alertWarningError(error.response);
            }
        }
    };

    const title = (
        <>
            <Typography variant="body1" display="block">
                LISTA DE USUARIO
                <Tooltip title="Nuevo usuario">
                    <IconButton aria-label="delete" className={classes.button} onClick={() => history.push("./agregar/")}  >
                        <Add fontSize="default" color="action" />
                    </IconButton>
                </Tooltip>
            </Typography>

        </>
    );
    const columns = [
        {
            title: "ID",
            field: "id",
            width: "1%",
            hidden: true
        },
        {
            title: "Usuario",
            field: "nombre",
            width: "10%",
        },
        {
            title: "Nombre",
            field: "nombreUsu",

        },
        {
            title: "Apellido",
            field: "apellidoUsu",

        },
        {
            title: "Correo",
            field: "correo",

        },

    ];

    const options = {
        filtering: false,
        exportButton: false,
        exportAllData: false,
        headerStyle: { position: "sticky", top: 0 },
        maxBodyHeight: "65vh",
        paging: true,
        draggable: false,
        rowStyle: {
            fontSize: "small",
        },
    };

    const editar = (event, props) => {
        //corta el evento el onRowClick
        event.stopPropagation();
        history.push("./editar/", {
            id: props.data.id,
            nombre: props.data.nombre,
            // fecRemision: props.data.fecRemision,
            // nroRemision: props.data.nroRemision,
        });
    };

    const eliminar = (event, props) => {
        const listaArticulo = [...data.content];
        const index = props.data.tableData.id;

        let idUsuario = listaArticulo[index];

        alertaAceptarCancelar({
            text:
                `¿Estás seguro de eliminar el usuario ${idUsuario.nombre}?`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await axios.delete(`/sesiones/funEliminar.php?id=` + Number(idUsuario.id));
                    let status = response.status
                    if (status === 200) {
                        getUsuario();
                    }

                } catch (error) {
                    console.log(error);
                    if (error.response) {
                        alertWarningError(error.response);
                    }
                }
            }
        });
    }

    const actions = [
        {
            icon: "save",
            tooltip: "place-holder",
            onClick: (event, rowData) => alert("You saved " + rowData.name),
            hidden: true,
        },
        {
            icon: "save",
            tooltip: "FreeActions-place-holder",
            isFreeAction: true,
            onClick: (event, rowData) => alert("You saved " + rowData.name),
            hidden: true,
        },
    ];

    return (
        <>
            {Number(userContext.state.accesos.administracion) === 1 ?
                
                <MakeTables
                    // onRowClick={detalle}
                    title={title}
                    columns={columns}
                    data={data?.content}
                    actions={actions}
                    isLoading={isLoading}
                    options={options}
                    classes={classes}
                    componentsAssets={{ classes, editar, eliminar }}

                />
                : <AccesoDenegado />}
        </>
    );
}
