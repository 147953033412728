import React from "react";
import { pathTo } from "./utils";
import Breadcrumb from "@material-ui/core/Breadcrumbs";
import Grid from "@material-ui/core/Grid";

import { NavLink, Link } from "react-router-dom"
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

const Breadcrumbs = ({ route }) => {
  return (
    <Container maxWidth="xl">
      <Grid
        container
        justify="flex-start"
        alignItems="center"
        style={{ paddingBottom: 5, marginTop: 7 }}
      >
        <Breadcrumb aria-label="breadcrumb">
          {pathTo(route).map((crumb, index, breadcrumbs) => (
            <div key={index} className="item">
              {index < breadcrumbs.length - 1 && (
                <NavLink to={crumb.path} name={crumb.label}>
                  <Typography variant="caption" display="block">
                    {crumb.label}
                  </Typography>
                </NavLink>
              )}
              {index === breadcrumbs.length - 1 && <Typography variant="caption" display="block">
                {crumb.label}
              </Typography>}
            </div>
          ))}
        </Breadcrumb>
      </Grid>
    </Container>
  );
};

export default Breadcrumbs;
