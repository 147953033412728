import React, { useState, useEffect, useContext } from "react";
import { MakeTables } from "../../components/MaterialTables/MakeTables";
import { alertWarningError } from "../../components/Notificaciones";
import { useStyles } from "../../assets/styles/CustomStyles";
import { useHistory } from "react-router-dom";

import { Grid, Typography } from "@material-ui/core";
import axios from "../../utils/axios";
import IconButton from '@material-ui/core/IconButton';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Close from "@material-ui/icons/Close";
import Print from "@material-ui/icons/PictureAsPdf";
import CardHeader from '@material-ui/core/CardHeader';
import UserContext from "../../utils/user/UserContext";
import AccesoDenegado from "../../components/AccesoDenegado";

export default function Inventario() {
  const history = useHistory();

  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState([]);
  const userContext = useContext(UserContext);

  useEffect(() => {

    if (Number(userContext.state.accesos.stock) === 1) {
      inventario()
    }

  }, []);




  const inventario = async () => {

    let url = `/facturacion/funListaProductos.php`;

    try {
      setIsLoading(true);

      const response = await axios.get(url);
      const status = response.status;
      if (status === 200) {
        let responseData = response.data;
        setData(responseData);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    } finally {
    }

  };

  const title = (
    <Typography variant="body1" display="block">
      LISTA FACTURACION

    </Typography>
  );
  const columns = [
    {
      title: "Código",
      field: "codigo",
      width: "15%",
    },
    {
      title: "Descripción",
      field: "descripcion",

    },
    {
      title: "Depósito",
      field: "deposito",
      width: "20%",

    },
    {
      title: "Cantidad",
      field: "cantidad",
      width: "10%",
      align: "right",

    },
  ];

  const actions = [
    {
      icon: "save",
      tooltip: "place-holder",
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
    {
      icon: "save",
      tooltip: "FreeActions-place-holder",
      isFreeAction: true,
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
  ];

  const options = {
    toolbar: false,
    filtering: false,
    search: false,
    headerStyle: { position: "sticky", top: 0 },
    maxBodyHeight: "55vh",
    paging: false,
    draggable: false,
    rowStyle: {
      fontSize: "small",
    },
  };



  const handlePDF = async () => {
    let url = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}extensiones/dompdf/pdfs/listaProductos.php?nombre=${userContext.state.nombreUsu}`
    var win = window.open(url, '_blank');
    win.focus();

  }

  return (
    <>
      {Number(userContext.state.accesos.stock) === 1 ?
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{ marginBottom: 10 }}

        >
          <Card className={classes.root}>
            <CardHeader
              action={<>
                <IconButton aria-label="print" onClick={handlePDF}>
                  <Print />
                </IconButton>
                <IconButton aria-label="exit" onClick={(e) => {
                  history.goBack();
                }}>
                  <Close />
                </IconButton>

              </>

              }
              title={<Typography variant="body1" >
                REPORTE DE INVENTARIO DE STOCK

              </Typography>}
            />
            <CardContent >

              <MakeTables
                //  onRowClick={detalle}
                title={title}
                isLoading={isLoading}
                columns={columns}
                data={data}
                actions={actions}
                classes={classes}
                options={options}
                componentsAssets={{ classes, }}
              />
            </CardContent>
          </Card >
        </Grid >

        : <AccesoDenegado />}
    </>
  );
}
