import React, { useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { useStyles } from "../../../assets/styles/CustomStyles";
import MaterialTable from "material-table";
import Localizacion from "../../MaterialTables/Localization";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import AddIcon from "@material-ui/icons/AddCircle";
import Divider from "@material-ui/core/Divider";
import FacturacionContext from "../../../utils/facturacionContext/FacturacionContext";
import NumberFormatCustom from "../../../utils/separadorMil";
import axios from "../../../utils/axios";
import { alertWarningError, notificacionWarning } from "../../Notificaciones";
import TypographyBold from "../../TypographyBold";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

const initialValue = {
  descripcion: "",
  cantidad: 0,
  precio: 0,
  impuesto: "10"
};

export default function DetalleRemision() {
  const classes = useStyles();

  const [articulo, setArticulo] = useState(initialValue);
  const [cantidadRegistro, setCantidadRegistro] = useState(0);
  const [cabecera, setCabecera] = useState("");
  const [total, setTotal] = useState(0);
  const [ivaCinco, setIvaCinco] = useState(0);
  const [ivaDiez, setIvaDiez] = useState(0);
  const localization = Localizacion;
  const facturacionContext = useContext(FacturacionContext);
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  useEffect(() => {
    setDetalle({ ...detalle, content: facturacionContext.detalle.dataDetalle });
    setCantidadRegistro(facturacionContext.cabeceraMaxItem);
    setCabecera(facturacionContext.cabecera);
    setTotal(facturacionContext.cabecera.total);
    setIvaDiez(facturacionContext.cabecera.iva_10);
    setIvaCinco(facturacionContext.cabecera.iva_5);
  }, [facturacionContext.cabecera]);


  const [detalle, setDetalle] = useState({
    columns: [
      {
        field: "cantidad",
        title: "Cantidad",
        width: "5%",
        editable: "never",

      },
      {
        field: "descripcion",
        title: "Descripción",
        editable: "never",
        width: "55%",
      },
      {
        field: "impuesto",
        title: "Impuesto",
        editable: "never",
        width: "10%",
        type: "numeric",
      },
      {
        field: "precio_unitario",
        title: "Precio unitario",
        width: "15%",
        editable: "never",
        type: "numeric",
      },
      {
        field: "sub_total",
        title: "Sub Total",
        width: "15%",
        editable: "never",
        type: "numeric",
      },
    ],
    content: [],
  });

  const handlePrecioArticulo = (value) => {
    let valueGs = parseFloat(value.target.value).toLocaleString('en')
    setArticulo({ ...articulo, precio: valueGs });
  };

  const handleCantidadArticulo = (value) => {
    let valueCantidad = parseFloat(value.target.value).toLocaleString('en')
    setArticulo({ ...articulo, cantidad: valueCantidad });
  };
  const handleInpuestoArticulo = (value) => {
    setArticulo({ ...articulo, impuesto: value.target.value });
  };

  const handleMercaderia = (value) => {
    setArticulo({ ...articulo, descripcion: value.target.value });
  };


  const handleIncluir = (props) => {

    if (detalle.content.length >= cantidadRegistro) {
      notificacionWarning("No puede superar la cantidad maxima de items.");
      return;
    }

    if (articulo.descripcion !== "" && articulo.cantidad !== "" && articulo.precio !== "" && articulo.impuesto !== "") {
      if (Number(articulo.cantidad) <= 0) {
        notificacionWarning("No puede insertar cantidad menor a 1.");
        setArticulo({ ...articulo, cantidad: 0 });
        return;
      }

      let copyInput = { ...detalle };
      let copyContent = [...copyInput.content];
      let suma = articulo.precio.toString().replace(/,/g, "") * articulo.cantidad.toString().replace(/,/g, "")

      let totalSuma = (Number(suma) + Number(total))

      //verificamos el iva
      if (articulo.impuesto === "exenta") {
        //actualizamos la cabecera
        let cabeceraCopy = {
          ...cabecera,
          total: totalSuma
        };
        facturacionContext.updateFacturaCabecera(cabeceraCopy)
      } else {

        if (articulo.impuesto === "10") {
          let iva10 = (Number(suma) / 11).toFixed(2)
          let sumaIva10 = Number(iva10) + ivaDiez
          //actualizamos la cabecera
          let cabeceraCopy = {
            ...cabecera,
            iva_10: Math.round(sumaIva10),
            total: totalSuma
          };

          setIvaDiez(Math.round(sumaIva10))
          facturacionContext.updateFacturaCabecera(cabeceraCopy)

        } else {

          let iva5 = (Number(suma) / 21).toFixed(2)
          let sumaIva5 = Number(iva5) + ivaCinco
          //actualizamos la cabecera
          let cabeceraCopy = {
            ...cabecera,
            iva_5: Math.round(sumaIva5),
            total: totalSuma
          };

          setIvaCinco(Math.round(sumaIva5))
          facturacionContext.updateFacturaCabecera(cabeceraCopy)
       
        }

      }
      copyContent.push({
        cantidad: articulo.cantidad,
        descripcion: articulo.descripcion?.toUpperCase(),
        precio_unitario: articulo.precio,
        sub_total: suma.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        impuesto: articulo.impuesto
      });

      setTotal(totalSuma)

      copyInput.content = copyContent;
      setDetalle(copyInput);
      facturacionContext.updateFacturaDetalle(copyInput.content);
      setArticulo(initialValue);
      document.getElementById("mercaderia").focus();

    } else {
      notificacionWarning("Por favor ingrese los datos para el articulo.");
    }
  };


  return (
    <>
      <Grid container direction="row" alignItems="center" justify="center" spacing={1}>
        {/** cod articulo */}
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

          <TextField
            variant="outlined"
            id="mercaderia"
            name="mercaderia"
            label="MERCADERIA"
            size="small"
            value={articulo.descripcion}
            onChange={(value) => handleMercaderia(value)}
            fullWidth
            autoFocus
          />
        </Grid>
        {/** cantidad */}
        <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
          <TextField
            variant="outlined"
            id="cantidad"
            name="cantidad"
            label="Cantidad"
            size="small"
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            value={articulo.cantidad}
            onChange={(value) => handleCantidadArticulo(value)}
            fullWidth
          />
        </Grid>
        {/** impuesto */}
        <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
            size="small"
            required
          >
            <InputLabel id="tipoDoc-label">Impuesto</InputLabel>
            <Select
              labelId="impuesto-label"
              id="impuesto"
              fullWidth
              value={articulo.impuesto}
              onChange={(value) => handleInpuestoArticulo(value)}
              label="Impuesto"
            >
              <MenuItem value="5" key="5">5%</MenuItem>
              <MenuItem value="10" key="10">10%</MenuItem>
              <MenuItem value="exenta" key="exenta">Excenta</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/** precio */}
        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
          <TextField
            variant="outlined"
            id="precio"
            name="precio"
            label="Precio unitario"
            size="small"
            value={articulo.precio}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            onChange={(value) => handlePrecioArticulo(value)}
            fullWidth
          />
        </Grid>


        {/** incluir */}
        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
          <Button
            size="small"
            color="secondary"
            fullWidth
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => handleIncluir()}
          //className={classes.input}
          >
            AGREGAR
          </Button>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <MaterialTable
            title=""
            columns={detalle.columns}
            data={detalle.content}
            localization={localization}
            icons={tableIcons}
            options={{
              filtering: false,
              search: true,
              toolbar: false,
              headerStyle: { position: "sticky", top: 0 },
              maxBodyHeight: "55vh",
              paging: false,
              draggable: false,
              rowStyle: {
                fontSize: "small",
              },
            }}
            editable={{
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const dataDelete = [...detalle.content];
                    const index = oldData.tableData.id;

                    let resta = (total - oldData.sub_total.toString().replace(/,/g, ""))
                    setTotal(resta)

                    //verificamos el iva
                    if (oldData?.impuesto === "exenta") {
                      //actualizamos la cabecera
                      let cabeceraCopy = {
                        ...cabecera,
                        total: resta
                      };
                      facturacionContext.updateFacturaCabecera(cabeceraCopy)
                      dataDelete.splice(index, 1);
                      facturacionContext.updateFacturaDetalle(dataDelete);
                      setDetalle({ ...detalle, content: dataDelete });
                      
                    } else {

                      if (oldData?.impuesto === "10") {
                        let totalItem = oldData.sub_total.toString().replace(/,/g, "")
                        let iva10 = (Number(totalItem) / 11).toFixed(2)
                        let restaIva10 = ivaDiez - Number(iva10)
                        //actualizamos la cabecera
                        let cabeceraCopy = {
                          ...cabecera,
                          iva_10: Math.round(restaIva10),
                          total: resta
                        };

                        setIvaDiez(Math.round(restaIva10))
                        facturacionContext.updateFacturaCabecera(cabeceraCopy)
                        setCabecera(cabeceraCopy)

                        dataDelete.splice(index, 1);
                        setDetalle({ ...detalle, content: dataDelete });
                        facturacionContext.updateFacturaDetalle(dataDelete);

                      } else {
                        let totalItem = oldData.sub_total.toString().replace(/,/g, "")
                        let iva5 = (Number(totalItem) / 21).toFixed(2)
                        let restaIva5 = ivaCinco - Number(iva5)
                        //actualizamos la cabecera
                        let cabeceraCopy = {
                          ...cabecera,
                          iva_5: Math.round(restaIva5),
                          total: resta
                        };

                        setIvaCinco(Math.round(restaIva5))
                        facturacionContext.updateFacturaCabecera(cabeceraCopy)
                        setCabecera(cabeceraCopy)

                        dataDelete.splice(index, 1);
                        setDetalle({ ...detalle, content: dataDelete });
                        facturacionContext.updateFacturaDetalle(dataDelete);

                      }

                    }




                    resolve();
                  }, 600);
                }),
            }}
          />
        </Grid>
        <Grid container direction="row" className={classes.alignDerecha}>

          {/** total e IVA */}

          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
            <List dense>
              <ListItem>
                <ListItemIcon >
                  <TypographyBold variant="subtitle2" color="inherit" >
                    IVA (5%):
                  </TypographyBold>
                </ListItemIcon>
                <ListItemText
                  className={classes.buttonFiltro}
                  primary={ivaCinco.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                />
              </ListItem>
            </List>
          </Grid>

          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
            <List dense>
              <ListItem>
                <ListItemIcon >
                  <TypographyBold variant="subtitle2" color="inherit" >
                    (10%):
                  </TypographyBold>
                </ListItemIcon>
                <ListItemText
                  className={classes.buttonFiltro}
                  primary={ivaDiez.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                />
              </ListItem>
            </List>
          </Grid>

          <Grid item xs={12} sm={12} md={2} lg={2} xl={2} >
            <List dense>
              <ListItem>
                <ListItemIcon >
                  <TypographyBold variant="subtitle2" color="inherit" >
                    TOTAL:
                  </TypographyBold>
                </ListItemIcon>
                <ListItemText
                  className={classes.buttonFiltro}
                  primary={total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                />
              </ListItem>

            </List>
          </Grid>


        </Grid>
      </Grid>

    </>
  );
}
