import { useHistory } from "react-router-dom";
import React, { useEffect, useState, useContext } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "../../assets/styles/CustomStyles";
import axios from "../../utils/axios";
import Box from "@material-ui/core/Box";
import {
  alertWarningError,
} from "../../components/Notificaciones";
import TypographyBold from "../../components/TypographyBold";
import BackdropCustom from "../../components/BackdropCustom";
import Paper from "@material-ui/core/Paper";
import MaterialTable from "material-table";
import Localizacion from "../../components/MaterialTables/Localization";
import TableIcons from "../../components/MaterialTables/TableIcons";
import Chip from "@material-ui/core/Chip";
import Close from "@material-ui/icons/Close";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";
import CardHeader from '@material-ui/core/CardHeader';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DateIcon from '@material-ui/icons/DateRange';
import IconButton from '@material-ui/core/IconButton';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AccesoDenegado from "../../components/AccesoDenegado";
import UserContext from "../../utils/user/UserContext";

const tableColumns = [
  {
    field: "descripcion", title: "N°", width: "5%", render: rowData => <Typography variant="caption" display="block" > {rowData.tableData.id + 1}</Typography>
  },
  { field: "descripcion", title: "Descripción" },
  { field: "cantidad", title: "Cantidad", align: "right", width: "10%" },
];

const DetalleRemisionAnulado = () => {
  const history = useHistory();

  const dataProps = history.location.state;
  const userContext = useContext(UserContext);
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [remision, setRemision] = useState({
    content: []
  });

  const localization = Localizacion;
  const tableIcons = TableIcons;

  useEffect(() => {
    getRemision();
  }, []);


  const getRemision = async () => {
    console.log(dataProps)
    try {
      setIsLoading(true);
      const response = await axios.get("remision/funConsultaIndividual.php", {
        params: {
          id: dataProps.id,
        },
      });
      let status = response.status
      if (status === 200) {
        setIsLoading(false);
        setRemision(response.data[0]);
        console.log(response.data[0]);
      }

    } catch (error) {
      if (error.response) {
        alertWarningError(error.response);
      }
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <>
      {Number(userContext.state.accesos.remision) === 1 ?
        <>
          <BackdropCustom open={isLoading} />

          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ marginBottom: 10 }}

          >
            <Card className={classes.root}>
              <CardHeader
                action={<>
                  <Chip label={remision?.fecha_activacion} variant="default" icon={<DateIcon />} />
                  <IconButton aria-label="pdf" onClick={(e) => {
                    alert("se genera pdf");
                  }}>
                    <PictureAsPdf />
                  </IconButton>
                  <IconButton aria-label="exit" onClick={(e) => {
                    history.goBack();
                  }}>
                    <Close />
                  </IconButton>

                </>

                }
                title={<Typography variant="body1" >
                  INFORMACION DE LA NOTA DE REMISION  <Chip label={remision?.nro_factura} variant="default" size="small" icon={<AssignmentIcon />} />
                </Typography>}
              />
              <CardContent >
                <Paper >
                  <Box p={1} mb={2} >
                    <Box ml={1} >
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TypographyBold variant="body2" display="block" color="textSecondary">
                          DESTINATARIO DE LA MERCADERÍA
                        </TypographyBold>
                        <Grid container direction="row" >

                          <Grid item xs={12} sm={12} md={6} lg={6} xl={5}>
                            <Grid container>
                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                                <List dense>
                                  <ListItem>
                                    <ListItemIcon className={classes.buttonFiltro}>
                                      <TypographyBold variant="subtitle2" color="inherit" >
                                        Denominación:
                                      </TypographyBold>
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={remision?.nombre_cliente}
                                    />
                                  </ListItem>
                                  <ListItem>
                                    <ListItemIcon className={classes.buttonFiltro}>
                                      <TypographyBold variant="subtitle2" color="inherit" >
                                        RUC:
                                      </TypographyBold>
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={remision?.ruc}
                                    />
                                  </ListItem>

                                </List>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={12} md={6} lg={6} xl={5}>
                            <Grid container >
                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <List dense>
                                  <ListItem>
                                    <ListItemIcon className={classes.buttonFiltro}>
                                      <TypographyBold variant="subtitle2" color="inherit" >
                                        Domicilio:
                                      </TypographyBold>
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={remision?.direccion}
                                    />
                                  </ListItem>


                                </List>

                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>

                    <Box ml={1} mt={1}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TypographyBold variant="body2" display="block" color="textSecondary">
                          DATOS DEL TRASLADO
                        </TypographyBold>
                        <Grid container direction="row" >
                          {/** izquierda */}
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={5}>
                            <Grid container direction="row">
                              <List dense>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <ListItem>
                                    <ListItemIcon className={classes.buttonFiltro}>
                                      <TypographyBold variant="subtitle2" color="inherit" >
                                        Motivo del traslado:
                                      </TypographyBold>
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={remision?.motivo_del_traslado}
                                    />
                                  </ListItem>

                                  <ListItem>
                                    <ListItemIcon className={classes.buttonFiltro}>
                                      <TypographyBold variant="subtitle2" color="inherit" >
                                        Comprobante de venta:
                                      </TypographyBold>
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={remision?.comprobante_de_venta}
                                    />
                                  </ListItem>

                                  <ListItem>
                                    <ListItemIcon className={classes.buttonFiltro}>
                                      <TypographyBold variant="subtitle2" color="inherit" >
                                        Comprobante de venta N°:
                                      </TypographyBold>
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={remision?.nro_comprobante_de_venta}
                                    />
                                  </ListItem>


                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <Grid container direction="row">
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                      <ListItem>
                                        <ListItemIcon className={classes.buttonFiltro}>
                                          <TypographyBold variant="subtitle2" color="inherit" >
                                            N° de timbrado:
                                          </TypographyBold>
                                        </ListItemIcon>
                                        <ListItemText
                                          primary={remision?.nro_de_timbrado}
                                        />
                                      </ListItem>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                      <ListItem>
                                        <ListItemIcon className={classes.buttonFiltro}>
                                          <TypographyBold variant="subtitle2" color="inherit" >
                                            Fecha expedición:
                                          </TypographyBold>
                                        </ListItemIcon>
                                        <ListItemText
                                          primary={remision?.fecha_de_expedicion}
                                        />
                                      </ListItem>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                      <ListItem>
                                        <ListItemIcon className={classes.buttonFiltro}>
                                          <TypographyBold variant="subtitle2" color="inherit" >
                                            Fecha inicio traslado:
                                          </TypographyBold>
                                        </ListItemIcon>
                                        <ListItemText
                                          primary={remision?.fecha_de_inicio_de_traslado}
                                        />
                                      </ListItem>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                      <ListItem>
                                        <ListItemIcon className={classes.buttonFiltro}>
                                          <TypographyBold variant="subtitle2" color="inherit" >
                                            Fecha fin traslado:
                                          </TypographyBold>
                                        </ListItemIcon>
                                        <ListItemText
                                          primary={remision?.fecha_de_termino_de_traslado}
                                        />
                                      </ListItem>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <ListItem>
                                    <ListItemIcon className={classes.buttonFiltro}>
                                      <TypographyBold variant="subtitle2" color="inherit" >
                                        Cambio de fecha traslado y/o punto llegada:
                                      </TypographyBold>
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={remision?.cambio_de_fecha}
                                    />
                                  </ListItem>
                                </Grid>

                              </List>
                            </Grid>
                          </Grid>
                          {/** derecha */}
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={5}>
                            <Grid container >
                              <List dense>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <Grid container direction="row">
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                      <ListItem>
                                        <ListItemIcon className={classes.buttonFiltro}>
                                          <TypographyBold variant="subtitle2" color="inherit" >
                                            Dirección de partida:
                                          </TypographyBold>
                                        </ListItemIcon>
                                        <ListItemText
                                          primary={remision?.direccion_partida}
                                        />
                                      </ListItem>

                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                      <ListItem>
                                        <ListItemIcon className={classes.buttonFiltro}>
                                          <TypographyBold variant="subtitle2" color="inherit" >
                                            Ciudad:
                                          </TypographyBold>
                                        </ListItemIcon>
                                        <ListItemText
                                          primary={remision?.ciudad_partida}
                                        />
                                      </ListItem>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                      <ListItem>
                                        <ListItemIcon className={classes.buttonFiltro}>
                                          <TypographyBold variant="subtitle2" color="inherit" >
                                            Departamento:
                                          </TypographyBold>
                                        </ListItemIcon>
                                        <ListItemText
                                          primary={remision?.departamento_partida}
                                        />
                                      </ListItem>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                      <ListItem>
                                        <ListItemIcon className={classes.buttonFiltro}>
                                          <TypographyBold variant="subtitle2" color="inherit" >
                                            Dirección de llegada:
                                          </TypographyBold>
                                        </ListItemIcon>
                                        <ListItemText
                                          primary={remision?.direccion_llegada}
                                        />
                                      </ListItem>

                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                      <ListItem>
                                        <ListItemIcon className={classes.buttonFiltro}>
                                          <TypographyBold variant="subtitle2" color="inherit" >
                                            Ciudad:
                                          </TypographyBold>
                                        </ListItemIcon>
                                        <ListItemText
                                          primary={remision?.ciudad_llegada}
                                        />
                                      </ListItem>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                      <ListItem>
                                        <ListItemIcon className={classes.buttonFiltro}>
                                          <TypographyBold variant="subtitle2" color="inherit" >
                                            Departamento:
                                          </TypographyBold>
                                        </ListItemIcon>
                                        <ListItemText
                                          primary={remision?.departamento_llegada}
                                        />
                                      </ListItem>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                      <ListItem>
                                        <ListItemIcon className={classes.buttonFiltro}>
                                          <TypographyBold variant="subtitle2" color="inherit" >
                                            Km de recorrido:
                                          </TypographyBold>
                                        </ListItemIcon>
                                        <ListItemText
                                          primary={remision?.kilometros_de_recorrido}
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <ListItemIcon className={classes.buttonFiltro}>
                                          <TypographyBold variant="subtitle2" color="inherit" >
                                            Motivo:
                                          </TypographyBold>
                                        </ListItemIcon>
                                        <ListItemText
                                          primary={remision?.motivo}
                                        />
                                      </ListItem>

                                    </Grid>
                                  </Grid>
                                </Grid>
                              </List>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                    </Box>

                    <Box ml={1} mt={1}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TypographyBold variant="body2" display="block" color="textSecondary">
                          DATOS DEL VEHICULO DE TRANSPORTE
                        </TypographyBold>
                        <List dense>
                          <Grid container direction="row" >
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                              <ListItem>
                                <ListItemIcon className={classes.buttonFiltro}>
                                  <TypographyBold variant="subtitle2" color="inherit" >
                                    Marca del vehículo:
                                  </TypographyBold>
                                </ListItemIcon>
                                <ListItemText
                                  primary={remision?.marca_del_vehiculo}
                                />
                              </ListItem>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                              <ListItem>
                                <ListItemIcon className={classes.buttonFiltro}>
                                  <TypographyBold variant="subtitle2" color="inherit" >
                                    N° de RUA:
                                  </TypographyBold>
                                </ListItemIcon>
                                <ListItemText
                                  primary={remision?.nro_de_rua}
                                />
                              </ListItem>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                              <ListItem>
                                <ListItemIcon className={classes.buttonFiltro}>
                                  <TypographyBold variant="subtitle2" color="inherit" >
                                    N° de RUA:
                                  </TypographyBold>
                                </ListItemIcon>
                                <ListItemText
                                  primary={remision?.nro_rua_remolque}
                                />
                              </ListItem>
                            </Grid>

                          </Grid>
                        </List>
                      </Grid>
                    </Box>

                    <Box ml={1} mt={1}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TypographyBold variant="body2" display="block" color="textSecondary">
                          DATOS DEL CONDUCTOR DEL VEHICULO
                        </TypographyBold>
                        <List dense>
                          <Grid container direction="row" >
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                              <ListItem>
                                <ListItemIcon className={classes.buttonFiltro}>
                                  <TypographyBold variant="subtitle2" color="inherit" >
                                    Nombre y Apellido o Razón Social:
                                  </TypographyBold>
                                </ListItemIcon>
                                <ListItemText
                                  primary={remision?.nombre_transporte}
                                />
                              </ListItem>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                              <ListItem>
                                <ListItemIcon className={classes.buttonFiltro}>
                                  <TypographyBold variant="subtitle2" color="inherit" >
                                    RUC o CI:
                                  </TypographyBold>
                                </ListItemIcon>
                                <ListItemText
                                  primary={remision?.ruc_transporte}
                                />
                              </ListItem>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                              <ListItem>
                                <ListItemIcon className={classes.buttonFiltro}>
                                  <TypographyBold variant="subtitle2" color="inherit" >
                                    Domicilio:
                                  </TypographyBold>
                                </ListItemIcon>
                                <ListItemText
                                  primary={remision?.domicilio_transporte}
                                />
                              </ListItem>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                              <ListItem>
                                <ListItemIcon className={classes.buttonFiltro}>
                                  <TypographyBold variant="subtitle2" color="inherit" >
                                    Observación:
                                  </TypographyBold>
                                </ListItemIcon>
                                <ListItemText
                                  primary={remision?.observacion}
                                />
                              </ListItem>
                            </Grid>

                          </Grid>
                        </List>
                      </Grid>
                    </Box>
                  </Box>

                </Paper>


                <MaterialTable

                  columns={tableColumns}
                  data={remision.items}
                  localization={localization}
                  icons={tableIcons}
                  options={{
                    // showTitle: false,
                    toolbar: false,
                    filtering: false,
                    search: false,
                    headerStyle: { position: "sticky", top: 0 },
                    maxBodyHeight: "55vh",
                    paging: false,
                    draggable: false,
                    rowStyle: {
                      fontSize: "small",
                    },
                  }}

                />

              </CardContent>

            </Card>
          </Grid>

        </>
        : <AccesoDenegado />}

    </>
  );
};

export default DetalleRemisionAnulado;
