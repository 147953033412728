import { createMuiTheme } from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";
import { esES } from "@material-ui/core/locale";

export const darkTheme = createMuiTheme(

  {
    body: {
      background: "#0c1139"
    },
    palette: {
      type: "dark",
      primary: { main: "#0c1139" },
      secondary: blue,
    },
    overrides: {
      MuiTableCell: {
        root: {
          padding: 8,
        },
      },
      MuiIconButton: {
        root: {
          padding: 7,
        },
      },
      MuiDialogActions: {
        root: {
          justifyContent: "space-evenly",
        },
      },
      MuiDialogContent: {
        root: {
          background: "#333333",
        },
      },
      MuiCardHeader: {
        root: {
          paddingRight: 16,
          paddingLeft: 16,
          paddingBottom: 0,
          paddingTop: 16,
        }
      },
      MuiCardContent: {
        root: {
          paddingRight: 16,
          paddingLeft: 16,
          paddingBottom: 16,
          paddingTop: 5,
        }
      },
      MuiTableRow: {
        root: {
          "&:hover": {
            background: "#303030",
          },
        },
      },
      MuiTooltip: {
        tooltip: {
          fontSize: "1em",
        },
      },
      MuiInputBase: {
        input: {
          textTransform: "uppercase",
        },
      },

      // MuiBackdrop: {
      //   root: {
      //     zIndex: 2000,
      //     color: "#fff",
      //   },
      // },
      // MuiTableSortLabel: {
      //   root: {
      //     color: "#303030",
      //     "&:hover": {
      //       color: "#bbdefb",
      //     },
      //   },
      //   active: {
      //     color: "#bbdefb",
      //   },
      // },
    },
  },
  esES
);

export const lightTheme = createMuiTheme(
  {

    palette: {
      type: "light",
      primary: { main: "#396b99" },
      secondary: blue,
      background: {
        default: "#ebf6ff"
      }
    },
    overrides: {
      MuiTableCell: {
        root: {
          padding: 8,
        },
      },
      MuiIconButton: {
        root: {
          padding: 7,
        },
      },
      MuiDialogActions: {
        root: {
          justifyContent: "space-evenly",
        },
      },
      MuiDialogContent: {
        root: {
          background: "#f5f5f5",
        },
      },
      MuiCardHeader: {
        root: {
          paddingRight: 16,
          paddingLeft: 16,
          paddingBottom: 0,
          paddingTop: 16,
        }
      },
      MuiCardContent: {
        root: {
          paddingRight: 16,
          paddingLeft: 16,
          paddingBottom: 16,
          paddingTop: 5,
        }
      },
      MuiTableRow: {
        root: {
          "&:hover": {
            background: "#cfd8dc",
          },
        },
      },
      MuiTooltip: {
        tooltip: {
          fontSize: "1em",
        },
      },

      // MuiInputBase: {
      //   input: {
      //     textTransform: "uppercase",
      //   },
      // },

      // MuiBackdrop: {

      //   root: {
      //     zIndex: 2000,
      //     color: "#fff",
      //   },
      // },
    },
  },
  esES
);

export default lightTheme;
