import React, { useState, useEffect, useContext } from "react";
import { alertWarningError } from "../../components/Notificaciones";
import { useStyles } from "../../assets/styles/CustomStyles";
import { Typography } from "@material-ui/core";
import axios from "../../utils/axios";
import MaterialTable from "material-table";
import Localizacion from "../../components/MaterialTables/Localization";
import TableIcons from "../../components/MaterialTables/TableIcons";
import AccesoDenegado from "../../components/AccesoDenegado";
import UserContext from "../../utils/user/UserContext";

export default function ListaProductos() {

    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({ content: [] });
    const localization = Localizacion;
    const tableIcons = TableIcons;
    const userContext = useContext(UserContext);
    useEffect(() => {
        if (Number(userContext.state.accesos.stock) === 1) {
            getProducto()
        }

    }, []);

    const getProducto = async (props) => {
        setIsLoading(true);
        let url = props ? props : "producto/funListadoGeneral.php";
        try {
            const response = await axios.get(url);

            const productos = response.data;
            setData({ ...data, content: productos });

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            if (error.response) {
                alertWarningError(error.response);
            }
        }
    };

    const title = (
        <Typography variant="body1" display="block">
            LISTA DE PRODUCTOS
        </Typography>
    );
    const columns = [
        {
            title: "ID",
            field: "id",
            width: "1%",
            hidden: true
        },
        {
            title: "Código",
            field: "codigo",
            width: "10%",
        },
        {
            title: "Descripción",
            field: "descripcion",

        },
        {
            title: "IVA",
            field: "iva",
            lookup: { 5: '5%', 10: '10%', Excenta: 'Excenta' },

        },
        {
            title: "Precio",
            field: "venta",
            type: "numeric"

        },

    ];

    const options = {
        filtering: false,
        exportButton: false,
        exportAllData: false,
        headerStyle: { position: "sticky", top: 0 },
        maxBodyHeight: "65vh",
        draggable: false,
        rowStyle: {
            fontSize: "small",
        },
    };

    return (
        <>
            {Number(userContext.state.accesos.stock) === 1 ?
                <MaterialTable
                    title={title}
                    columns={columns}
                    data={data.content}
                    options={options}
                    isLoading={isLoading}
                    componentsAssets={{ classes }}
                    localization={localization}
                    icons={tableIcons}
                    editable={{
                        onRowAdd: async (newData) => {
                            const dataNew = [...data.content];
                            console.log(newData)
                            try {

                                const response = await axios({
                                    method: "POST",
                                    url: "producto/funAgregar.php",
                                    data: newData,
                                });
                                let status = response.status

                                if (status === 200) {
                                    dataNew.push(newData)
                                    setData({ ...data, content: dataNew });
                                }

                            } catch (error) {
                                if (error.response) {
                                    alertWarningError(error.response);
                                }
                            }
                        },
                        onRowUpdate: async (newData, oldData) => {
                            console.log(newData)
                            const dataUpdate = [...data.content];
                            const index = oldData.tableData.id;

                            try {

                                const response = await axios({
                                    method: "POST",
                                    url: "producto/funModificar.php",
                                    data: newData,
                                });
                                let status = response.status

                                if (status === 200) {
                                    dataUpdate[index] = newData;
                                    setData({ ...data, content: dataUpdate });

                                }

                            } catch (error) {
                                if (error.response) {
                                    alertWarningError(error.response);
                                }
                            }
                        },

                        onRowDelete: async (oldData) => {
                            const listaArticulo = [...data.content];
                            const index = oldData.tableData.id;
                            try {
                                let idcliente = listaArticulo[index];

                                const response = await axios.delete(`/producto/funEliminar.php?id=` + Number(idcliente.id))
                                let status = response.status

                                if (status === 200) {
                                    listaArticulo.splice(index, 1);

                                    setData({ ...data, content: listaArticulo });
                                }

                            } catch (error) {
                                if (error.response) {
                                    alertWarningError(error.response);
                                }
                            }

                        },
                    }}
                />
                : <AccesoDenegado />}
        </>
    );
}
