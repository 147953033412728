import { useHistory } from "react-router-dom";
import React, { useEffect, useState, useContext } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "../../assets/styles/CustomStyles";
import axios from "../../utils/axios";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import {
  notificacionExitosaV2,
  alertWarningError, notificacionWarning
} from "../../components/Notificaciones";
import TypographyBold from "../../components/TypographyBold";
import BackdropCustom from "../../components/BackdropCustom";
import Paper from "@material-ui/core/Paper";
import MaterialTable from "material-table";
import Localizacion from "../../components/MaterialTables/Localization";
import TableIcons from "../../components/MaterialTables/TableIcons";
import Close from "@material-ui/icons/Close";
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Divider from "@material-ui/core/Divider";
import InputBase from "@material-ui/core/InputBase";
import Search from "@material-ui/icons/Search";
import CircularProgress from "@material-ui/core/CircularProgress";
import CardActions from '@material-ui/core/CardActions';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from "@material-ui/icons/AddCircle";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import BuscadorArticuloConDepositoDialog from "../../components/BuscadorArticuloConDepositoDialog";
import AccesoDenegado from "../../components/AccesoDenegado";
import UserContext from "../../utils/user/UserContext";

const tableColumns = [
  { field: "id", title: "ID", width: "1%", hidden: true },
  { field: "codigo", title: "Código", width: "10%" },
  { field: "descripcion", title: "Descripción" },
  { field: "deposito", title: "Deposito", width: "30%" },
  { field: "stock", title: "Stock", align: "right", width: "10%" },
  { field: "cantidad", title: "Cantidad", align: "right", width: "10%" },
];


const initialValueArticulo = {
  articulo: { id: "", codigo: "", descripcion: "" },
  deposito: { id: "", descripcion: "" },
  stock: 0,
  cantidad: 0
};

const AjustarStock = () => {
  const history = useHistory();

  const classes = useStyles();
  const [articulo, setArticulo] = useState(initialValueArticulo);
  const [isLoadingArticulo, setIsLoadiongArticulo] = useState(false);
  const [openArticulo, setOpenArticulo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [operacion, setOperacion] = useState("alta")
  const [motivo, setMotivo] = useState("")
  const [deposito, setDeposito] = useState({
    content: [],
  });

  const [detalle, setDetalle] = useState({
    content: [],
  });
  const localization = Localizacion;
  const tableIcons = TableIcons;
  const userContext = useContext(UserContext);
  useEffect(() => {


  }, []);


  const handleChangeOperacion = (event) => {
    setOperacion(event.target.value);
  };

  const handleMotivoOperacion = (event) => {
    setMotivo(event.target.value);
  };

  const handleArticulo = (value) => {
    let copyInput = {
      ...articulo,
      articulo: {
        id: value?.id,
        codigo: value?.codigo,
        descripcion: value?.descripcion,
      },
    };
    setArticulo(copyInput);
    setDeposito({ ...deposito, content: value?.depositos })
  };

  const handleChangeArticulo = (value) => {
    let copyInput = {
      ...articulo,
      articulo: {
        codigo: value.target.value,
        descripcion: "",
        id: "",
      },
    };
    setArticulo(copyInput);
  };

  const handleOpenArticulo = () => {
    setOpenArticulo(true);
  };

  const handleCloseArticulo = (value) => {
    setOpenArticulo(false);
  };

  const loadArticulo = async () => {
    let codArticulo = articulo.articulo?.codigo?.toUpperCase();
    if (codArticulo) {
      try {
        setIsLoadiongArticulo(true);

        const response = await axios.get(
          `producto/funConsultaIndividualCodigoDeposito.php?codigo=${codArticulo}`
        );

        let data = response.data;

        let status = response.status
        if (status = 200) {
          if (data.length < 0) {
            notificacionWarning(
              "No se encontró el artículo con código " + codArticulo
            );
            return;
          }

          if (data.length > 1) {
            notificacionWarning(
              "Se encontró más de un resultado para el artículo con código " +
              codArticulo
            );
            return;
          }

          handleArticulo(data[0]);

        }


      } catch (error) {
        if (error.response) {
          alertWarningError(error.response);
        }
      } finally {
        setIsLoadiongArticulo(false);
      }
    }
  };


  const handleGuardar = async () => {

    let objetData = {
      operacion: operacion,
      motivo: motivo,
      articulos: detalle.content
    }
    console.log(objetData)
    try {
      const response = await axios.post("producto/funAltaBajaProducto.php", objetData);

      let status = response.status
      if (status === 200) {
        notificacionExitosaV2({
          text: "Stock ajustado exitosamente.",
          afterWork: () => {
            history.goBack();
          },
        });


      }

    } catch (error) {

      if (error.response) {
        alertWarningError(error.response);
      }
    }
  }

  const handleIncluir = (props) => {

    if (articulo.articulo?.descripcion !== "" && articulo.cantidad !== "") {
      if (Number(articulo.cantidad) <= 0) {
        notificacionWarning("No puede insertar cantidad menor a 1.");
        setArticulo({ ...articulo, cantidad: 0 });
        return;
      }

      let copyInput = { ...detalle };
      let copyContent = [...copyInput.content];

      copyContent.push({
        id: articulo.articulo.id,
        descripcion: articulo.articulo.descripcion?.toUpperCase(),
        deposito: articulo.deposito.descripcion,
        idDeposito: articulo.deposito.id,
        cantidad: articulo.cantidad,
        stock: articulo.stock
      });
      copyInput.content = copyContent;
      setDetalle(copyInput);
      setDeposito({ ...deposito, content: [] })
      console.log(articulo)
      console.log(initialValueArticulo);
      setArticulo(initialValueArticulo);
      document.getElementById("codArticulo").focus();
      //seteamos los campos editables de la cabecera
    } else {
      notificacionWarning("Por favor ingrese los datos para el articulo.");
    }
  };


  const handleCantidadArticulo = (value) => {

    let copyInput = {
      ...articulo,
      cantidad: value.target.value,
    };
    setArticulo(copyInput);
  };



  const onSelectDeposito = (e, value) => {
    if (value && value.id !== articulo.deposito.id) {
      let copyInput = {
        ...articulo,
        deposito: {
          id: value?.id,
          codigo: value?.codigo,
          descripcion: value?.descripcion,
        },
        stock: value?.cantidad
      };
      setArticulo(copyInput);

    }

  };
  return (
    <>
      {Number(userContext.state.accesos.stock) === 1 ?
        <>
          <BackdropCustom open={isLoading} />

          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ marginBottom: 10 }}

          >
            <Card className={classes.root}>
              <CardHeader
                action={<>

                  <IconButton aria-label="exit" onClick={(e) => {
                    history.goBack();
                  }}>
                    <Close />
                  </IconButton>

                </>

                }
                title={<Typography variant="body1" >
                  AJUSTAR STOCK
                </Typography>}
              />
              <CardContent >
                <Paper >
                  <Box p={1} mb={2} >
                    <Box ml={1} >
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                        <Grid container direction="row" spacing={3}>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TypographyBold variant="body2" display="block" color="textSecondary">
                              TIPO DE OPERACION
                            </TypographyBold>
                            <Box mt={1} >
                              <Grid container direction="row" spacing={1} className={classes.padding1}>
                                {/** cod articulo */}
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                                  <FormControl component="fieldset">
                                    <RadioGroup
                                      row
                                      aria-label="position"
                                      name="position"
                                      defaultValue="top"
                                      value={operacion}
                                      onChange={handleChangeOperacion}
                                    >
                                      <FormControlLabel
                                        value="alta"
                                        control={<Radio color="primary" />}
                                        label="Alta de producto"
                                        labelPlacement="end"
                                      />

                                      <FormControlLabel
                                        value="baja"
                                        control={<Radio color="primary" />}
                                        label="Baja de producto"
                                        labelPlacement="end"
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <TextField
                                    id="standard-read-only-input"
                                    label="MOTIVO DE LA OPERACION"
                                    name="cantidad"
                                    variant="outlined"
                                    value={motivo}
                                    onChange={(value) => handleMotivoOperacion(value)}

                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TypographyBold variant="body2" display="block" color="textSecondary">
                              BUSCAR PRODUCTO
                            </TypographyBold>
                            <Box mt={1} >
                              <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                  <Paper className={classes.rootPaper}>
                                    <InputBase
                                      className={classes.input}
                                      id="codArticulo"
                                      name="codArticulo"
                                      placeholder="COD. ARTICULO"
                                      fullWidth
                                      autoFocus
                                      onChange={(e) => handleChangeArticulo(e)}
                                      value={
                                        articulo.articulo?.codigo &&
                                        articulo.articulo?.codigo
                                      }
                                      onBlur={() => loadArticulo()}
                                      inputProps={{ "aria-label": "Chofer" }}
                                    />
                                    <Divider className={classes.divider} orientation="vertical" />
                                    {isLoadingArticulo ? (
                                      <CircularProgress color="primary" size={20} />
                                    ) : (
                                      <IconButton
                                        style={{
                                          color: "#396b99",
                                          textAlign: "center",
                                          minWidth: 45,
                                          padding: "1px 8px 1px 8px",
                                        }}
                                        className={classes.iconButton}
                                        aria-label="search"
                                        onClick={() => handleOpenArticulo()}
                                      >
                                        <Search />
                                      </IconButton>
                                    )}
                                  </Paper>
                                </Grid>

                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                  <TextField
                                    id="standard-read-only-input"
                                    label="DESCRIPCION ARTICULO"
                                    name="articulo"
                                    variant="outlined"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    value={
                                      articulo.articulo?.descripcion &&
                                      articulo.articulo?.descripcion
                                    }
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                  <Autocomplete
                                    id="deposito"
                                    value={articulo.deposito || ""}
                                    onChange={onSelectDeposito}
                                    options={deposito.content}
                                    size="small"
                                    getOptionLabel={(option) =>
                                      option.codigo
                                        ? option.codigo + " - " + option.descripcion
                                        : ""
                                    }
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                        label="DEPOSITOS"
                                        name="deposito"
                                        //inputRef={register({ required: false })}
                                        InputProps={{
                                          ...params.InputProps,

                                        }}
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
                                  <TextField
                                    id="standard-read-only-input"
                                    label="STOCK"
                                    name="stock"
                                    variant="outlined"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    value={
                                      articulo.stock &&
                                      articulo.stock
                                    }
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>

                                <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
                                  <TextField
                                    id="standard-read-only-input"
                                    label="CANTIDAD"
                                    name="cantidad"
                                    variant="outlined"
                                    type="number"
                                    value={articulo.cantidad}
                                    onChange={(value) => handleCantidadArticulo(value)}

                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
                                  <Button
                                    size="small"
                                    color="secondary"
                                    fullWidth
                                    variant="contained"
                                    startIcon={<AddIcon />}
                                    onClick={() => handleIncluir()}
                                  //className={classes.input}
                                  >
                                    AGREGAR
                                  </Button>
                                </Grid>

                              </Grid>

                            </Box>
                          </Grid>


                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Paper>
                <MaterialTable
                  title=""
                  columns={tableColumns}
                  data={detalle.content}
                  localization={localization}
                  icons={tableIcons}
                  options={{
                    filtering: false,
                    search: true,
                    toolbar: false,
                    headerStyle: { position: "sticky", top: 0 },
                    maxBodyHeight: "55vh",
                    paging: false,
                    draggable: false,
                    rowStyle: {
                      fontSize: "small",
                    },
                  }}
                  editable={{
                    onRowDelete: (oldData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          const dataDelete = [...detalle.content];
                          const index = oldData.tableData.id;
                          dataDelete.splice(index, 1);
                          setDetalle({ ...detalle, content: dataDelete });

                          resolve();
                        }, 600);
                      }),
                  }}
                />
              </CardContent>
              <CardActions >
                <Box p={1} className={classes.botonDerecha}>
                  <Button
                    size="small"
                    color="secondary"

                    variant="contained"
                    startIcon={<SaveIcon />}
                    onClick={() => handleGuardar()}

                  >
                    GUARDAR
                  </Button>
                </Box>

              </CardActions>
            </Card>
          </Grid>

          <BuscadorArticuloConDepositoDialog
            open={openArticulo}
            onClose={handleCloseArticulo}
            onSetData={handleArticulo}
          />
        </>
        : <AccesoDenegado />}
    </>
  );
};

export default AjustarStock;
