import React, { useContext, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Destinatario from "./Destinatario";
import Detalle from "./Detalle";
import DetalleIps from "./DetalleIps";
import DetalleContrato from "./DetalleContrato";


import {
  notificacionError,
  notificacionAlerta,
} from "../../Notificaciones";
import { BotonGris } from "../../../assets/styles/StyledButtons";
import FacturacionContext from "../../../utils/facturacionContext/FacturacionContext";
import { useStyles } from "../../../assets/styles/CustomStyles";
import Alert from "@material-ui/lab/Alert";

import ExitToApp from "@material-ui/icons/ExitToApp";
import axios from "../../../utils/axios";
import pdfConverter from "../../../utils/pdfConverter";
import { useHistory } from "react-router-dom";
import BackdropCustom from "../../BackdropCustom";

const defaultValue = {
  textValue: {
    textRuc: false,
    textnNroFactura: false,
    //  textNroRemisionImpresion: false,
  },
};
export default function StepperFacturacion() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const facturacionContext = useContext(FacturacionContext);
  const [textValue, setTextValue] = useState(defaultValue);
  const [claveFactura, setClaveFactura] = useState({ factura: "", id: "", control_minimo: "" });
  const steps = getSteps();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  function getSteps() {
    return [
      "Cabecera",
      "Detalle",
      "Impresión",
    ];
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return DestinatarioStep(textValue);
      case 1:
        return DetalleStep();
      case 2:
        return Impresion();
      default:
        return "";
    }
  }

  const DestinatarioStep = (props) => {
    return (
      <>
        <Destinatario select={props} />
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          className={classes.button}
          size="small"
        >
          Anterior
        </Button>
        <Button variant="contained" color="primary" size="small" onClick={handleNext}>
          Siguiente
        </Button>
      </>
    );
  };

  const DetalleStep = () => {
    return (
      <>
        {facturacionContext.cabecera.nro_remision !== "" ? <Detalle /> :
          facturacionContext.cabecera.nroContrato !== "" ? <DetalleContrato /> : <DetalleIps />
        }

        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          className={classes.button}
          size="small"
        >
          Anterior
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleGenerarFactura}
          size="small"
        >
          Procesar Factura
        </Button>
      </>
    );
  };

  const Impresion = () => {
    return (
      <div>
        <Typography variant="h6" component="h6">
          <Alert severity="success"> {claveFactura?.control_minimo === "" ?
            "Se confirmó exitosamente la facturación Nro. " + claveFactura?.factura :
            "Se confirmó exitosamente la facturación Nro. " + claveFactura?.factura + " OBS:" + claveFactura?.control_minimo} </Alert>

        </Typography>

        <Button
          onClick={() => handleGenerarImpresion()}
          variant="contained"
          color="primary"
          size="small"
          disabled={claveFactura?.control_minimo === "No hay disponiblidad de facturas" ? true : false}
        >
          IMPRIMIR FACTURA
        </Button>
        <BotonGris
          startIcon={<ExitToApp />}
          onClick={(e) => {
            history.goBack();
          }}
          children="SALIR"
        />
      </div>
    );
  };

  const handleNext = () => {
    /*
        if (
          facturacionContext.cabecera.ruc !== "" && facturacionContext.cabecera.nro_remision_impresion !== ""
        ) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        */


    if (facturacionContext.cabecera.ruc !== "") {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    //validamos cada input requerido 
    let copy = { ...textValue };
    if (facturacionContext.cabecera.ruc === "") {
      copy.textRuc = true;
    } else {
      copy.textRuc = false;
    }

    if (facturacionContext.cabecera.nro_remision === "") {
      copy.textnNroFactura = true;

      if (facturacionContext.cabecera.nro_remision_impresion === "") {
        copy.textNroRemisionImpresion = true;
      } else {
        copy.textNroRemisionImpresion = false;
        copy.textnNroFactura = false;
      }

    } else {
      copy.textnNroFactura = false;
      copy.textNroRemisionImpresion = false;
    }

    setTextValue(copy);

  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    facturacionContext.clearFactura();
    setActiveStep(0);
  };

  const handleGenerarFactura = async () => {

    try {
      setIsLoading(true);
      console.log(facturacionContext.cabecera)
      console.log(facturacionContext.cabecera.idRemision ? facturacionContext.cabecera.idRemision : "")
      let dataObject = {
        cliente: facturacionContext.cabecera.cliente,
        fecha: facturacionContext.cabecera.fecha,
        nro_remision: facturacionContext.cabecera.idRemision ? facturacionContext.cabecera.idRemision : "",
        nro_remision_impresion: facturacionContext.cabecera.nro_remision_impresion ? facturacionContext.cabecera.nro_remision_impresion : "",
        tipo_de_pago: facturacionContext.cabecera.tipo_de_pago,
        total: facturacionContext.cabecera.total,
        iva_5: facturacionContext.cabecera.iva_5,
        iva_10: facturacionContext.cabecera.iva_10,
        items: facturacionContext.detalle.dataDetalle,
      };

      const response = await axios.post("facturacion/funAgregar.php", dataObject);

      let data = response.data;
      let status = response.status
      if (status === 200) {
        setClaveFactura(data);
        handleNext();
      }


    } catch (error) {
      if (error.response) {
        notificacionAlerta(error.response?.mensaje);
      }
    } finally {
      setIsLoading(false);
    }


  };

  const handleGenerarImpresion = async () => {
    handleNext();
    let tokenCookies = sessionStorage.getItem("jwt-jr");
    let url = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}extensiones/tcpdf/pdf/vFactura.php?nroFactura=${claveFactura?.id}&p=${JSON.parse(tokenCookies).token}`
    var win = window.open(url, '_blank');
    win.focus();
  }

  return (
    <>
      <BackdropCustom open={isLoading} />

      <div>
        <Stepper activeStep={activeStep} alternativeLabel >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel color="secondary">{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography variant="h6" component="h6">
                <Alert severity="success">Impresión exitosa.</Alert>
              </Typography>
              <Button onClick={handleReset} size="small">Cargar nueva facturación</Button>

              <BotonGris
                startIcon={<ExitToApp />}
                onClick={(e) => {
                  history.goBack();
                }}
                children="SALIR"
              />
            </div>
          ) : (
            <div>
              <Typography variant="h6" component="h6">
                {getStepContent(activeStep)}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
