import getCookie from "../cookie";
import jwtDecode from "jwt-decode";

const TOKEN_KEY = "jwt-jr";

export const getUserPrincipal = () => {
  const jwtCookie = JSON.parse(sessionStorage.getItem("jwt-jr"));
  if (jwtCookie !== null) {
    const decodedAccessToken = jwtDecode(jwtCookie?.token);
    return {
      nombreUsu: decodedAccessToken.usuario,
      apellidoUsu: jwtCookie.apellidoUsu,
      correo: jwtCookie.correo,
      accesos: jwtCookie.accesos,
    };
  /*  
    const decodedRefreshToken = jwtDecode(JSON.parse(jwtCookie).refresh_token);
    const now = new Date().getTime() / 1000;
    if (decodedAccessToken.exp - now > 0 || decodedRefreshToken.exp - now > 0) {
      return {
        name: decodedAccessToken.usuario,
        preferred_username: decodedAccessToken.usuario,
        given_name: decodedAccessToken.rol,
        family_name: decodedAccessToken.rol,
      };
    }
    */
  }


};
export const logout = () => {
  localStorage.clear();
  sessionStorage.clear();
  console.log(TOKEN_KEY)
  var d = new Date();
  d.setTime(d.getTime());
  var expires = "expires=" + d.toUTCString();
  console.log(expires) 
  document.cookie = 'jwt-jr= ; Path=/; expires=Tue, 06 Jul 2020 20:19:55 GMT;;domain=jr.com.py;';
  window.location.href = `/acceder`;
 // window.location.href = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_MENU}/acceder/`;
};

export default { getUserPrincipal, logout };
