import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "../../assets/styles/CustomStyles";
import axios from "../../utils/axios";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import {
  notificacionError,
  alertWarningError,
} from "../../components/Notificaciones";
import TypographyBold from "../../components/TypographyBold";
import BackdropCustom from "../../components/BackdropCustom";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import Close from "@material-ui/icons/Close";
import Print from "@material-ui/icons/Print";
import CardHeader from '@material-ui/core/CardHeader';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DateIcon from '@material-ui/icons/DateRange';
import IconButton from '@material-ui/core/IconButton';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CardActions from '@material-ui/core/CardActions';
import SaveIcon from '@material-ui/icons/Save';
import Button from "@material-ui/core/Button";


const DetalleFacturacion = () => {
  const history = useHistory();
  const dataProps = history.location.state;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState("")


  const handleGuardar = async () => {
    console.log(value)
    setIsLoading(true);
    let url = `facturacion/funPagoCredito.php?id=${dataProps.data?.id}&nroRecibo=${value}`;
    try {
      const response = await axios.get(url);

      const status = response.status
      if (status === 200) {
        history.goBack();
      }



      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    }
  }

  const handleValue = (value) => {
    setValue(value.target.value)
  }

  return (
    <>
      <BackdropCustom open={isLoading} />

      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{ marginBottom: 10 }}

      >
        <Card className={classes.root}>
          <CardHeader
            action={<>
              <Chip label={dataProps.data?.fecha} variant="default" icon={<DateIcon />} />

              <IconButton aria-label="exit" onClick={(e) => {
                history.goBack();
              }}>
                <Close />
              </IconButton>

            </>

            }
            title={<Typography variant="body1" >
              INFORMACION DEL CREDITO <Chip label={dataProps.data?.nro_factura} variant="default" size="small" icon={<AssignmentIcon />} />
            </Typography>}
          />
          <CardContent >
            <Paper >
              <Box p={1} >
                <Box ml={1} >
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                    <Grid container direction="row" spacing={1}>
                      <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                        <TypographyBold variant="body2" display="block" color="textSecondary">
                          DESTINATARIO DEL CLIENTE
                        </TypographyBold>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6} xl={5}>
                        <Grid container>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                            <List dense>
                              <ListItem>
                                <ListItemIcon className={classes.buttonFiltro}>
                                  <TypographyBold variant="subtitle2" color="inherit" >
                                    Denominación:
                                  </TypographyBold>
                                </ListItemIcon>
                                <ListItemText
                                  primary={dataProps.data?.nombre}
                                />
                              </ListItem>
                              <ListItem>
                                <ListItemIcon className={classes.buttonFiltro}>
                                  <TypographyBold variant="subtitle2" color="inherit" >
                                    RUC:
                                  </TypographyBold>
                                </ListItemIcon>
                                <ListItemText
                                  primary={dataProps.data?.ruc}
                                />
                              </ListItem>
                              <ListItem>
                                <ListItemIcon className={classes.buttonFiltro}>
                                  <TypographyBold variant="subtitle2" color="inherit" >
                                    Domicilio:
                                  </TypographyBold>
                                </ListItemIcon>
                                <ListItemText
                                  primary={dataProps.data?.direccion}
                                />
                              </ListItem>

                            </List>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6} xl={5}>
                        <Grid container >
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <List dense>
                              <ListItem>
                                <ListItemIcon className={classes.buttonFiltro}>
                                  <TypographyBold variant="subtitle2" color="inherit" >
                                    Nota de Remisión N°:
                                  </TypographyBold>
                                </ListItemIcon>
                                <ListItemText
                                  primary={dataProps.data?.nro_remision ? dataProps.data?.nro_remision : dataProps.data?.nro_remision_impresion}
                                />
                              </ListItem>

                            </List>
                          </Grid>
                        </Grid>
                      </Grid>


                      <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                        <TypographyBold variant="body2" display="block" color="textSecondary">
                          DETALLE
                        </TypographyBold>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6} xl={5}>
                        <Grid container>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                            <List dense>
                              <ListItem>
                                <ListItemIcon className={classes.buttonFiltro}>
                                  <TypographyBold variant="subtitle2" color="inherit" >
                                    TOTAL :
                                  </TypographyBold>
                                </ListItemIcon>
                                <ListItemText
                                  primary={dataProps.data?.total}
                                />
                              </ListItem>

                              <ListItem>
                                <ListItemIcon className={classes.buttonFiltro}>
                                  <TypographyBold variant="subtitle2" color="inherit" >
                                    TOTAL IVA:
                                  </TypographyBold>
                                </ListItemIcon>
                                <ListItemText
                                  primary={dataProps.data?.iva}
                                />
                              </ListItem>

                            </List>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6} xl={5}>
                        <Grid container >
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <List dense>
                              <ListItem>
                                <ListItemIcon className={classes.buttonFiltro}>
                                  <TypographyBold variant="subtitle2" color="inherit" >
                                    Comprobante:
                                  </TypographyBold>
                                </ListItemIcon>
                                <TextField
                                  variant="outlined"
                                  id="cantidad"
                                  name="cantidad"
                                  label="N° Comprobante"
                                  type="number"
                                  size="small"
                                  value={value}
                                  onChange={(value) => handleValue(value)}
                                  fullWidth
                                />
                              </ListItem>

                            </List>
                          </Grid>
                        </Grid>
                      </Grid>

                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Paper>

          </CardContent>
          <CardActions >
            <Box p={1} className={classes.botonDerecha}>
              <Button
                size="small"
                color="secondary"
                disabled={value ? false : true}
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={() => handleGuardar()}

              >
                GUARDAR
              </Button>
            </Box>

          </CardActions>
        </Card>
      </Grid>

    </>
  );
};

export default DetalleFacturacion;
