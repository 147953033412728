import React, { useEffect, useState, useContext } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { useStyles } from "../../../assets/styles/CustomStyles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import InputBase from "@material-ui/core/InputBase"

import axios from "../../../utils/axios";
import {
  alertaAceptarCancelar,
  alertWarningError,
  notificacionWarning,
} from "../../Notificaciones";
import FacturacionContext from "../../../utils/facturacionContext/FacturacionContext";
import CircularProgress from "@material-ui/core/CircularProgress";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import IconButton from '@material-ui/core/IconButton';
import Search from "@material-ui/icons/Search";
import BuscadorClienteDialog from "../../BuscadorClienteDialog";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const initialValue = {
  idRemision:"",
  cliente: "",
  ruc: "",
  direccion: "",
  nombre: "",
  nro_factura: "",
  idContrato: "",
  nroContrato: "",
  timbrado: "",
  nro_remision: "",
  nro_remision_impresion: "",
  fecha: new Date(),
  iva: "",
  total: "",
  tipo_de_pago: "CREDITO",
  estado: ""
};

const initialValueSelect = {

  id: "",
  idCliente: "",
  direccion: "",
  nro_factura: "",
  nombre: "",
  ruc: "",
}
const initialValueSelectContrato = {

  id: "",
  idCliente: "",
  direccion: "",
  nroContrato: "",
  nombre: "",
  ruc: "",
}

export default function Destinatario(props) {
  const { textRuc } = props?.select;

  const classes = useStyles();
  const facturacionContext = useContext(FacturacionContext);
  const [remision, setRemision] = useState({
    content: [],
  });
  const [contratos, setContratos] = useState({
    content: [],
  });
  const [inputValues, setInputValues] = useState(initialValue);
  const [inputValuesSelect, setInputValuesSelect] = useState(initialValueSelect);
  const [selectContrato, setSelectContrato] = useState(initialValueSelectContrato);
  const [isLoadingCliente, setIsLoadiongCliente] = useState(false);
  const [isLoadingRemision, setIsLoadingRemision] = useState(false);
  const [isLoadingContrato, setIsLoadingContrato] = useState(false);
  const [openCliente, setOpenCliente] = useState(false);

  useEffect(() => {
    setInputValues(facturacionContext.cabecera);

    let selectContrato = {

      id: facturacionContext.cabecera.idContrato,
      idCliente: facturacionContext.cabecera.cliente,
      direccion: facturacionContext.cabecera.direccion,
      nroContrato: facturacionContext.cabecera.nroContrato,
      nombre: facturacionContext.cabecera.nombre,
      ruc: facturacionContext.cabecera.ruc,
    }
    setSelectContrato(selectContrato)


    let select = {

      id: facturacionContext.cabecera.idRemision,
      idCliente: facturacionContext.cabecera.cliente,
      direccion: facturacionContext.cabecera.direccion,
      nro_factura: facturacionContext.cabecera.nro_remision,
      nombre: facturacionContext.cabecera.nombre,
      ruc: facturacionContext.cabecera.ruc,
    }
    setInputValuesSelect(select)
    getRemisiones()
    getContratos()
    console.log(inputValues)
    // se utiliza para actualizar input values cuando el contexto ha sido actualizado
  }, []);


  const getRemisiones = async (props) => {
    setIsLoadingRemision(true);
    let url = props ? props : "facturacion/funListaRemisiones.php";
    try {
      const response = await axios.get(url);

      const remisiones = response.data;
      setRemision({ ...remision, content: remisiones });

      setIsLoadingRemision(false);
    } catch (error) {
      setIsLoadingRemision(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    }
  };

  const getContratos = async (props) => {
    setIsLoadingContrato(true);
    let url = props ? props : "contrato/funListadoContratosParaFacturar.php";
    try {
      const response = await axios.get(url);

      const contratosList = response.data;
      console.log(contratosList)
      setContratos({ ...contratos, content: contratosList });

      setIsLoadingContrato(false);
    } catch (error) {
      setIsLoadingContrato(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    }
  };



  const onSelectRemision = (e, value) => {
    console.log(value)
    if (value?.nro_factura !== inputValues.nro_factura) {
      let copyInput = {
        ...inputValues,
        idRemision: value?.id,
        cliente: value?.idCliente,
        ruc: value?.ruc,
        direccion: value?.direccion,
        nombre: value?.nombre,
        nro_remision: value?.nro_factura,
        iva_10: value?.iva,
        total: value?.total,
      };

      let copyInputSelect = {
        ...inputValuesSelect,
        id: value?.id,
        idCliente: value?.idCliente,
        ruc: value?.ruc,
        direccion: value?.direccion,
        nombre: value?.nombre,
        nro_factura: value?.nro_factura,
      };

      setInputValues(copyInput);
      setInputValuesSelect(copyInputSelect);
      facturacionContext.updateFacturaCabecera(copyInput)
    }
    if (value === null) {
      let copyInput = {
        ...inputValues,
        idRemision:"",
        cliente: "",
        direccion: "",
        nro_remision: "",
        nombre: "",
        ruc: "",
        iva_10: "",
        total: "",

      };

      let copyInputSelect = {
        ...inputValuesSelect,
        id: "",
        idCliente: "",
        ruc: "",
        direccion: "",
        nombre: "",
        nro_factura: "",
      };

      setInputValues(copyInput);
      setInputValuesSelect(copyInputSelect);
      facturacionContext.clearFactura()
    }
  };


  const onSelectContrato = (e, value) => {
    console.log(value)
    if (value?.nroContrato !== inputValues.nroContrato) {

      if (facturacionContext.detalle.dataDetalle.length > 0) {

        alertaAceptarCancelar({
          text:
            `¿Al cambiar de contrato perderas todo el detalle cargado, desaeas continuar?`,
        }).then(async (result) => {
          if (result.isConfirmed) {
            facturacionContext.updateFacturaDetalle([]);

            let cabeceraUpdate = {
              ...inputValues,
              cliente: value?.idCliente,
              ruc: value?.rucCliente,
              direccion: value?.direccionCliente,
              nombre: value?.nombreCliente,
              idContrato: value?.id,
              nroContrato: value?.nroContrato,
              iva_5: "",
              iva_10: "",
              total: "",
            };

            let copyInputSelect = {
              ...selectContrato,
              id: value?.id,
              idCliente: value?.idCliente,
              ruc: value?.rucCliente,
              direccion: value?.direccionCliente,
              nombre: value?.nombreCliente,
              nroContrato: value?.nroContrato,
            };

            setInputValues(cabeceraUpdate);
            setSelectContrato(copyInputSelect);
            facturacionContext.updateFacturaCabecera(cabeceraUpdate)

            if (value === null) {
              console.log(value)
              console.log(inputValues)
              let copyInput = {
                ...inputValues,
                cliente: "",
                ruc: "",
                direccion: "",
                nombre: "",
                nro_factura: "",
                idContrato: "",
                nroContrato: "",
                timbrado: "",
                nro_remision: "",
                nro_remision_impresion: "",
                iva: "",
                total: ""
        
              };
        
              let copyInputSelect = {
                ...selectContrato,
                id: "",
                idCliente: "",
                ruc: "",
                direccion: "",
                nombre: "",
                nroContrato: "",
              };
        
              setInputValues(copyInput);
              setSelectContrato(copyInputSelect);
              facturacionContext.clearFactura()
              facturacionContext.updateFacturaDetalle([]);
        
            }
          }
        });
      } else {
        let copyInput = {
          ...inputValues,
          cliente: value?.idCliente,
          ruc: value?.rucCliente,
          direccion: value?.direccionCliente,
          nombre: value?.nombreCliente,
          idContrato: value?.id,
          nroContrato: value?.nroContrato,

        };

        let copyInputSelect = {
          ...selectContrato,
          id: value?.id,
          idCliente: value?.idCliente,
          ruc: value?.rucCliente,
          direccion: value?.direccionCliente,
          nombre: value?.nombreCliente,
          nroContrato: value?.nroContrato,
        };

        setInputValues(copyInput);
        setSelectContrato(copyInputSelect);
        facturacionContext.updateFacturaCabecera(copyInput)
      }


    }
    if (value === null) {
      console.log(value)
      console.log(inputValues)
      let copyInput = {
        ...inputValues,
        cliente: "",
        ruc: "",
        direccion: "",
        nombre: "",
        nro_factura: "",
        idContrato: "",
        nroContrato: "",
        timbrado: "",
        nro_remision: "",
        nro_remision_impresion: "",
        iva: "",
        total: ""

      };

      let copyInputSelect = {
        ...selectContrato,
        id: "",
        idCliente: "",
        ruc: "",
        direccion: "",
        nombre: "",
        nroContrato: "",
      };

      setInputValues(copyInput);
      setSelectContrato(copyInputSelect);
      facturacionContext.clearFactura()
      facturacionContext.updateFacturaDetalle([]);

    }
  };

  const handleChangeCliente = (value) => {
    let copyInput = {
      ...inputValues,
      ruc: value.target.value,

    };
    setInputValues(copyInput);
    facturacionContext.updateFacturaCabecera(copyInput)
  };

  const handleChangeNroRemisionImpresion = (value) => {
    if (value.target.value !== inputValues.nro_remision_impresion) {
      let copyInput = {
        ...inputValues,
        nro_remision_impresion: value.target.value,

      };
      setInputValues(copyInput);
      facturacionContext.updateFacturaCabecera(copyInput)
    }
    console.log(value.target.value)
    if (value.target.value === "") {
      facturacionContext.updateFacturaDetalle([])
    }

  };

  const loadCliente = async () => {
    let ruc = inputValues?.ruc;
    if (ruc) {
      try {
        setIsLoadiongCliente(true);

        const response = await axios.get(`cliente/funConsultaIndividual.php?ruc=${ruc}`);

        let data = response.data;
        let status = response.status
        if (status === 200) {

          if (data.length < 0) {
            notificacionWarning("No se encontró el cliente con código " + ruc);
            return;
          }

          if (data.length > 1) {
            notificacionWarning("Se encontró más de un resultado para el cliente con código " + ruc);
            return;
          }

          // document.getElementById("nroContrato").focus();
          handleCliente(data[0]);
        }


      } catch (error) {
        if (error.response) {
          alertWarningError(error.response);
        }
      } finally {
        setIsLoadiongCliente(false);
      }
    } else {
      let copyInput = {
        ...inputValues,
        cliente: "",
        ruc: "",
        nombre: "",
        direccion: "",
      };
      setInputValues(copyInput);
      facturacionContext.updateFacturaCabecera(copyInput)
    }
  };

  const handleCliente = (value) => {

    let copyInput = {
      ...inputValues,
      cliente: value?.id,
      ruc: value?.ruc,
      nombre: value?.nombre,
      direccion: value?.direccion,
    };
    setInputValues(copyInput);
    facturacionContext.updateFacturaCabecera(copyInput)
  };

  const handleChangeTipoPago = (event) => {
    let copyInput = {
      ...inputValues,
      tipo_de_pago: event.target.value
    }
    setInputValues(copyInput);
    facturacionContext.updateFacturaCabecera(copyInput)
  };


  const handleCloseCliente = (value) => {
    setOpenCliente(false);
  };
  const handleOpenCliente = () => {
    setOpenCliente(true);
  };

  const handleChangeFechaInicio = (value) => {
    let copyInput = {
      ...inputValues,
      fecha: value
    };
    setInputValues(copyInput);
    console.log(value)
    facturacionContext.updateFacturaCabecera(copyInput)

  };

  return (
    <>
      <Paper className={classes.padding2}>
        <Grid container direction="row" alignItems="flex-start" justify="flex-start" spacing={2}>

          <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
            <Grid container spacing={2} justify="center" alignItems="center">

              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Paper className={classes.rootPaper}>
                  <InputBase
                    className={classes.input}
                    id="ruc"
                    name="ruc"
                    placeholder="RUC/CI"
                    fullWidth
                    // autoFocus
                    disabled={inputValues?.nro_remision ? true : false}

                    onChange={(e) => handleChangeCliente(e)}
                    value={
                      inputValues?.ruc && inputValues?.ruc
                    }
                    onBlur={() => loadCliente()}
                    inputProps={{
                      "aria-label": "ruc",
                    }}
                  />
                  {isLoadingCliente ? (
                    <CircularProgress color="primary" size={20} />
                  ) : (
                    <IconButton
                      style={{
                        color: "#396b99",
                        textAlign: "center",
                        minWidth: 45,
                        padding: "1px 8px 1px 8px",
                      }}
                      className={classes.iconButton}
                      aria-label="search"
                      onClick={() => handleOpenCliente()}
                    >
                      <Search />
                    </IconButton>
                  )}

                </Paper>

              </Grid>

              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <TextField
                  id="DENOMINACION"
                  label="DENOMINACION"
                  name="DENOMINACION"
                  variant="outlined"
                  color="secondary"
                  error={textRuc}
                  helperText={textRuc ? "Campo obligatorio" : null}
                  InputProps={{
                    readOnly: inputValues?.nro_remision ? true : false,
                  }}
                  value={
                    inputValues?.nombre &&
                    inputValues?.nombre
                  }
                  fullWidth
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  id="DOMICILIO"
                  label="DOMICILIO"
                  name="DIRECCION"
                  variant="outlined"
                  color="secondary"
                  error={textRuc}
                  helperText={textRuc ? "Campo obligatorio" : null}
                  InputProps={{
                    readOnly: inputValues?.nro_remision ? true : false,
                  }}

                  value={
                    inputValues?.direccion && inputValues?.direccion
                  }
                  fullWidth
                  size="small"
                />

              </Grid>

            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <Grid container spacing={2} justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    id="FECHA"
                    label="FECHA"
                    value={inputValues?.fecha}
                    fullWidth
                    size="small"
                    helperText={
                      inputValues?.fecha ? "Fecha para la impresión" : null
                    }
                    onChange={(e) => handleChangeFechaInicio(e)}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>

              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    defaultValue="top"
                    value={inputValues?.tipo_de_pago}
                    onChange={handleChangeTipoPago}
                  >
                    <FormControlLabel
                      value="CONTADO"
                      control={<Radio color="primary" />}
                      label="Contado"
                      labelPlacement="end"
                    />

                    <FormControlLabel
                      value="CREDITO"
                      control={<Radio color="primary" />}
                      label="Crédito"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Autocomplete
                  fullWidth
                  value={inputValuesSelect || ""}
                  onChange={onSelectRemision}
                  size="small"
                  id="REMISION"
                  disabled={inputValues?.nro_remision_impresion  || inputValues?.nroContrato  ? true : false}
                  options={remision.content}
                  getOptionLabel={(option) =>
                    option.nro_factura ? option.nro_factura : ""
                  }
                  loading={isLoadingRemision}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        required
                        label="NRO. REMISION"
                        variant="outlined"
                        color="secondary"
                        // error={textnNroFactura}
                        //  helperText={textnNroFactura ? "Campo obligatorio" : null}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isLoadingRemision ? (
                                <CircularProgress color="secondary" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  id="nro_remision_impresion"
                  label="NRO REMISION IMPRESION"
                  name="nro_remision_impresion"
                  variant="outlined"
                  color="secondary"
                  //  error={textNroRemisionImpresion}
                  //helperText={textNroRemisionImpresion ? "Campo obligatorio" : null}
                  disabled={inputValues?.nro_remision || inputValues?.nroContrato ? true : false}
                  onChange={(e) => handleChangeNroRemisionImpresion(e)}
                  value={
                    inputValues?.nro_remision_impresion && inputValues?.nro_remision_impresion
                  }
                  fullWidth
                  size="small"
                />

              </Grid>

              <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                <Autocomplete
                  fullWidth
                  value={selectContrato || ""}
                  onChange={onSelectContrato}
                  size="small"
                  id="CONTRATO"
                  disabled={inputValues?.nro_remision_impresion || inputValues?.nro_remision  ? true : false}
                  options={contratos.content}
                  getOptionLabel={(option) =>
                    option.nroContrato ? option.nroContrato : ""
                  }
                  loading={isLoadingContrato}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        required
                        label="NRO. CONTRATO"
                        variant="outlined"
                        color="secondary"
                        // error={textnNroFactura}
                        //  helperText={textnNroFactura ? "Campo obligatorio" : null}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isLoadingContrato ? (
                                <CircularProgress color="secondary" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    );
                  }}
                />
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <BuscadorClienteDialog
        open={openCliente}
        onClose={handleCloseCliente}
        onSetData={handleCliente}
      />
    </>
  );
}
