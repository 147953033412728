import React, { useContext, memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useHistory } from "react-router-dom";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import logo from "../assets/images/logoNav.png";
import Exit from "@material-ui/icons/ExitToApp";
import Person from "@material-ui/icons/Person";
import HomeWork from '@material-ui/icons/HomeWork';
import Grid from "@material-ui/core/Grid";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import Brightness7Icon from "@material-ui/icons/Brightness7";
import UserContext from "../utils/user/UserContext";
import Link from "@material-ui/core/Link";
import Button from '@material-ui/core/Button';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& .makeStyles-iconButton-12": {
      padding: 5,
    },
  },

  papper: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: "#fff",
  },
  appBar: {
    height: "42px",
  },
  Toolbar: {
    minHeight: "42px",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 5,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

function NavBar({ theme, toggleTheme }) {
  const classes = useStyles();
  const history = useHistory();
  const userContext = useContext(UserContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [auth] = React.useState(true);
  const open = Boolean(anchorEl);
  const [clicked, setClicked] = React.useState();

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDarkModeBotton = () => {
    if (clicked) {
      setClicked(false);
      toggleTheme();
    } else {
      setClicked(true);
      toggleTheme();
    }
  };

  return (
    <>
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar className={classes.Toolbar}>
          <Grid container spacing={2} className={classes.title}>
            <Grid item xs={4} sm={2} md={3} lg={3} xl={3}>
             
              <Button color="inherit" onClick={() => history.push("/")} >JR Ingeniería</Button>
            </Grid>
          </Grid>
          <IconButton onClick={() => handleDarkModeBotton()} color="inherit">
            {clicked ? <Brightness4Icon /> : <Brightness7Icon />}
          </IconButton>
          {auth && (
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircleIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{vertical: "top",horizontal: "right"}}
                keepMounted
                transformOrigin={{vertical: "top",horizontal: "right"}}
                open={open}
                onClose={handleClose}
              >

                <MenuItem>
                  <ListItemIcon onClick={handleClose}>
                    <Person fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary={userContext.state.nombreUsu} />
                </MenuItem>
                <Divider />

                <MenuItem
                  
                  onClick={(e) => {
                    userContext.clearUser();
                    history.push("/acceder")

                  }}
                >
                  <ListItemIcon onClick={handleClose}>
                    <Exit color="primary"  fontSize="small" />
                  </ListItemIcon>
                  <ListItemText  primary="Cerrar sesión"/>
                 
                </MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar style={{ minHeight: 45 }} />
    </>
  );
}

export default memo(NavBar);
