import React, { useEffect, useState, useContext } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { useStyles } from "../../../assets/styles/CustomStyles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { Typography } from "@material-ui/core";
import axios from "../../../utils/axios";
import {
  notificacionError,
  alertWarningError,
  notificacionWarning,
} from "../../Notificaciones";
import RemisionContext from "../../../utils/remisionContext/RemisionContext";
import CircularProgress from "@material-ui/core/CircularProgress";

const initialValue = {
  marca_del_vehiculo: "", nro_de_rua: "", nro_rua_remolque: "", nombre_transporte: "", ruc_transporte: "", domicilio_transporte: ""
};

export default function Transporte(props) {
  const { textMarcaVehiculo, textRua, textNombreTrasporte, textRucTrasporte } = props?.select;

  const classes = useStyles();
  const remisionContext = useContext(RemisionContext);

  const [inputValues, setInputValues] = useState(initialValue);


  useEffect(() => {
    setInputValues(remisionContext.cabeceraTrasporte);

    // se utiliza para actualizar input values cuando el contexto ha sido actualizado
  }, []);


  const handleMarcaVehiculo = (value) => {
    let copyInput = {
      ...inputValues,
      marca_del_vehiculo: value.target.value,
    };

    setInputValues(copyInput);
    remisionContext.updateRemisionCabeceraTrasporte(copyInput);
  };

  const handleRuaNro = (value) => {
    let copyInput = {
      ...inputValues,
      nro_de_rua: value.target.value,
    };

    setInputValues(copyInput);
    remisionContext.updateRemisionCabeceraTrasporte(copyInput);
  };

  const handleRuaRemolque = (value) => {
    let copyInput = {
      ...inputValues,
      nro_rua_remolque: value.target.value,
    };

    setInputValues(copyInput);
    remisionContext.updateRemisionCabeceraTrasporte(copyInput);
  };

  const handleNombreTrnsporte = (value) => {
    let copyInput = {
      ...inputValues,
      nombre_transporte: value.target.value,
    };

    setInputValues(copyInput);
    remisionContext.updateRemisionCabeceraTrasporte(copyInput);
  };

  const handleRucTransporte = (value) => {
    let copyInput = {
      ...inputValues,
      ruc_transporte: value.target.value,
    };

    setInputValues(copyInput);
    remisionContext.updateRemisionCabeceraTrasporte(copyInput);
  };

  const handleDomicilioTransporte = (value) => {
    let copyInput = {
      ...inputValues,
      domicilio_transporte: value.target.value,
    };

    setInputValues(copyInput);
    remisionContext.updateRemisionCabeceraTrasporte(copyInput);
  };

  const handleObservacion = (value) => {
    let copyInput = {
      ...inputValues,
      observacion: value.target.value,
    };

    setInputValues(copyInput);
    remisionContext.updateRemisionCabeceraTrasporte(copyInput);
  };
  return (
    <>
      <Paper className={classes.padding2}>
        <Grid container direction="row" alignItems="flex-start" justify="flex-start" spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid container spacing={2} justify="flex-start" alignItems="flex-start">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="button" display="block">
                  Datos del vehículo de transporte
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  id="marca_del_vehiculo"
                  label="MARCA DEL VEHICULO"
                  name="marca_del_vehiculo"
                  variant="outlined"
                  color="secondary"
                  onChange={handleMarcaVehiculo}
                  error={textMarcaVehiculo}
                  helperText={
                    textMarcaVehiculo ? "Campo obligatorio" : null
                  }
                  value={
                    inputValues.marca_del_vehiculo && inputValues.marca_del_vehiculo
                  }
                  fullWidth
                  size="small"
                />

              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  id="nro_de_rua"
                  label="N° DE RUA"
                  name="nro_de_rua"
                  variant="outlined"
                  color="secondary"
                  onChange={handleRuaNro}
                  error={textRua}
                  helperText={
                    textRua ? "Campo obligatorio" : null
                  }
                  value={
                    inputValues.nro_de_rua && inputValues.nro_de_rua
                  }
                  fullWidth
                  size="small"
                />

              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  id="nro_rua_remolque"
                  label="N° DE RUA DE REMOLQUE/TRACTOR/SEMIREMOLQUE"
                  name="nro_rua_remolque"
                  variant="outlined"
                  color="secondary"
                  onChange={handleRuaRemolque}
                  value={
                    inputValues.nro_rua_remolque && inputValues.nro_rua_remolque
                  }
                  fullWidth
                  size="small"
                />

              </Grid>

            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid container spacing={2} justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="button" display="block">
                  Datos del conductor del vehículo
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  id="nombre_transporte"
                  label="NOMBRE Y APELIIDO / RAZON SOCIAL"
                  name="nombre_transporte"
                  variant="outlined"
                  color="secondary"
                  onChange={handleNombreTrnsporte}
                  error={textNombreTrasporte}
                  helperText={
                    textNombreTrasporte ? "Campo obligatorio" : null
                  }
                  value={
                    inputValues.nombre_transporte && inputValues.nombre_transporte
                  }
                  fullWidth
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  id="ruc_transporte"
                  label="RUC / CI"
                  name="ruc_transporte"
                  variant="outlined"
                  color="secondary"
                  onChange={handleRucTransporte}
                  error={textRucTrasporte}
                  helperText={
                    textRucTrasporte ? "Campo obligatorio" : null
                  }
                  value={
                    inputValues.ruc_transporte &&
                    inputValues.ruc_transporte
                  }
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  id="DOMICILIO"
                  label="DOMICILIO"
                  name="DOMICILIO"
                  variant="outlined"
                  color="secondary"
                  onChange={handleDomicilioTransporte}
                  value={
                    inputValues.domicilio_transporte &&
                    inputValues.domicilio_transporte
                  }
                  fullWidth
                  size="small"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              id="observacion"
              label="OBSERVACION"
              name="observacion"
              variant="outlined"
              color="secondary"
              onChange={handleObservacion}
              value={
                inputValues.observacion &&
                inputValues.observacion
              }
              fullWidth
              size="small"
            />
          </Grid>
        </Grid>
      </Paper>

    </>
  );
}
