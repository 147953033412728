
import NotFound from "./components/NotFound";
import ListaClientes from "./page/clientes/ListaClientes";
import ListaUsuarios from "./page/usuarios/ListaUsuarios";
import ListaProductos from "./page/productos/ListaProductos";
import ListaDepositos from "./page/depositos/ListaDepositos";
import MenuPrincipal from "./page/MenuPrincipal";
import Login from "./page/Login";

import RemisionListaPendiente from "./page/remisiones/RemisionListaPendiente";
import RemisionListaRemitidos from "./page/remisiones/RemisionListaRemitidos";
import RemisionListaAnulado from "./page/remisiones/RemisionListaAnulado";
import GenerarRemision from "./page/remisiones/GenerarRemision";
import DetalleRemisionPendiente from "./page/remisiones/DetalleRemisionPendiente";
import DetalleRemisionRemitidos from "./page/remisiones/DetalleRemisionRemitidos";
import DetalleRemisionAnulado from "./page/remisiones/DetalleRemisionAnulado";

import ContratoListaActivo from "./page/contratos/ContratoListaActivo";
import ContratoListaPendiente from "./page/contratos/ContratoListaPendiente";
import ContratoListaCancelado from "./page/contratos/ContratoListaCancelado";
import GenerarContrato from "./page/contratos/GenerarContrato";
import DetalleContratoActivo from "./page/contratos/DetalleContratoActivo";
import DetalleContratoPendiente from "./page/contratos/DetalleContratoPendiente";
import DetalleContratoCancelado from "./page/contratos/DetalleContratoCancelado";

import FacturacionLista from "./page/facturacion/FacturacionLista";
import FacturacionListaAnulado from "./page/facturacion/FacturacionListaAnulado";
import DetalleFacturacion from "./page/facturacion/DetalleFacturacion";
import GenerarFacturacion from "./page/facturacion/GenerarFacturacion";

import CobrarCredito from "./page/cobrarCredito/CobrarCredito";
import DetalleCobrarCredito from "./page/cobrarCredito/DetalleCobrarCredito";

import ReporteFacturacion from "./page/reportes/Facturacion"
import ReporteFacturacionPendiente from "./page/reportes/FacturacionPendiente";
import ReporteFacturacionCobradas from "./page/reportes/FacturacionCobradas"
import ProductoAlDeposito from "./page/producto-deposito/ProductoAlDeposito";
import AjustarStock from "./page/stock/AjustarStock";
import TimbradoRemision from "./page/timbrado/TimbradoRemision";
import TimbradoFacuracion from "./page/timbrado/TimbradoFacturacion";
import AgregarUsuario from "./page/usuarios/AgregarUsuario";
import EditarUsuario from "./page/usuarios/EditarUsuario";
import EditarContrato from "./page/contratos/EditarContrato";
import EditarRemision from "./page/remisiones/EditarRemision";
import Inventario from "./page/reportes/Inventario";
import DetalleNotaCredito from "./page/cobrarCredito/DetalleNotaCredito";
import ListaNotaCreditoGenerada from "./page/cobrarCredito/ListaNotaCreditoGenerada";
import DetalleNotaCreditoGenerada from "./page/cobrarCredito/DetalleNotaCreditoGenerada";
import TimbradoNotaCredito from "./page/timbrado/TimbradoNotaCredito";



export const routes = [
  {
    path: "/",
    label: "INICIO",
    component: MenuPrincipal,
    routes: [

      {
        path: '/acceder',
        label: 'INICIAR SESION',
        component: Login,
      },

      //cliente
      {
        path: "/lista-cliente/",
        label: "CLIENTES",
        component: ListaClientes,
      },
      //usuario
      {
        path: "/lista-usuario/",
        label: "USUARIOS",
        component: ListaUsuarios,
        routes: [
          {
            path: "/editar",
            label: "EDITAR",
            component: EditarUsuario,
          },
          {
            path: "/agregar",
            label: "AGREGAR",
            component: AgregarUsuario,
          }
        ]
      },
      //timbrado nota remision
      {
        path: "/timbrado-nota-remision",
        label: "ADMINISTRACION DE TIMBRADO DE NOTA DE REMISION",
        component: TimbradoRemision,
      },
      //timbrado facturacion
      {
        path: "/timbrado-facturacion",
        label: "ADMINISTRACION DE TIMBRADO DE FACTURACION",
        component: TimbradoFacuracion,
      },

      //timbrado nota credito
      {
        path: "/timbrado-nota-credito",
        label: "ADMINISTRACION DE TIMBRADO DE NOTA DE CREDITO",
        component: TimbradoNotaCredito,
      },

      //producto
      {
        path: "/lista-producto",
        label: "PRODUCTOS",
        component: ListaProductos,
      },
      //deposito
      {
        path: "/lista-deposito/",
        label: "DEPOSITOS",
        component: ListaDepositos,
      },
      //contrato
      {
        path: "/lista-contrato-activo/",
        label: "CONTRATOS ACTIVOS",
        component: ContratoListaActivo,
        routes: [
          {
            path: "/registrar/",
            label: "REGISTRAR CONTRATO",
            component: GenerarContrato,
          },
          {
            path: "/detalle/",
            label: "INFORMACION DEL CONTRATO",
            component: DetalleContratoActivo,
          },
        ],
      },

      {
        path: "/lista-contrato-pendiente/",
        label: "CONTRATOS PENDIENTES",
        component: ContratoListaPendiente,
        routes: [
          {
            path: "/registrar/",
            label: "REGISTRAR CONTRATO",
            component: GenerarContrato,
          },
          {
            path: "/modificar/",
            label: "MODIFICAR CONTRATO",
            component: EditarContrato,
          },
          {
            path: "/detalle/",
            label: "INFORMACION DEL CONTRATO",
            component: DetalleContratoPendiente,
          },
        ],
      },

      {
        path: "/lista-contrato-cancelado/",
        label: "CONTRATOS CANCELADOS",
        component: ContratoListaCancelado,
        routes: [
          {
            path: "/detalle/",
            label: "INFORMACION DEL CONTRATO",
            component: DetalleContratoCancelado,
          },
        ],
      },
      //nota-remision pendientes
      {
        path: "/lista-nota-remision-pendiente/",
        label: "NOTA DE REMISIONES PENDIENTES",
        component: RemisionListaPendiente,
        routes: [
          {
            path: "/generar/",
            label: "GENERAR NOTA DE REMISION",
            component: GenerarRemision,
          },
          {
            path: "/modificar/",
            label: "MODIFICAR NOTA DE REMISION",
            component: EditarRemision,
          },
          {
            path: "/detalle/",
            label: "INFORMACION DE LA NOTA DE REMISION",
            component: DetalleRemisionPendiente,
          },
        ],
      },
      //nota-remision activos
      {
        path: "/lista-nota-remision-remitido/",
        label: "NOTA DE REMISIONES REMITIDAS",
        component: RemisionListaRemitidos,
        routes: [
          {
            path: "/generar/",
            label: "GENERAR NOTA DE REMISION",
            component: GenerarRemision,
          },
          {
            path: "/detalle/",
            label: "INFORMACION DE LA NOTA DE REMISION",
            component: DetalleRemisionRemitidos,
          },
        ],
      },
      //nota-remision anulados
      {
        path: "/lista-nota-remision-anulado/",
        label: "NOTA DE REMISIONES ANULADOS",
        component: RemisionListaAnulado,
        routes: [
          {
            path: "/detalle/",
            label: "INFORMACION DE LA NOTA DE REMISION",
            component: DetalleRemisionAnulado,
          },
        ],
      },
      //facturacion
      {
        path: "/lista-facturacion/",
        label: "FACTURACION",
        component: FacturacionLista,
        routes: [
          {
            path: "/generar/",
            label: "GENERAR FACTURACION",
            component: GenerarFacturacion,
          },
          {
            path: "/detalle/",
            label: "INFORMACION DE LA FACTURACION",
            component: DetalleFacturacion,
          },
        ],
      },

      {
        path: "/lista-facturacion-anulada/",
        label: "FACTURACION ANULADA",
        component: FacturacionListaAnulado,
        routes: [
          {
            path: "/detalle/",
            label: "INFORMACION DE LA FACTURACION",
            component: DetalleFacturacion,
          },
        ],
      },
      //cobrar-credito
      {
        path: "/cobrar-credito/",
        label: "COBRAR CREDITO",
        component: CobrarCredito,
        routes: [
          {
            path: "/detalle/",
            label: "INFORMACION DEL CREDITO",
            component: DetalleCobrarCredito,
          },
          {
            path: "/nota-credito/",
            label: "INFORMACION DE LA NOTA DE CREDITO",
            component: DetalleNotaCredito,
          },

        ],
      },

      //nota-credito
      {
        path: "/lista-nota-credito/",
        label: "NOTA DE CREDITOS GENERADAS",
        component: ListaNotaCreditoGenerada,
        routes: [
          {
            path: "/detalle/",
            label: "INFORMACION DE LA NOTA DE CREDITO",
            component: DetalleNotaCreditoGenerada,
          },
        ],
      },

      //prodcuto-deposito
      {
        path: "/prodcuto-deposito/",
        label: "ASIGNAR PRODUCTO AL DEPOSITO",
        component: ProductoAlDeposito,
      },
      //ajustar-stock
      {
        path: "/ajustar-stock/",
        label: "AJUSTAR STOCK",
        component: AjustarStock,
      },
      //reporte-facturacion
      {
        path: "/reporte-facturacion/",
        label: "REPORTE FACTURACION",
        component: ReporteFacturacion,
      },
      //reporte-facturacion-PENDIENTE
      {
        path: "/reporte-facturacion-pendiente/",
        label: "REPORTE FACTURACION PENDIENTE",
        component: ReporteFacturacionPendiente,
      },
      //reporte-facturacion-COBRADAS
      {
        path: "/reporte-facturacion-cobradas/",
        label: "REPORTE FACTURACION COBRADAS",
        component: ReporteFacturacionCobradas,
      },

      {
        path: "/reporte-inventario//",
        label: "REPORTE DE INVENTARIO",
        component: Inventario,
      },

    ],
  },

  {
    path: "*",
    label: "Error en la página",
    component: NotFound,
  },
];
