import React, { useEffect, useContext } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useStyles, tableStyles } from "../../assets/styles/CustomStyles";
import StepperFacturacion from "../../components/facturacion/generar/StepperFacturacion";
import FacturacionProvider from "../../utils/facturacionContext/FacturacionProvider";
import AccesoDenegado from "../../components/AccesoDenegado";
import UserContext from "../../utils/user/UserContext";

export default function GenerarFacturacion() {

    const classes = tableStyles();
    const userContext = useContext(UserContext);
    useEffect(() => { }, []);

    return (
        <>
            {Number(userContext.state.accesos.facturacion) === 1 ?
                <FacturacionProvider>
                    <Grid container direction="row" justify="center" alignItems="center" >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Card className={classes.root}>
                                <CardContent>
                                    <Typography variant="body1" display="block">
                                        FACTURACION
                                    </Typography>
                                    <StepperFacturacion />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </FacturacionProvider>
                : <AccesoDenegado />}
        </>
    );
}
