import React, { useState } from "react";
import RemisionContext from "../remisionContext/RemisionContext";

const defaultCabeceraDestino = {
  destinatario: {id: "", contrato: "", nroContrato: "", cliente: "", ruc: "", denominacion: "", direccion: "", items: [] },

}
const defaultCabeceraTraslado = {
  motivo_del_traslado: "", comprobante_de_venta: "", nro_comprobante_de_venta: "", nro_de_timbrado: "", fecha_de_expedicion: "", fecha_de_inicio_de_traslado: "", fecha_de_termino_de_traslado: "", direccion_partida: "Habana 185 c/Lima", ciudad_partida: "M.R. Alonso", departamento_partida: "Central", direccion_llegada: "", ciudad_llegada: "", departamento_llegada: "", kilometros_de_recorrido: "", cambio_de_fecha: "", motivo: ""
}


const defaultCabeceraTrasporte = {
  marca_del_vehiculo: "", nro_de_rua: "", nro_rua_remolque: "", nombre_transporte: "", ruc_transporte: "", domicilio_transporte: "", observacion: "",
}

const defaultCabeceraMaxItem = {
  maxItem: 50
}

const defaultDetalle = {
  dataDetalle: [],
};

const RemisionProvider = ({ children }) => {
  const [cabeceraDestino, setCabeceraDestino] = useState(defaultCabeceraDestino);
  const [cabeceraTraslado, setCabeceraTraslado] = useState(defaultCabeceraTraslado);
  const [cabeceraTrasporte, setCabeceraTrasporte] = useState(defaultCabeceraTrasporte);
  const [cabeceraMaxItem, setCabeceraMaxItem] = useState(defaultCabeceraMaxItem);
  const [detalle, setDetalle] = useState(defaultDetalle);

  const updateRemisionCabeceraDestino = (props) => {
    if (props) {
      setCabeceraDestino(props);
    }
  };
  const updateRemisionCabeceraTraslado = (props) => {
    if (props) {
      setCabeceraTraslado(props);
    }
  };
  const updateRemisionCabeceraTrasporte = (props) => {
    if (props) {
      setCabeceraTrasporte(props);
    }
  };

  const updateRemisionCabeceraMaxItem = (props) => {
    if (props) {
      setCabeceraMaxItem(props);
    }
  };

  const updateRemisionDetalle = (props) => {
    if (props) {
      setDetalle({ ...detalle, dataDetalle: props });
    }
  };

  const clearRemision = () => {
    setCabeceraDestino(defaultCabeceraDestino);
    setCabeceraTraslado(defaultCabeceraTraslado);
    setCabeceraTrasporte(defaultCabeceraTrasporte);
    setCabeceraMaxItem(defaultCabeceraMaxItem);
    setDetalle(defaultDetalle);
  };

  return (
    <RemisionContext.Provider
      value={{
        cabeceraDestino,
        cabeceraTraslado,
        cabeceraTrasporte,
        cabeceraMaxItem,
        detalle,
        updateRemisionCabeceraDestino,
        updateRemisionCabeceraTraslado,
        updateRemisionCabeceraTrasporte,
        updateRemisionCabeceraMaxItem,
        updateRemisionDetalle,
        clearRemision,
      }}
    >
      {children}
    </RemisionContext.Provider>
  );
};

export default RemisionProvider;
