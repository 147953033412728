import React, { useState } from "react";
import ContratoContext from "../contratoContext/ContratoContext";

const defaultCabecera = {
  id: "",
  nroContrato: "",
  cliente: {id: "", ruc: "", denominacion: "", domicilio: "" },
  fechaInicio: "",
  fechaFin: "",
  facturar: false,
};

const defaultDetalle = {
  dataDetalle: [],
};

const ContratoProvider = ({ children }) => {
  const [cabecera, setCabecera] = useState(defaultCabecera);
  const [detalle, setDetalle] = useState(defaultDetalle);

  const updateContratoCabecera = (props) => {
    if (props) {
      setCabecera(props);
    }
  };

  const updateContratoDetalle = (props) => {
    if (props) {
      setDetalle({ ...detalle, dataDetalle: props });
    }
  };

  const clearContrato = () => {
    setCabecera(defaultCabecera);
    setDetalle(defaultDetalle);
  };

  return (
    <ContratoContext.Provider
      value={{
        cabecera,
        detalle,
        updateContratoCabecera,
        updateContratoDetalle,
        clearContrato,
      }}
    >
      {children}
    </ContratoContext.Provider>
  );
};

export default ContratoProvider;
