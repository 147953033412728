import React, { useEffect, useState, useContext } from "react";
import { MakeTables } from "../../components/MaterialTables/MakeTables";
import { useStyles } from "../../assets/styles/CustomStyles";
import { useHistory } from "react-router-dom";
import axios from "../../utils/axios";
import { Typography,Chip } from "@material-ui/core";
import { alertWarningError, alertaAceptarCancelar } from "../../components/Notificaciones";
import BackdropCustom from "../../components/BackdropCustom";
import IconButton from '@material-ui/core/IconButton';
import Add from '@material-ui/icons/AddBox';
import Tooltip from '@material-ui/core/Tooltip';
import AccesoDenegado from "../../components/AccesoDenegado";
import UserContext from "../../utils/user/UserContext";
const defaultValue = [
    {
      "id": "21",
      "fecha": "2021-08-09",
      "nroContrato": "02/2021",
      "facturar": 1,
      "fechaInicio": "19/04/2021",
      "fechaFin": "19/04/2023",
      "cliente": "18",
      "nombre": "MINISTERIO DE SALUD PUBLICA Y BIENESTAR SOCIAL",
      "ruc": "80000905-3",
      "direccion": "Centro Médico Nacional - Hospital Nacional",
      "estado": "activo"
    },
  ]
export default function ContratoListaCancelado() {
    const history = useHistory();
    const userContext = useContext(UserContext);
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({ content: [] });

    useEffect(() => {
        getContratos()
    }, []);

    const getContratos = async (props) => {
        setIsLoading(true);
        let url = props ? props : "contrato/funListadoGeneral.php?estado=cancelado" ;
        try {
            const response = await axios.get(url);
            let status = response.status
            if(status === 200){
                const contratos = response.data;
                setData({ ...data, content: contratos });

                setIsLoading(false);
            }
          
        } catch (error) {
            setIsLoading(false);
            if (error.response) {
                alertWarningError(error.response);
            }
        }
    };

    const title = (
        <>
            <Typography variant="body1" display="block">
                LISTA DE CONTRATOS CANCELADOS
            </Typography>

        </>
    );
    const columns = [
        {
            title: "ID",
            field: "id",
            width: "1%",
            hidden: true
        },
        {
            title: "Fecha",
            field: "fecha",
            width: "10%",
        },
        {
            title: "N° Contrato",
            field: "nroContrato",
            width: "12%",
        },
        {
            title: "RUC",
            field: "ruc",
            width: "12%",

        },
        {
            title: "Denominación",
            field: "nombre",
        },
        {
            title: "Facturar",
            field: "facturar",
            width: "8%",
            render: (rowData) => (
                <Chip
                  color={rowData?.facturar === "1"  ? "secondary" : "default"}
                  label={rowData?.facturar === "1"  ? "SI" : "NO"}
                  size="small"
                />
              ),
        },
        {
            title: "Fecha Inicio",
            field: "fechaInicio",
            width: "10%",
        },
        {
            title: "Fecha fin",
            field: "fechaFin",
            width: "10%",

        },

    ];

    const options = {
        filtering: false,
        exportButton: false,
        exportAllData: false,
        headerStyle: { position: "sticky", top: 0 },
        maxBodyHeight: "65vh",
        paging: true,
        draggable: false,
        rowStyle: {
            fontSize: "small",
        },
    };


    const detalle = (event, props) => {
        //corta el evento el onRowClick
        event.stopPropagation();
        history.push("./detalle/", {
            id: props.data.id,

        });
    };

    const actions = [
        {
            icon: "save",
            tooltip: "place-holder",
            onClick: (event, rowData) => alert("You saved " + rowData.name),
            hidden: true,
        },
        {
            icon: "save",
            tooltip: "FreeActions-place-holder",
            isFreeAction: true,
            onClick: (event, rowData) => alert("You saved " + rowData.name),
            hidden: true,
        },
    ];


    return (

        <>
            {Number(userContext.state.accesos.contrato) === 1 ?
                <>
                    <MakeTables
                        //onRowClick={detalle}
                        title={title}
                        columns={columns}
                        data={data?.content}
                        actions={actions}
                        isLoading={isLoading}
                        classes={classes}
                        options={options}
                        componentsAssets={{ classes, detalle }}
                    />
                </>
                : <AccesoDenegado />}
        </>
    );
}
