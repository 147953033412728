import React, { useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { useStyles } from "../../../assets/styles/CustomStyles";
import MaterialTable from "material-table";
import Localizacion from "../../MaterialTables/Localization";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import AddIcon from "@material-ui/icons/AddCircle";
import Divider from "@material-ui/core/Divider";
import FacturacionContext from "../../../utils/facturacionContext/FacturacionContext";
import NumberFormatCustom from "../../../utils/separadorMil";
import axios from "../../../utils/axios";
import { alertWarningError, notificacionWarning } from "../../Notificaciones";
import TypographyBold from "../../TypographyBold";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

const initialValue = {
  descripcion: "",
  cantidad: 0,
  precio: 0,
  impuesto: "10"
};
const dataItem =[
  {
    "id": "23",
    "fecha": "2022-01-13",
    "nroContrato": "20/2021",
    "fechaInicio": "19/10/2021",
    "fechaFin": "19/10/2023",
    "cliente": "18",
    "nombre": "MINISTERIO DE SALUD PUBLICA Y BIENESTAR SOCIAL",
    "ruc": "80000905-3",
    "direccion": "Centro Médico Nacional - Hospital Nacional",
    items: [
      {
        "iddetalle": "208",
        "descripcion": "M. P: Sub parte: Turbina. Verificación de nivelación, alineación y balanceo del conjunto impulsor y aspa aireador. Reemplazar si es necesario. Lote 1 - Ítem 1.",
        "cantidad": "20",
        "precio": "19,750,000"
      },
      {
        "iddetalle": "209",
        "descripcion": "M. P: Sub parte: Reductor. Desmonte, desarme, limpieza general, verificar estado. Sustituir según necesidad. Lote 1 - Ítem 2.",
        "cantidad": "20",
        "precio": "2,193,750"
      },
      {
        "iddetalle": "210",
        "descripcion": "M. P: Sub parte: Reductor. Verificación del estado de los engranajes, piñón, rodamientos superior - inferior, retenes de la caja reductora - sistema de acople de la turbina, bulones, arandelas y control de vibración. Sustituir según necesidad. Lote 1 - Ítem 3.",
        "cantidad": "20",
        "precio": "1,950,000"
      },
      {
        "iddetalle": "211",
        "descripcion": "M. P: Sub parte: Reductor. Provisión y cambio de aceite ISO 140 - junta para acople entre motor eléctrico y caja reductora. Reemplazar si es necesario. Lote 1 - Ítem 4.",
        "cantidad": "20",
        "precio": "2,850,000"
      },
      {
        "iddetalle": "212",
        "descripcion": "M. P: Sub parte: Motor eléctrico. Desmonte, desarme, limpieza general del motor eléctrico, verificación del estado y desgaste de los rodamientos delantero - trasero, alineación de rotores. Reemplazar según necesidad. Lote 1 - Ítem 5.",
        "cantidad": "20",
        "precio": "3,065,100"
      },
      {
        "iddetalle": "213",
        "descripcion": "M: P: Sub parte: Motor eléctrico. Medición de parámetros ( I, V, rpm, aislación), aislación del bobinado eléctrico, ajustes en la conexión y programación del arrancador suave, relé horario y motor eléctrico. Reemplazar según necesidad. Lote 1 - Ítem 6.",
        "cantidad": "20",
        "precio": "2,400,000"
      },
      {
        "iddetalle": "214",
        "descripcion": "M. C: Parte: Aireador. Sub parte: Turbina. Desmonte y montaje de turbina aireador. Lote 2 - Ítem 1.",
        "cantidad": "20",
        "precio": "4,150,000"
      },
      {
        "iddetalle": "215",
        "descripcion": "M. C: Parte: Aireador. Sub parte: Turbina. Trabajo de pintura epoxica de la turbina. Lote 2 - Ítem 2.",
        "cantidad": "20",
        "precio": "4,900,000"
      },
      {
        "iddetalle": "216",
        "descripcion": "M. C: Parte: Aireador. Sub parte: Turbina. Retiro, provisión e instalación de bulones con tuercas de 22 cm x 18 mm. Lote 2 - Ítem 3.",
        "cantidad": "20",
        "precio": "1,277,500"
      },
      {
        "iddetalle": "217",
        "descripcion": "M. C: Parte: Aireador. Sub parte: Turbina. Retiro, provisión e instalación de bulones con tuercas de 21 cm x 23 mm. Lote 2 - Ítem 4.",
        "cantidad": "20",
        "precio": "1,400,100"
      },
      {
        "iddetalle": "218",
        "descripcion": "M. C: Parte: Aireador. Sub parte: Turbina. Relleno del alabe impulsor. Lote 2 - Ítem 5.",
        "cantidad": "20",
        "precio": "3,912,550"
      },
      {
        "iddetalle": "219",
        "descripcion": "M. C: Parte: Aireador. Sub parte: Turbina. Nivelación del conjunto motorreductor con relación a la base de hormigón. Lote 2 Ítem 6.",
        "cantidad": "20",
        "precio": "1,352,500"
      },
      {
        "iddetalle": "220",
        "descripcion": "M. C: Parte: Aireador. Sub parte: Turbina. Alineación y balanceo del conjunto impulsor. Lote 2 - Ítem 7",
        "cantidad": "20",
        "precio": "3,612,550"
      },
      {
        "iddetalle": "221",
        "descripcion": "M. C: Parte: Aireador. Sub parte: Turbina. Provisión y reemplazo de alabe del impulsor. Lote 2 - Ítem 8.",
        "cantidad": "20",
        "precio": "4,387,500"
      },
      {
        "iddetalle": "222",
        "descripcion": "M. C: Parte: Aireador. Sub parte: Turbina. Provisión y reemplazo de aspa aireador. Lote 2 - Ítem 8.",
        "cantidad": "20",
        "precio": "4,387,500"
      },
      {
        "iddetalle": "223",
        "descripcion": "M. C: Parte: Aireador. Sub parte: Turbina. Trabajo de pintura de la caja reductora. Lote 2 - Ítem 10.",
        "cantidad": "20",
        "precio": "1,135,000"
      },
      {
        "iddetalle": "224",
        "descripcion": "M. C: Parte: Aireador. Sub parte: Reductor. Provisión y sustitución de rodamientos cilíndricos de la tapa superior correspondiente a engranajes (3 unidades). Lote 2 - Ítem 11.",
        "cantidad": "20",
        "precio": "3,150,000"
      },
      {
        "iddetalle": "225",
        "descripcion": "M. C: Parte: Aireador. Sub parte: Reductor. Provisión y sustitución de rodamientos cilíndricos de la tapa inferior correspondiente a engranajes (1 unidad). Lote 2 - Ítem 12.",
        "cantidad": "20",
        "precio": "1,487,500"
      },
      {
        "iddetalle": "226",
        "descripcion": "M. C: Parte: Aireador. Sub parte: Reductor. Provisión y sustitución de rodamiento cilíndrico superior correspondiente al eje (1 unidad). Lote 2 - Ítem 13.",
        "cantidad": "20",
        "precio": "1,260,000"
      },
      {
        "iddetalle": "227",
        "descripcion": "M. C: Parte: Aireador. Sub parte: Reductor. Provisión y sustitución de rodamiento esférico inferior correspondiente al eje (1 unidad). Lote 2 - Ítem 14.",
        "cantidad": "20",
        "precio": "1,437,550"
      },
      {
        "iddetalle": "228",
        "descripcion": "M. C: Parte: Aireador. Sub parte: Reductor. Provisión y sustitución de rodamientos cilíndricos del sistema  de acople correspondiente a la turbina (1 unidad). Lote 2 - Ítem 15.",
        "cantidad": "20",
        "precio": "1,582,500"
      },
      {
        "iddetalle": "229",
        "descripcion": "M. C: Parte: Aireador. Sub parte: Reductor. Provisión y cambio de retenes correspondientes al reductor y sistema de acople a la turbina ( 3 unidades), bulones, tuercas  y arandelas del sistema del acople. Lote 2  - Ítem 16.",
        "cantidad": "20",
        "precio": "1,737,550"
      },
      {
        "iddetalle": "230",
        "descripcion": "M. C: Parte: Aireador. Sub parte: Reductor. Provisión y reemplazo de engranaje nuevo (3 unidades), piñón nuevo correspondiente a la punta eje del motor (1 unidad). Lote 2 - Ítem 17.",
        "cantidad": "20",
        "precio": "11,425,000"
      },
      {
        "iddetalle": "231",
        "descripcion": "M. C: Parte: Aireador. Sub parte: Motor eléctrico. Trabajo de pintura de motor eléctrico, baño de bobina estatorica con barniz de aislamiento eléctrico, secado de horno. Lote 2 - Ítem 18.",
        "cantidad": "20",
        "precio": "2,067,550"
      },
      {
        "iddetalle": "232",
        "descripcion": "M. C: Parte: Aireador. Sub parte: Motor eléctrico. Provisión y cambio de kit de rodamientos delantero y trasero 2 (unidades). Lote 2 - Ítem 19.",
        "cantidad": "20",
        "precio": "4,487,500"
      },
      {
        "iddetalle": "233",
        "descripcion": "M. C: Parte: Aireador. Sub parte: Motor eléctrico. Provisión y cambio de kit de rodamientos delantero y trasero (2 unidades). Lote 2 - Ítem 19.",
        "cantidad": "20",
        "precio": "4,487,500"
      },
      {
        "iddetalle": "234",
        "descripcion": "M. C: Parte: Aireador. Sub parte: Motor eléctrico. Rebobinado de motor eléctrico asíncrono trifásico, ajustes y prueba de operación. Lote 2 - Ítem 20.",
        "cantidad": "20",
        "precio": "2,221,300"
      },
      {
        "iddetalle": "235",
        "descripcion": " M. C: Parte: Aireador. Sub parte: Motor eléctrico. Provisión y cambio de reten de entrada de 8 mm.-Lote 2 - Ítem 21.",
        "cantidad": "20",
        "precio": "2,511,250"
      },
      {
        "iddetalle": "236",
        "descripcion": "M. C: Parte: Aireador. Sub parte: Turbina. Su Nivelación del conjunto motorreductor con relación a la base de hormigón. Lote 2 Ítem 6.",
        "cantidad": "20",
        "precio": "1,352,500"
      },
      {
        "iddetalle": "237",
        "descripcion": "M. C: Parte: Aireador. Su Provisión y reemplazo de alabe del impulsor b parte: Turbina. Lote 2 - Ítem 8",
        "cantidad": "20",
        "precio": "4,387,500"
      }
    ]
  }
]

export default function DetalleContrato() {
  const classes = useStyles();

  const [articulo, setArticulo] = useState(initialValue);
  const [cantidadRegistro, setCantidadRegistro] = useState(0);
  const [cabecera, setCabecera] = useState("");
  const [total, setTotal] = useState(0);
  const [ivaCinco, setIvaCinco] = useState(0);
  const [ivaDiez, setIvaDiez] = useState(0);
  const localization = Localizacion;
  const facturacionContext = useContext(FacturacionContext);
  const [inputValues, setInputValues] = useState({});
  const [itemContratos, setItemContratos] = useState({
    content: dataItem[0].items,
  });
  const [isLoadingItemContratos, setIsLoadingItemContratos] = useState(false);


  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  useEffect(() => {
    getContrato()
    setDetalle({ ...detalle, content: facturacionContext.detalle.dataDetalle });
    setCantidadRegistro(facturacionContext.cabeceraMaxItem);
    setCabecera(facturacionContext.cabecera);
    setTotal(facturacionContext.cabecera.total);
    setIvaDiez(facturacionContext.cabecera.iva_10);
    setIvaCinco(facturacionContext.cabecera.iva_5);
  }, [facturacionContext.cabecera]);

  const getContrato = async () => {

    try {
      setIsLoadingItemContratos(true);
      const response = await axios.get("contrato/funConsultaContratoFacturacion.php", {
        params: {
          id: facturacionContext.cabecera.idContrato,
        },
      });
      let status = response.status
      if (status === 200) {
        setIsLoadingItemContratos(false);
        setItemContratos({ ...itemContratos, content: response.data[0].items });
      }

    } catch (error) {
      if (error.response) {
        alertWarningError(error.response);
      }
    } finally {
      setIsLoadingItemContratos(false);
    }
  };


  const [detalle, setDetalle] = useState({
    columns: [
      {
        field: "cantidad",
        title: "Cantidad",
        width: "5%",
        editable: "never",

      },
      {
        field: "descripcion",
        title: "Descripción",
        editable: "never",
        width: "55%",
      },
      {
        field: "impuesto",
        title: "Impuesto",
        editable: "never",
        width: "10%",
        type: "numeric",
      },
      {
        field: "precio_unitario",
        title: "Precio unitario",
        width: "15%",
        editable: "never",
        type: "numeric",
      },
      {
        field: "sub_total",
        title: "Sub Total",
        width: "15%",
        editable: "never",
        type: "numeric",
      },
    ],
    content: [],
  });

  const handlePrecioArticulo = (value) => {
    let valueGs = parseFloat(value.target.value).toLocaleString('en')
    setArticulo({ ...articulo, precio: valueGs });
  };

  const handleCantidadArticulo = (value) => {
    let valueCantidad = parseFloat(value.target.value).toLocaleString('en')
    setArticulo({ ...articulo, cantidad: valueCantidad });
  };
  const handleInpuestoArticulo = (value) => {
    setArticulo({ ...articulo, impuesto: value.target.value });
  };

  const handleMercaderia = (value) => {
    setArticulo({ ...articulo, descripcion: value.target.value });
  };


  const handleIncluir = (props) => {

    if (detalle.content.length >= cantidadRegistro) {
      notificacionWarning("No puede superar la cantidad maxima de items.");
      return;
    }

    if (articulo.descripcion !== "" && articulo.cantidad !== "" && articulo.precio !== "" && articulo.impuesto !== "") {
      if (Number(articulo.cantidad) <= 0) {
        notificacionWarning("No puede insertar cantidad menor a 1.");
        setArticulo({ ...articulo, cantidad: 0 });
        return;
      }

      let copyInput = { ...detalle };
      let copyContent = [...copyInput.content];
      let suma = articulo.precio.toString().replace(/,/g, "") * articulo.cantidad.toString().replace(/,/g, "")

      let totalSuma = (Number(suma) + Number(total))

      //verificamos el iva
      if (articulo.impuesto === "exenta") {
        //actualizamos la cabecera
        let cabeceraCopy = {
          ...cabecera,
          total: totalSuma
        };
        facturacionContext.updateFacturaCabecera(cabeceraCopy)
      } else {

        if (articulo.impuesto === "10") {
          let iva10 = (Number(suma) / 11).toFixed(2)
          let sumaIva10 = Number(iva10) + ivaDiez
          //actualizamos la cabecera
          let cabeceraCopy = {
            ...cabecera,
            iva_10: Math.round(sumaIva10),
            total: totalSuma
          };

          setIvaDiez(Math.round(sumaIva10))
          facturacionContext.updateFacturaCabecera(cabeceraCopy)

        } else {

          let iva5 = (Number(suma) / 21).toFixed(2)
          let sumaIva5 = Number(iva5) + ivaCinco
          //actualizamos la cabecera
          let cabeceraCopy = {
            ...cabecera,
            iva_5: Math.round(sumaIva5),
            total: totalSuma
          };

          setIvaCinco(Math.round(sumaIva5))
          facturacionContext.updateFacturaCabecera(cabeceraCopy)

        }

      }
      copyContent.push({
        cantidad: articulo.cantidad,
        descripcion: articulo.descripcion?.toUpperCase(),
        precio_unitario: articulo.precio,
        sub_total: suma.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        impuesto: articulo.impuesto
      });

      setTotal(totalSuma)

      copyInput.content = copyContent;
      setDetalle(copyInput);
      facturacionContext.updateFacturaDetalle(copyInput.content);
      setArticulo(initialValue);
      setInputValues({})
      document.getElementById("mercaderias").focus();

    } else {
      notificacionWarning("Por favor ingrese los datos para el articulo.");
    }
  };

  const onSelectItemContrato = (e, value) => {
    console.log(value)
    if (value?.descripcion !== inputValues.descripcion) {
      let copyInput = {
        ...inputValues,
        descripcion: value?.descripcion,
      };
      setInputValues(copyInput);

      let copyArticulo = {
        ...articulo,
        descripcion: value?.descripcion,
        precio: value?.precio
      }
      setArticulo(copyArticulo)
     // remisionContext.updateRemisionCabecera(copyInput);
    }

    if(value === null){
      setInputValues({})
        setArticulo(initialValue)
    }

  };

  return (
    <>
      <Grid container direction="row" alignItems="center" justify="center" spacing={1}>
        {/** cod articulo */}
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

          <Autocomplete
            fullWidth
            value={inputValues || ""}
            onChange={onSelectItemContrato}
            size="small"
            id="mercaderias"
            options={itemContratos.content}
            getOptionLabel={(option) =>
              option.descripcion
                ? option.descripcion
                : ""
            }
            loading={isLoadingItemContratos}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}

                  label="MERCADERIAS"
                  variant="outlined"
                  color="secondary"
                  // error={textContrato}
                  //  helperText={
                  //    textContrato ? "Campo obligatorio" : null
                  //  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoadingItemContratos ? (
                          <CircularProgress color="secondary" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              );
            }}
          />

        </Grid>
        {/** cantidad */}
        <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
          <TextField
            variant="outlined"
            id="cantidad"
            name="cantidad"
            label="Cantidad"
            size="small"
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            value={articulo.cantidad}
            onChange={(value) => handleCantidadArticulo(value)}
            fullWidth
          />
        </Grid>
        {/** impuesto */}
        <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
            size="small"
            required
          >
            <InputLabel id="tipoDoc-label">Impuesto</InputLabel>
            <Select
              labelId="impuesto-label"
              id="impuesto"
              fullWidth
              value={articulo.impuesto}
              onChange={(value) => handleInpuestoArticulo(value)}
              label="Impuesto"
            >
              <MenuItem value="5" key="5">5%</MenuItem>
              <MenuItem value="10" key="10">10%</MenuItem>
              <MenuItem value="exenta" key="exenta">Excenta</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/** precio */}
        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
          <TextField
            variant="outlined"
            id="precio"
            name="precio"
            label="Precio unitario"
            size="small"
            value={articulo.precio}
            InputProps={{
              inputComponent: NumberFormatCustom,
              readOnly:true
            }}
            onChange={(value) => handlePrecioArticulo(value)}
            fullWidth
          />
        </Grid>


        {/** incluir */}
        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
          <Button
            size="small"
            color="secondary"
            fullWidth
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => handleIncluir()}
          //className={classes.input}
          >
            AGREGAR
          </Button>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <MaterialTable
            title=""
            columns={detalle.columns}
            data={detalle.content}
            localization={localization}
            icons={tableIcons}
            options={{
              filtering: false,
              search: true,
              toolbar: false,
              headerStyle: { position: "sticky", top: 0 },
              maxBodyHeight: "55vh",
              paging: false,
              draggable: false,
              rowStyle: {
                fontSize: "small",
              },
            }}
            editable={{
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const dataDelete = [...detalle.content];
                    const index = oldData.tableData.id;

                    let resta = (total - oldData.sub_total.toString().replace(/,/g, ""))
                    setTotal(resta)

                    //verificamos el iva
                    if (oldData?.impuesto === "exenta") {
                      //actualizamos la cabecera
                      let cabeceraCopy = {
                        ...cabecera,
                        total: resta
                      };
                      facturacionContext.updateFacturaCabecera(cabeceraCopy)
                      dataDelete.splice(index, 1);
                      facturacionContext.updateFacturaDetalle(dataDelete);
                      setDetalle({ ...detalle, content: dataDelete });

                    } else {

                      if (oldData?.impuesto === "10") {
                        let totalItem = oldData.sub_total.toString().replace(/,/g, "")
                        let iva10 = (Number(totalItem) / 11).toFixed(2)
                        let restaIva10 = ivaDiez - Number(iva10)
                        //actualizamos la cabecera
                        let cabeceraCopy = {
                          ...cabecera,
                          iva_10: Math.round(restaIva10),
                          total: resta
                        };

                        setIvaDiez(Math.round(restaIva10))
                        facturacionContext.updateFacturaCabecera(cabeceraCopy)
                        setCabecera(cabeceraCopy)

                        dataDelete.splice(index, 1);
                        setDetalle({ ...detalle, content: dataDelete });
                        facturacionContext.updateFacturaDetalle(dataDelete);

                      } else {
                        let totalItem = oldData.sub_total.toString().replace(/,/g, "")
                        let iva5 = (Number(totalItem) / 21).toFixed(2)
                        let restaIva5 = ivaCinco - Number(iva5)
                        //actualizamos la cabecera
                        let cabeceraCopy = {
                          ...cabecera,
                          iva_5: Math.round(restaIva5),
                          total: resta
                        };

                        setIvaCinco(Math.round(restaIva5))
                        facturacionContext.updateFacturaCabecera(cabeceraCopy)
                        setCabecera(cabeceraCopy)

                        dataDelete.splice(index, 1);
                        setDetalle({ ...detalle, content: dataDelete });
                        facturacionContext.updateFacturaDetalle(dataDelete);

                      }

                    }




                    resolve();
                  }, 600);
                }),
            }}
          />
        </Grid>
        <Grid container direction="row" className={classes.alignDerecha}>

          {/** total e IVA */}

          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
            <List dense>
              <ListItem>
                <ListItemIcon >
                  <TypographyBold variant="subtitle2" color="inherit" >
                    IVA (5%):
                  </TypographyBold>
                </ListItemIcon>
                <ListItemText
                  className={classes.buttonFiltro}
                  primary={ivaCinco.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                />
              </ListItem>
            </List>
          </Grid>

          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
            <List dense>
              <ListItem>
                <ListItemIcon >
                  <TypographyBold variant="subtitle2" color="inherit" >
                    (10%):
                  </TypographyBold>
                </ListItemIcon>
                <ListItemText
                  className={classes.buttonFiltro}
                  primary={ivaDiez.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                />
              </ListItem>
            </List>
          </Grid>

          <Grid item xs={12} sm={12} md={2} lg={2} xl={2} >
            <List dense>
              <ListItem>
                <ListItemIcon >
                  <TypographyBold variant="subtitle2" color="inherit" >
                    TOTAL:
                  </TypographyBold>
                </ListItemIcon>
                <ListItemText
                  className={classes.buttonFiltro}
                  primary={total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                />
              </ListItem>

            </List>
          </Grid>


        </Grid>
      </Grid>

    </>
  );
}
