import React, { useContext, useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import DatosContrato from "./DatosContrato";
import Detalle from "./Detalle";

import {
  alertaAceptarCancelar,
  alertWarningError,
} from "../../Notificaciones";
import ContratoContext from "../../../utils/contratoContext/ContratoContext";
import { useStyles } from "../../../assets/styles/CustomStyles";
import Alert from "@material-ui/lab/Alert";

import ExitToApp from "@material-ui/icons/ExitToApp";
import axios from "../../../utils/axios";
import { useHistory } from "react-router-dom";
import BackdropCustom from "../../BackdropCustom";

const defaultValue = {
  ruc: false,
  nroContrato: false,
  fechaInicio: false,
  fechaFin: false
};
export default function StepperContrato() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const contratoContext = useContext(ContratoContext);
  const [textValue, setTextValue] = useState(defaultValue);
  const steps = getSteps();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [contratoOriginal, setContratoOriginal] = useState({});


  const dataProps = history.location.state;

  useEffect(() => {
    setIsLoading(true);

    getContrato();
  }, []);

  const getContrato = async () => {
    try {
      const response = await axios.get("contrato/funConsultaIndividual.php", {
        params: {
          id: dataProps.id,
        },
      });

      const status = response.status;
      if (status === 200) {
        const data = response.data[0]

        const initialValue = {
          id: data.id,
          nroContrato: data.nroContrato,
          cliente: {
            id: data.cliente,
            ruc: data.ruc,
            denominacion: data.nombre,
            domicilio: data.direccion,
          },
          facturar: data.facturar,
          fechaInicio: data.fechaInicio,
          fechaFin: data.fechaFin,

        };
        contratoContext.updateContratoCabecera(initialValue);

        var items = data.items.map(function (
          articulo
        ) {
          return {
            iddetalle: articulo.iddetalle,
            descripcion: articulo.descripcion,
            cantidad: articulo.cantidad,
            precio: articulo.precio,
          };
        });

        contratoContext.updateContratoDetalle(items);

        setContratoOriginal(data);
        setIsLoading(false);
      }

    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    }
  };

  function getSteps() {
    return [
      "Datos del contrato",
      "Detalle",
    ];
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return DatosContratoStep(textValue);
      case 1:
        return DetalleStep(textValue);
      default:
        return "";
    }
  }


  const DatosContratoStep = (props) => {
    return (
      <>
        <DatosContrato select={props} />
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          className={classes.button}
          size="small"
        >
          Anterior
        </Button>
        <Button variant="contained" color="primary" size="small" onClick={handleNext}>
          Siguiente
        </Button>
      </>
    );
  };

  const DetalleStep = () => {
    return (
      <>
        <Detalle />
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          className={classes.button}
          size="small"
        >
          Anterior
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleActivar()}
          size="small"
        >
          Activar Contrato
        </Button>
      </>
    );
  };



  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };



  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  const handleActivar = async () => {

    try {
      setIsLoading(true);

      const response = await axios.post(`/contrato/funActivar.php?id=` + Number(contratoContext.cabecera.id));

      let data = response.data;
      let status = response.status;
      if (status === 200) {
        handleNext();
      }

    } catch (error) {
      if (error.response) {
        alertWarningError(error.response);
      }
    } finally {
      setIsLoading(false);
    }

  };


  return (
    <>
      <BackdropCustom open={isLoading} />

      <div>
        <Stepper activeStep={activeStep} alternativeLabel >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel color="secondary">{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography variant="h6" component="h6">
                <Alert severity="success">{`El Contrato N° ${contratoContext.cabecera.nroContrato} fue activado exitosamente.`}  </Alert>
              </Typography>

            </div>
          ) : (
            <div>
              <Typography variant="h6" component="h6">
                {getStepContent(activeStep)}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
