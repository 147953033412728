import React, { useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { useStyles } from "../../../assets/styles/CustomStyles";
import MaterialTable from "material-table";
import Localizacion from "../../MaterialTables/Localization";
import TypographyBold from "../../TypographyBold";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import AddIcon from "@material-ui/icons/AddCircle";
import Divider from "@material-ui/core/Divider";
import FacturacionContext from "../../../utils/facturacionContext/FacturacionContext";
import NumberFormatCustom from "../../../utils/separadorMil";
import axios from "../../../utils/axios";
import { alertWarningError, notificacionWarning } from "../../Notificaciones";


import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

const initialValue = {
  descripcion: "",
  cantidad: 0,
  precio: 0,
};

export default function DetalleRemision() {
  const classes = useStyles();

  const [articulo, setArticulo] = useState(initialValue);
  const [ips, setIps] = useState(false);
  const [cantidadRegistro, setCantidadRegistro] = useState(0);
  const [itemRemision, setItemRemision] = useState({
    content: [],
  });
  const [isLoadingItemRemision, setIsLoadingItemRemision] = useState(false);
  const localization = Localizacion;
  const facturacionContext = useContext(FacturacionContext);
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  useEffect(() => {
   // setDetalle({ ...detalle, content: facturacionContext.detalle.dataDetalle });
    setCantidadRegistro(facturacionContext.cabeceraMaxItem);

    getRemisionItem()

  }, [facturacionContext.cabecera]);

  const getRemisionItem = async () => {

    try {
      setIsLoadingItemRemision(true);
      const response = await axios.get("facturacion/funConsultaRemision.php", {
        params: {
          id: facturacionContext.cabecera.idRemision,
        },
      });
      let status = response.status
      if (status === 200) {
        setIsLoadingItemRemision(false);
        setItemRemision({ ...itemRemision, content: response.data[0].items });
        facturacionContext.updateFacturaDetalle(response.data[0].items);

      }

    } catch (error) {
      if (error.response) {
        alertWarningError(error.response);
      }
    } finally {
      setIsLoadingItemRemision(false);
    }
  };

  const [detalle, setDetalle] = useState({
    columns: [
      {
        field: "descripcion",
        title: "Descripción",
        editable: "never",
        width: "50%",
      },
      {
        field: "cantidad",
        title: "Cantidad",
        width: "10%",
        editable: "never",
        type: "numeric",
      },
      {
        field: "precio_unitario",
        title: "Precio unitario",
        width: "20%",
        editable: "never",
        type: "numeric",
      },
      {
        field: "sub_total",
        title: "Sub Total",
        width: "20%",
        editable: "never",
        type: "numeric",
      },
    ],
    content: [],
  });


  return (
    <>
      <MaterialTable
        title=""
        columns={detalle.columns}
        data={itemRemision.content}
        localization={localization}
        icons={tableIcons}
        isLoading={isLoadingItemRemision}
        options={{
          filtering: false,
          search: true,
          toolbar: false,
          headerStyle: { position: "sticky", top: 0 },
          maxBodyHeight: "55vh",
          paging: false,
          draggable: false,
          rowStyle: {
            fontSize: "small",
          },
        }}
      />
      <Grid container direction="row"  className={classes.alignDerecha}>

        {/** total e IVA */}

        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
          <List dense>
            <ListItem>
              <ListItemIcon >
                <TypographyBold variant="subtitle2" color="inherit" >
                  TOTAL IVA:
                </TypographyBold>
              </ListItemIcon>
              <ListItemText
                className={classes.buttonFiltro}
                primary={facturacionContext.cabecera.iva_10}
              />
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
          <List dense>
            <ListItem>
              <ListItemIcon >
                <TypographyBold variant="subtitle2" color="inherit" >
                  TOTAL:
                </TypographyBold>
              </ListItemIcon>
              <ListItemText
                className={classes.buttonFiltro}
                primary={facturacionContext.cabecera.total}
              />
            </ListItem>

          </List>
        </Grid>


      </Grid>

    </>
  );
}
