import { useHistory } from "react-router-dom";
import React, { useEffect, useState, useContext } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Grid, CardActions, Typography } from "@material-ui/core";
import { useStyles } from "../../assets/styles/CustomStyles";
import axios from "../../utils/axios";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import FinalizarIcon from '@material-ui/icons/AssignmentTurnedIn';

import {
  notificacionExitosaV2,
  alertWarningError, notificacionAlerta, alertaAceptarCancelar
} from "../../components/Notificaciones";
import TypographyBold from "../../components/TypographyBold";
import BackdropCustom from "../../components/BackdropCustom";
import Paper from "@material-ui/core/Paper";
import MaterialTable from "material-table";
import Localizacion from "../../components/MaterialTables/Localization";
import TableIcons from "../../components/MaterialTables/TableIcons";
import Chip from "@material-ui/core/Chip";
import Close from "@material-ui/icons/Close";
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import CardHeader from '@material-ui/core/CardHeader';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DateIcon from '@material-ui/icons/DateRange';
import IconButton from '@material-ui/core/IconButton';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AccesoDenegado from "../../components/AccesoDenegado";
import UserContext from "../../utils/user/UserContext";

const tableColumns = [
  { field: "cantidad", title: "Cantidad", width: "5%" },
  { field: "descripcion", title: "Descripción" },
  { field: "impuesto", title: "Impuesto", width: "0%" },
  { field: "precio_unitario", title: "Precio Unitario", align: "right", width: "10%" },
  { field: "sub_total", title: "Sub Total", align: "right", width: "10%" },
];


const DetalleNotaCredito = () => {
  const history = useHistory();

  const dataProps = history.location.state;

  const classes = useStyles();
  const userContext = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [factura, setFactura] = useState({});
  const [pdf, setPdf] = useState(true);
  const localization = Localizacion;
  const tableIcons = TableIcons;



  useEffect(() => {

    getNotaCredito();
  }, []);

  const getNotaCredito = async () => {
    console.log(dataProps)
    try {
      setIsLoading(true);

      const response = await axios.get("notaDeCredito/funConsultaFactura.php", {
        params: {
          id: dataProps?.data?.id,
        },
      });
      let status = response.status
      if (status === 200) {
        setIsLoading(false);
        setFactura(response.data[0]);

        if (response.data[0]?.nro_factura !== "") {
          setPdf(false)
        } else {
          setPdf(true)
        }
      }

    } catch (error) {
      if (error.response) {
        alertWarningError(error.response);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleGuardar = async () => {
    factura?.nota_de_credito === "CON NOTA DE CREDITO" ?
      alertaAceptarCancelar({
        text:
          `¿Estás seguro que quieres volver a generar otra Nota de Crédito?`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          handleGuardarRegistro()
        }
      })
      :
      handleGuardarRegistro()

  };

  const handleGuardarRegistro = async () => {


    try {
      setIsLoading(true);

      let dataObject = {
        cliente: factura?.cliente,
        idFactura: factura?.id,
        total: factura?.total,
        iva_10: factura?.iva_10,
        iva_5: factura?.iva_5,
        items: factura?.items,
      };
      console.log(dataObject)
      const response = await axios.post("notaDeCredito/funAgregar.php", dataObject);

      let status = response.status
      if (status === 200) {
        let data = response.data

        notificacionExitosaV2({
          text: "Nota de Crédito generada exisotasamente. Nro: " + data?.factura,
          afterWork: () => {
            history.goBack();
          },

        });
        handlePDF(data?.id)

      }


    } catch (error) {
      if (error.response) {
        notificacionAlerta(error.response?.mensaje);
      }
    } finally {
      setIsLoading(false);
    }

  }

  const handlePDF = async (id) => {
    let tokenCookies = sessionStorage.getItem("jwt-jr");
    let url = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}extensiones/tcpdf/pdf/vNotaDeCredito.php?nroFactura=${id}&p=${JSON.parse(tokenCookies).token}`
    var win = window.open(url, '_blank');
    win.focus();

  }

  return (
    <>
      <BackdropCustom open={isLoading} />
      {Number(userContext.state.accesos.facturacion) === 1 ?
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{ marginBottom: 10 }}

        >
          <Card className={classes.root}>
            <CardHeader
              action={<>

                {factura?.nota_de_credito === "CON NOTA DE CREDITO" && <Chip label={factura?.nota_de_credito} variant="default" color="primary" icon={<AssignmentLateIcon />} />}
                <Chip label={factura?.fecha} variant="default" icon={<DateIcon />} />
                <IconButton aria-label="exit" onClick={(e) => {
                  history.goBack();
                }}>
                  <Close />
                </IconButton>

              </>

              }
              title={<Typography variant="body1" >
                INFORMACION DE LA NOTA DE CREDITO <Chip label={factura?.nro_factura} variant="default" size="small" icon={<AssignmentIcon />} />
              </Typography>}
            />
            <CardContent >
              <Paper >
                <Box p={1} mb={2} >
                  <Box ml={1} >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                      <Grid container direction="row" >
                        <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                          <TypographyBold variant="body2" display="block" color="textSecondary">
                            DESTINATARIO DEL CLIENTE
                          </TypographyBold>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={5}>
                          <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                              <List dense>
                                <ListItem>
                                  <ListItemIcon className={classes.buttonFiltro}>
                                    <TypographyBold variant="subtitle2" color="inherit" >
                                      Denominación:
                                    </TypographyBold>
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={factura?.nombre}
                                  />
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon className={classes.buttonFiltro}>
                                    <TypographyBold variant="subtitle2" color="inherit" >
                                      RUC:
                                    </TypographyBold>
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={factura?.ruc}
                                  />
                                </ListItem>


                              </List>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={5}>
                          <Grid container >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <List dense>
                                <ListItem>
                                  <ListItemIcon className={classes.buttonFiltro}>
                                    <TypographyBold variant="subtitle2" color="inherit" >
                                      Domicilio:
                                    </TypographyBold>
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={factura?.direccion}
                                  />
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon className={classes.buttonFiltro}>
                                    <TypographyBold variant="subtitle2" color="inherit" >
                                      Teléfono:
                                    </TypographyBold>
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={factura?.telefono}
                                  />
                                </ListItem>
                              </List>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Paper>


              <MaterialTable

                columns={tableColumns}
                data={factura?.items}
                localization={localization}
                icons={tableIcons}
                options={{
                  // showTitle: false,
                  toolbar: false,
                  filtering: false,
                  search: false,
                  headerStyle: { position: "sticky", top: 0 },
                  maxBodyHeight: "55vh",
                  paging: false,
                  draggable: false,
                  rowStyle: {
                    fontSize: "small",
                  },
                }}

              />
              <Box ml={1} >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                  <Grid container direction="row" className={classes.alignDerecha} >

                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                      <List dense>
                        <ListItem>
                          <ListItemIcon >
                            <TypographyBold variant="subtitle2" color="inherit" >
                              IVA (5%):
                            </TypographyBold>
                          </ListItemIcon>
                          <ListItemText
                            className={classes.buttonFiltro}
                            primary={factura?.iva_5}
                          />
                        </ListItem>
                      </List>
                    </Grid>

                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                      <List dense>
                        <ListItem>
                          <ListItemIcon >
                            <TypographyBold variant="subtitle2" color="inherit" >
                              (10%):
                            </TypographyBold>
                          </ListItemIcon>
                          <ListItemText
                            className={classes.buttonFiltro}
                            primary={factura?.iva_10}
                          />
                        </ListItem>
                      </List>
                    </Grid>

                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                      <List dense>
                        <ListItem>
                          <ListItemIcon >
                            <TypographyBold variant="subtitle2" color="inherit" >
                              TOTAL:
                            </TypographyBold>
                          </ListItemIcon>
                          <ListItemText
                            className={classes.buttonFiltro}
                            primary={factura?.total}
                          />
                        </ListItem>

                      </List>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
            <CardActions>
              <Box p={1} className={classes.botonDerecha}>

                <Button size="small"
                  color="primary"
                  variant="contained"
                  onClick={() => handleGuardar()}
                  className={classes.buttonFiltro}
                  startIcon={<FinalizarIcon />}>
                  Generar Nota de Crédito
                </Button>

              </Box>
            </CardActions>
          </Card>
        </Grid>
        : <AccesoDenegado />}
    </>
  );
};

export default DetalleNotaCredito;
