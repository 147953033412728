import React, { useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { useStyles } from "../../../assets/styles/CustomStyles";
import MaterialTable from "material-table";
import Localizacion from "../../MaterialTables/Localization";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { BotonVerde } from "../../../assets/styles/StyledButtons";
import AddIcon from "@material-ui/icons/AddCircle";
import Divider from "@material-ui/core/Divider";
import RemisionContext from "../../../utils/remisionContext/RemisionContext";
import { notificacionWarning, alertWarningError } from "../../Notificaciones";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "../../../utils/axios";

const initialValue = {
  descripcion: "",
  cantidad: 0,
};

export default function DetalleRemision() {
  const classes = useStyles();

  const [articulo, setArticulo] = React.useState(initialValue);
  const [inputValues, setInputValues] = useState({});
  const [cantidadRegistro, setCantidadRegistro] = React.useState(0);
  const [itemContratos, setItemContratos] = useState({
    content: [],
  });
  const [isLoadingItemContratos, setIsLoadingItemContratos] = useState(false);
  const localization = Localizacion;
  const remisionContext = useContext(RemisionContext);
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  useEffect(() => {
    getContrato()
    setDetalle({ ...detalle, content: remisionContext.detalle.dataDetalle });
    setCantidadRegistro(
      remisionContext.cabeceraMaxItem.maxItem
    );
  }, [remisionContext.cabeceraDestino]);


  const getContrato = async () => {

    try {
      setIsLoadingItemContratos(true);
      const response = await axios.get("contrato/funConsultaIndividual.php", {
        params: {
          id: remisionContext.cabeceraDestino.destinatario.id,
        },
      });
      let status = response.status
      if (status === 200) {
        setIsLoadingItemContratos(false);
        setItemContratos({ ...itemContratos, content: response.data[0].items });
      }

    } catch (error) {
      if (error.response) {
        alertWarningError(error.response);
      }
    } finally {
      setIsLoadingItemContratos(false);
    }
  };
  const [detalle, setDetalle] = React.useState({
    columns: [
      {
        field: "descripcion",
        title: "Descripción",
        editable: "never",
        width: "60%",
      },
      {
        field: "cantidad",
        title: "Cantidad",
        width: "40%",
        editable: "never",
        type: "numeric",
      },
    ],
    content: [],
    totalElelemts: 0,
  });
  

  const handleCantidadArticulo = (value) => {
    setArticulo({ ...articulo, cantidad: value.target.value });
  };

  const handleDescripcionArticulo = (value) => {
    setArticulo({ ...articulo, descripcion: value.target.value });
  };

  const handleIncluir = (props) => {
    if (detalle.content.length >= cantidadRegistro) {
      notificacionWarning("No puede superar la cantidad maxima de items.");
      return;
    }

    if (articulo.descripcion !== "" && articulo.cantidad !== "") {
      if (Number(articulo.cantidad) <= 0) {
        notificacionWarning("No puede insertar cantidad menor a 1.");
        setArticulo({ ...articulo, cantidad: 0 });
        return;
      }

      let copyInput = { ...detalle };
      let copyContent = [...copyInput.content];

      copyContent.push({
        cantidad: articulo.cantidad,
        descripcion: articulo.descripcion?.toUpperCase(),
      });
      copyInput.content = copyContent;
      setDetalle(copyInput);
      remisionContext.updateRemisionDetalle(copyInput.content);
      setArticulo(initialValue);
      setInputValues({})
      document.getElementById("mercaderias").focus();
      //seteamos los campos editables de la cabecera
   //   remisionContext.updateRemisionCabecera({
     //   ...remisionContext.cabecera,
     //   editable: true,
    //  });
    } else {
      notificacionWarning("Por favor ingrese los datos para el articulo.");
    }
  };

  const onSelectItemContrato = (e, value) => {
    if (value?.descripcion !== inputValues.descripcion) {
      let copyInput = {
        ...inputValues,
        descripcion: value?.descripcion,
      };
      setInputValues(copyInput);

      let copyArticulo = {
        ...articulo,
        descripcion: value?.descripcion
      }
      setArticulo(copyArticulo)
     // remisionContext.updateRemisionCabecera(copyInput);
    }

    if(value === null){
      setInputValues({})
        setArticulo(initialValue)
    }

  };
  return (
    <>
      <Grid container direction="row" alignItems="center" justify="center" spacing={1}>
        {/** cod articulo */}
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>

          <Autocomplete
            fullWidth
            value={inputValues || ""}
            onChange={onSelectItemContrato}
            size="small"
            id="mercaderias"
            options={itemContratos.content}
            getOptionLabel={(option) =>
              option.descripcion
                ? option.descripcion 
                : ""
            }
            loading={isLoadingItemContratos}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                
                  label="MERCADERIAS"
                  variant="outlined"
                  color="secondary"
                  // error={textContrato}
                  //  helperText={
                  //    textContrato ? "Campo obligatorio" : null
                  //  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoadingItemContratos ? (
                          <CircularProgress color="secondary" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              );
            }}
          />
        </Grid>

        {/** cantidad */}
        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
          <TextField
            variant="outlined"
            id="cantidad"
            name="cantidad"
            label="Cantidad"
            type="number"
            size="small"
            value={articulo.cantidad}
            onChange={(value) => handleCantidadArticulo(value)}
            fullWidth
          />
        </Grid>

        {/** incluir */}
        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
          <Button
            size="small"
            color="secondary"
            fullWidth
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => handleIncluir()}
            //className={classes.input}
          >
            AGREGAR
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <MaterialTable
            title=""
            columns={detalle.columns}
            data={detalle.content}
            localization={localization}
            icons={tableIcons}
            options={{
              filtering: false,
              search: true,
              toolbar: false,
              headerStyle: { position: "sticky", top: 0 },
              maxBodyHeight: "55vh",
              paging: false,
              draggable: false,
              rowStyle: {
                fontSize: "small",
              },
            }}
            editable={{
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const dataDelete = [...detalle.content];
                    const index = oldData.tableData.id;
                    dataDelete.splice(index, 1);
                    setDetalle({ ...detalle, content: dataDelete });
                    remisionContext.updateRemisionDetalle(dataDelete);
                    resolve();
                  }, 600);
                }),
            }}
          />
        </Grid>
      </Grid>

      
    </>
  );
}
