import React, { useEffect, useState, useContext } from "react";
import { MakeTables } from "../../components/MaterialTables/MakeTables";
import { useStyles } from "../../assets/styles/CustomStyles";
import { useHistory } from "react-router-dom";
import axios from "../../utils/axios";
import { Typography } from "@material-ui/core";
import { alertWarningError } from "../../components/Notificaciones";
import AccesoDenegado from "../../components/AccesoDenegado";
import UserContext from "../../utils/user/UserContext";


export default function RemisionListaAnulado() {
    const history = useHistory();
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const userContext = useContext(UserContext);
    const [data, setData] = useState({ content: [] });

    useEffect(() => {
        getRemisiones()
    }, []);

    const getRemisiones = async (props) => {
        setIsLoading(true);
        let url = props ? props : "remision/funListadoGeneral.php?estado=anulado";
        try {
            const response = await axios.get(url);

            const remisiones = response.data;
            setData({ ...data, content: remisiones });

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            if (error.response) {
                alertWarningError(error.response);
            }
        }
    };

    const title = (
        <>
            <Typography variant="body1" display="block">
                LISTA NOTA DE REMISIONES ANULADAS
            </Typography>

        </>
    );
    const columns = [
        {
            title: "Fecha",
            field: "fecha_activacion",
            width: "10%",
        },
        {
            title: "N° Remisión",
            field: "nro_factura",
            width: "15%"
        },
        {
            title: "Denominación",
            field: "nombre",

        },
        {
            title: "RUC/CI",
            field: "ruc",
            width: "15%"

        },
        {
            title: "N° Contrato",
            field: "nro_contrato",
            width: "15%"

        },
        {
            title: "N° Factura",
            field: "nro_factura",
            width: "15%"

        },


    ];


    const options = {
        filtering: false,
        exportButton: false,
        exportAllData: false,
        headerStyle: { position: "sticky", top: 0 },
        maxBodyHeight: "65vh",
        paging: true,
        draggable: false,
        rowStyle: {
            fontSize: "small",
        },
    };


    const detalle = (event, props) => {
        //corta el evento el onRowClick
        event.stopPropagation();
        history.push("./detalle/", {
            id: props.data.id,
        });
    };

    const actions = [
        {
            icon: "save",
            tooltip: "place-holder",
            onClick: (event, rowData) => alert("You saved " + rowData.name),
            hidden: true,
        },
        {
            icon: "save",
            tooltip: "FreeActions-place-holder",
            isFreeAction: true,
            onClick: (event, rowData) => alert("You saved " + rowData.name),
            hidden: true,
        },
    ];


    return (
        <>
            {Number(userContext.state.accesos.remision) === 1 ?
                <>

                    <MakeTables
                        //onRowClick={detalle}
                        title={title}
                        columns={columns}
                        data={data?.content}
                        actions={actions}
                        isLoading={isLoading}
                        classes={classes}
                        options={options}
                        componentsAssets={{ classes, detalle }}
                    />

                </>
                : <AccesoDenegado />}

        </>
    );
}
