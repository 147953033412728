import React, { useEffect, useState, useContext } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { useStyles } from "../../../assets/styles/CustomStyles";
import TypographyBold from "../../TypographyBold";
import RemisionContext from "../../../utils/remisionContext/RemisionContext";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Box from "@material-ui/core/Box";


export default function Cabecera(props) {
  // const { textContrato,} = props?.select;

  const classes = useStyles();
  const remisionContext = useContext(RemisionContext);
  const [remision, setRemision] = useState({
    content: []
  });

  const [cabeceraDestino, setCabeceraDestino] = useState("")
  const [cabeceraTraslado, setCabeceraTraslado] = useState("")
  const [cabeceraTrasporte, setCabeceraTrasporte] = useState("")


  useEffect(() => {
    setCabeceraDestino(remisionContext.cabeceraDestino.destinatario);
    setCabeceraTraslado(remisionContext.cabeceraTraslado);
    setCabeceraTrasporte(remisionContext.cabeceraTrasporte);
    // se utiliza para actualizar input values cuando el contexto ha sido actualizado
  }, [remisionContext.cabeceraDestino.destinatario]);




  return (
    <>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{ marginBottom: 10 }}

      >
        <Paper >
          <Box p={1} mb={2} >
            <Box ml={1} >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TypographyBold variant="body2" display="block" color="textSecondary">
                  DESTINATARIO DE LA MERCADERÍA
                </TypographyBold>
                <Grid container direction="row" >

                  <Grid item xs={12} sm={12} md={6} lg={6} xl={5}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                        <List dense>
                          <ListItem>
                            <ListItemIcon className={classes.buttonFiltro}>
                              <TypographyBold variant="subtitle2" color="inherit" >
                                Denominación:
                              </TypographyBold>
                            </ListItemIcon>
                            <ListItemText
                              primary={cabeceraDestino?.denominacion}
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon className={classes.buttonFiltro}>
                              <TypographyBold variant="subtitle2" color="inherit" >
                                RUC:
                              </TypographyBold>
                            </ListItemIcon>
                            <ListItemText
                              primary={cabeceraDestino?.ruc}
                            />
                          </ListItem>

                        </List>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={6} xl={5}>
                    <Grid container >
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <List dense>
                          <ListItem>
                            <ListItemIcon className={classes.buttonFiltro}>
                              <TypographyBold variant="subtitle2" color="inherit" >
                                Domicilio:
                              </TypographyBold>
                            </ListItemIcon>
                            <ListItemText
                              primary={cabeceraDestino?.direccion}
                            />
                          </ListItem>


                        </List>

                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Box ml={1} mt={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TypographyBold variant="body2" display="block" color="textSecondary">
                  DATOS DEL TRASLADO
                </TypographyBold>
                <Grid container direction="row" >
                  {/** izquierda */}
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={5}>
                    <Grid container direction="row">
                      <List dense>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <ListItem>
                            <ListItemIcon className={classes.buttonFiltro}>
                              <TypographyBold variant="subtitle2" color="inherit" >
                                Motivo del traslado:
                              </TypographyBold>
                            </ListItemIcon>
                            <ListItemText
                              primary={cabeceraTraslado?.motivo_del_traslado}
                            />
                          </ListItem>

                          <ListItem>
                            <ListItemIcon className={classes.buttonFiltro}>
                              <TypographyBold variant="subtitle2" color="inherit" >
                                Comprobante de venta:
                              </TypographyBold>
                            </ListItemIcon>
                            <ListItemText
                              primary={cabeceraTraslado?.comprobante_de_venta}
                            />
                          </ListItem>

                          <ListItem>
                            <ListItemIcon className={classes.buttonFiltro}>
                              <TypographyBold variant="subtitle2" color="inherit" >
                                Comprobante de venta N°:
                              </TypographyBold>
                            </ListItemIcon>
                            <ListItemText
                              primary={cabeceraTraslado?.nro_comprobante_de_venta}
                            />
                          </ListItem>


                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Grid container direction="row">
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <ListItem>
                                <ListItemIcon className={classes.buttonFiltro}>
                                  <TypographyBold variant="subtitle2" color="inherit" >
                                    N° de timbrado:
                                  </TypographyBold>
                                </ListItemIcon>
                                <ListItemText
                                  primary={cabeceraTraslado?.nro_de_timbrado}
                                />
                              </ListItem>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <ListItem>
                                <ListItemIcon className={classes.buttonFiltro}>
                                  <TypographyBold variant="subtitle2" color="inherit" >
                                    Fecha expedición:
                                  </TypographyBold>
                                </ListItemIcon>
                                <ListItemText
                                  primary={cabeceraTraslado?.fecha_de_expedicion}
                                />
                              </ListItem>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <ListItem>
                                <ListItemIcon className={classes.buttonFiltro}>
                                  <TypographyBold variant="subtitle2" color="inherit" >
                                    Fecha inicio traslado:
                                  </TypographyBold>
                                </ListItemIcon>
                                <ListItemText
                                  primary={cabeceraTraslado?.fecha_de_inicio_de_traslado}
                                />
                              </ListItem>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <ListItem>
                                <ListItemIcon className={classes.buttonFiltro}>
                                  <TypographyBold variant="subtitle2" color="inherit" >
                                    Fecha fin traslado:
                                  </TypographyBold>
                                </ListItemIcon>
                                <ListItemText
                                  primary={cabeceraTraslado?.fecha_de_termino_de_traslado}
                                />
                              </ListItem>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <ListItem>
                            <ListItemIcon className={classes.buttonFiltro}>
                              <TypographyBold variant="subtitle2" color="inherit" >
                                Cambio de fecha traslado y/o punto llegada:
                              </TypographyBold>
                            </ListItemIcon>
                            <ListItemText
                              primary={cabeceraTraslado?.cambio_de_fecha}
                            />
                          </ListItem>
                        </Grid>

                      </List>
                    </Grid>
                  </Grid>
                  {/** derecha */}
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={5}>
                    <Grid container >
                      <List dense>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Grid container direction="row">
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <ListItem>
                                <ListItemIcon className={classes.buttonFiltro}>
                                  <TypographyBold variant="subtitle2" color="inherit" >
                                    Dirección de partida:
                                  </TypographyBold>
                                </ListItemIcon>
                                <ListItemText
                                  primary={cabeceraTraslado?.direccion_partida}
                                />
                              </ListItem>

                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <ListItem>
                                <ListItemIcon className={classes.buttonFiltro}>
                                  <TypographyBold variant="subtitle2" color="inherit" >
                                    Ciudad:
                                  </TypographyBold>
                                </ListItemIcon>
                                <ListItemText
                                  primary={cabeceraTraslado?.ciudad_partida}
                                />
                              </ListItem>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <ListItem>
                                <ListItemIcon className={classes.buttonFiltro}>
                                  <TypographyBold variant="subtitle2" color="inherit" >
                                    Departamento:
                                  </TypographyBold>
                                </ListItemIcon>
                                <ListItemText
                                  primary={cabeceraTraslado?.departamento_partida}
                                />
                              </ListItem>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <ListItem>
                                <ListItemIcon className={classes.buttonFiltro}>
                                  <TypographyBold variant="subtitle2" color="inherit" >
                                    Dirección de llegada:
                                  </TypographyBold>
                                </ListItemIcon>
                                <ListItemText
                                  primary={cabeceraTraslado?.direccion_llegada}
                                />
                              </ListItem>

                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <ListItem>
                                <ListItemIcon className={classes.buttonFiltro}>
                                  <TypographyBold variant="subtitle2" color="inherit" >
                                    Ciudad:
                                  </TypographyBold>
                                </ListItemIcon>
                                <ListItemText
                                  primary={cabeceraTraslado?.ciudad_llegada}
                                />
                              </ListItem>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <ListItem>
                                <ListItemIcon className={classes.buttonFiltro}>
                                  <TypographyBold variant="subtitle2" color="inherit" >
                                    Departamento:
                                  </TypographyBold>
                                </ListItemIcon>
                                <ListItemText
                                  primary={cabeceraTraslado?.departamento_llegada}
                                />
                              </ListItem>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <ListItem>
                                <ListItemIcon className={classes.buttonFiltro}>
                                  <TypographyBold variant="subtitle2" color="inherit" >
                                    Km de recorrido:
                                  </TypographyBold>
                                </ListItemIcon>
                                <ListItemText
                                  primary={cabeceraTraslado?.kilometros_de_recorrido}
                                />
                              </ListItem>
                              <ListItem>
                                <ListItemIcon className={classes.buttonFiltro}>
                                  <TypographyBold variant="subtitle2" color="inherit" >
                                    Motivo:
                                  </TypographyBold>
                                </ListItemIcon>
                                <ListItemText
                                  primary={cabeceraTraslado?.motivo}
                                />
                              </ListItem>

                            </Grid>
                          </Grid>
                        </Grid>
                      </List>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

            </Box>

            <Box ml={1} mt={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TypographyBold variant="body2" display="block" color="textSecondary">
                  DATOS DEL VEHICULO DE TRANSPORTE
                </TypographyBold>
                <List dense>
                  <Grid container direction="row" >
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <ListItem>
                        <ListItemIcon className={classes.buttonFiltro}>
                          <TypographyBold variant="subtitle2" color="inherit" >
                            Marca del vehículo:
                          </TypographyBold>
                        </ListItemIcon>
                        <ListItemText
                          primary={cabeceraTrasporte?.marca_del_vehiculo}
                        />
                      </ListItem>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <ListItem>
                        <ListItemIcon className={classes.buttonFiltro}>
                          <TypographyBold variant="subtitle2" color="inherit" >
                            N° de RUA:
                          </TypographyBold>
                        </ListItemIcon>
                        <ListItemText
                          primary={cabeceraTrasporte?.nro_de_rua}
                        />
                      </ListItem>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <ListItem>
                        <ListItemIcon className={classes.buttonFiltro}>
                          <TypographyBold variant="subtitle2" color="inherit" >
                            N° de RUA:
                          </TypographyBold>
                        </ListItemIcon>
                        <ListItemText
                          primary={cabeceraTrasporte?.nro_rua_remolque}
                        />
                      </ListItem>
                    </Grid>

                  </Grid>
                </List>
              </Grid>
            </Box>

            <Box ml={1} mt={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TypographyBold variant="body2" display="block" color="textSecondary">
                  DATOS DEL CONDUCTOR DEL VEHICULO
                </TypographyBold>
                <List dense>
                  <Grid container direction="row" >
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <ListItem>
                        <ListItemIcon className={classes.buttonFiltro}>
                          <TypographyBold variant="subtitle2" color="inherit" >
                            Nombre y Apellido o Razón Social:
                          </TypographyBold>
                        </ListItemIcon>
                        <ListItemText
                          primary={cabeceraTrasporte?.nombre_transporte}
                        />
                      </ListItem>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <ListItem>
                        <ListItemIcon className={classes.buttonFiltro}>
                          <TypographyBold variant="subtitle2" color="inherit" >
                            RUC o CI:
                          </TypographyBold>
                        </ListItemIcon>
                        <ListItemText
                          primary={cabeceraTrasporte?.ruc_transporte}
                        />
                      </ListItem>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <ListItem>
                        <ListItemIcon className={classes.buttonFiltro}>
                          <TypographyBold variant="subtitle2" color="inherit" >
                            Domicilio:
                          </TypographyBold>
                        </ListItemIcon>
                        <ListItemText
                          primary={cabeceraTrasporte?.domicilio_transporte}
                        />
                      </ListItem>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <ListItem>
                        <ListItemIcon className={classes.buttonFiltro}>
                          <TypographyBold variant="subtitle2" color="inherit" >
                            Observación:
                          </TypographyBold>
                        </ListItemIcon>
                        <ListItemText
                          primary={cabeceraTrasporte?.observacion}
                        />
                      </ListItem>
                    </Grid>

                  </Grid>
                </List>
              </Grid>
            </Box>
          </Box>

        </Paper>
      </Grid>
    </>
  );
}
