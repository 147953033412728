import React, { useContext, useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TypographyBold from "../components/TypographyBold";
import BackdropCustom from "../components/BackdropCustom";
import { useHistory } from "react-router-dom";
import UserContext from "../utils/user/UserContext";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import AssignmentIndRoundedIcon from '@material-ui/icons/AssignmentIndRounded';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import SettingsApplicationsRoundedIcon from '@material-ui/icons/SettingsApplicationsRounded';
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import ListRoundedIcon from '@material-ui/icons/ListRounded';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import TrendingUpRoundedIcon from '@material-ui/icons/TrendingUpRounded';
import PaymentRoundedIcon from '@material-ui/icons/PaymentRounded';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AddShoppingCartRoundedIcon from '@material-ui/icons/AddShoppingCartRounded';
import PostAddIcon from '@material-ui/icons/PostAdd';
import ReceiptRoundedIcon from '@material-ui/icons/ReceiptRounded';
import TodayRoundedIcon from '@material-ui/icons/TodayRounded';
import StoreIcon from '@material-ui/icons/Storage';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Login from "./Login";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: 200,
  },
}));

export default function MenuPrincipal() {

  const userContext = useContext(UserContext);
  const classes = useStyles();
  const history = useHistory();

  const envioFormulario = (formulario) => {
    history.push("./" + formulario);
  };

  const logins = () => {
    return (
      <>

      </>
    )
  }

  return (
    <>

      <Box mt={4}>
        <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>

          {Number(userContext.state.accesos.contrato) === 1 ?
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <Card className={classes.root}>
                <CardContent>
                  <List dense subheader={<ListSubheader ><TypographyBold variant="subtitle2">CONTRATO</TypographyBold></ListSubheader>} >
                    <ListItem button>
                      <ListItemIcon onClick={() => envioFormulario("lista-contrato-activo/registrar/")}>
                        <DescriptionRoundedIcon />
                      </ListItemIcon>
                      <ListItemText id="registrar-contrato" primary="Registrar contrato" onClick={() => envioFormulario("lista-contrato-activo/registrar/")} />
                    </ListItem>
                    <ListItem button>
                      <ListItemIcon onClick={() => envioFormulario("lista-contrato-activo/")}>
                        <ListRoundedIcon />
                      </ListItemIcon>
                      <ListItemText id="lista-contrato" primary="Listar contrato activos" onClick={() => envioFormulario("lista-contrato-activo/")} />
                    </ListItem>

                    <ListItem button>
                      <ListItemIcon onClick={() => envioFormulario("lista-contrato-pendiente/")}>
                        <ListRoundedIcon />
                      </ListItemIcon>
                      <ListItemText id="lista-contrato-pendientes" primary="Listar contrato pendientes" onClick={() => envioFormulario("lista-contrato-pendiente/")} />
                    </ListItem>

                    <ListItem button>
                      <ListItemIcon onClick={() => envioFormulario("lista-contrato-cancelado/")}>
                        <ListRoundedIcon />
                      </ListItemIcon>
                      <ListItemText id="lista-contrato-cancelado" primary="Listar contrato cancelados" onClick={() => envioFormulario("lista-contrato-cancelado/")} />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
            : ""}

          {Number(userContext.state.accesos.remision) === 1 ?
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <Card className={classes.root}>
                <CardContent>
                  <List dense subheader={<ListSubheader ><TypographyBold variant="subtitle2">NOTA DE REMISION</TypographyBold></ListSubheader>} >
                    <ListItem button>
                      <ListItemIcon onClick={() => envioFormulario("lista-nota-remision-pendiente/generar/")}>
                        <AssignmentRoundedIcon />
                      </ListItemIcon>
                      <ListItemText id="generarRemision" primary="Generar Nota de Remisión" onClick={() => envioFormulario("lista-nota-remision-pendiente/generar/")} />
                    </ListItem>
                    <ListItem button>
                      <ListItemIcon onClick={() => envioFormulario("lista-nota-remision-remitido/")} >
                        <ListRoundedIcon />
                      </ListItemIcon>
                      <ListItemText id="listarRemision" primary="Listar Nota de Remisión Remitida" onClick={() => envioFormulario("lista-nota-remision-remitido/")} />
                    </ListItem>
                    <ListItem button>
                      <ListItemIcon onClick={() => envioFormulario("lista-nota-remision-pendiente/")} >
                        <ListRoundedIcon />
                      </ListItemIcon>
                      <ListItemText id="listarRemision-pendiente" primary="Listar Nota de Remisión Pendiente" onClick={() => envioFormulario("lista-nota-remision-pendiente/")} />
                    </ListItem>
                    <ListItem button>
                      <ListItemIcon onClick={() => envioFormulario("lista-nota-remision-anulado/")} >
                        <ListRoundedIcon />
                      </ListItemIcon>
                      <ListItemText id="listarRemision-anulado" primary="Listar Nota de Remisión Anulada" onClick={() => envioFormulario("lista-nota-remision-anulado/")} />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
            : ""}

          {Number(userContext.state.accesos.facturacion) === 1 ?
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <Card className={classes.root}>
                <CardContent>
                  <List dense subheader={<ListSubheader ><TypographyBold variant="subtitle2">FACTURACIÓN</TypographyBold></ListSubheader>} >
                    <ListItem button>
                      <ListItemIcon onClick={() => envioFormulario("lista-facturacion/generar/")}>
                        <TrendingUpRoundedIcon />
                      </ListItemIcon>
                      <ListItemText id="facturacionGenerar" primary="Generar Facturación" onClick={() => envioFormulario("lista-facturacion/generar/")} />
                    </ListItem>
                    <ListItem button>
                      <ListItemIcon onClick={() => envioFormulario("lista-facturacion/")}>
                        <ListRoundedIcon />
                      </ListItemIcon>
                      <ListItemText id="listaFacturacion" primary="Listar Facturación" onClick={() => envioFormulario("lista-facturacion/")} />
                    </ListItem>

                    <ListItem button>
                      <ListItemIcon onClick={() => envioFormulario("lista-facturacion-anulada/")}>
                        <ListRoundedIcon />
                      </ListItemIcon>
                      <ListItemText id="listaFacturacionAnulada" primary="Listar Facturación Anulada" onClick={() => envioFormulario("lista-facturacion-anulada/")} />
                    </ListItem>

                    <ListItem button>
                      <ListItemIcon onClick={() => envioFormulario("cobrar-credito/")}>
                        <PaymentRoundedIcon />
                      </ListItemIcon>
                      <ListItemText id="cobrarCredito" primary="Cobrar Crédito" onClick={() => envioFormulario("cobrar-credito/")} />
                    </ListItem>

                    <ListItem button>
                      <ListItemIcon onClick={() => envioFormulario("lista-nota-credito/")}>
                        <AssignmentTurnedInIcon />
                      </ListItemIcon>
                      <ListItemText id="creditoGeneradas" primary="Nota de Crédito generadas" onClick={() => envioFormulario("lista-nota-credito/")} />
                    </ListItem>

                  </List>
                </CardContent>
              </Card>
            </Grid>
            : ""}

          {Number(userContext.state.accesos.administracion) === 1 ?
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <Card className={classes.root}>
                <CardContent>
                  <List dense subheader={<ListSubheader ><TypographyBold variant="subtitle2">ADMINISTRACION</TypographyBold></ListSubheader>} >
                    <ListItem button>
                      <ListItemIcon onClick={() => envioFormulario("lista-cliente/")}>
                        <AssignmentIndRoundedIcon />
                      </ListItemIcon>
                      <ListItemText id="listaCliente" primary="Clientes" onClick={() => envioFormulario("lista-cliente/")} />
                    </ListItem>
                    <ListItem button>
                      <ListItemIcon onClick={() => envioFormulario("lista-usuario/")}>
                        <AccountCircleRoundedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Usuarios" onClick={() => envioFormulario("lista-usuario/")} />
                    </ListItem>
                    <ListItem button>
                      <ListItemIcon onClick={() => envioFormulario("timbrado-nota-remision/")}>
                        <SettingsApplicationsRoundedIcon />
                      </ListItemIcon>
                      <ListItemText id="timbrado-remision" primary="Configurar timbrado Nota Remisión" onClick={() => envioFormulario("timbrado-nota-remision/")} />
                    </ListItem>
                    <ListItem button>
                      <ListItemIcon onClick={() => envioFormulario("timbrado-facturacion/")}>
                        <SettingsApplicationsRoundedIcon />
                      </ListItemIcon>
                      <ListItemText id="timbrado-facturacion" primary="Configurar timbrado Facturación" onClick={() => envioFormulario("timbrado-facturacion/")} />
                    </ListItem>
                    <ListItem button>
                      <ListItemIcon onClick={() => envioFormulario("timbrado-nota-credito/")}>
                        <SettingsApplicationsRoundedIcon />
                      </ListItemIcon>
                      <ListItemText id="timbrado-nota-credito" primary="Configurar timbrado Nota de Crédito" onClick={() => envioFormulario("timbrado-nota-credito/")} />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
            : ""}
          {Number(userContext.state.accesos.stock) === 1 ?
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <Card className={classes.root}>
                <CardContent>
                  <List dense subheader={<ListSubheader ><TypographyBold variant="subtitle2">STOCK</TypographyBold></ListSubheader>} >
                    <ListItem button>
                      <ListItemIcon onClick={() => envioFormulario("lista-producto/")}>
                        <ShoppingCartIcon />
                      </ListItemIcon>
                      <ListItemText primary="Productos" onClick={() => envioFormulario("lista-producto/")} />
                    </ListItem>
                    <ListItem button>
                      <ListItemIcon onClick={() => envioFormulario("lista-deposito/")}>
                        <StoreIcon />
                      </ListItemIcon>
                      <ListItemText primary="Depositos" onClick={() => envioFormulario("lista-deposito/")} />
                    </ListItem>
                    <ListItem button>
                      <ListItemIcon onClick={() => envioFormulario("prodcuto-deposito/")}>
                        <PostAddIcon />
                      </ListItemIcon>
                      <ListItemText id="asignar-prodcuto-deposito" primary="Asignar producto al deposito" onClick={() => envioFormulario("prodcuto-deposito/")} />
                    </ListItem>

                    <ListItem button>
                      <ListItemIcon onClick={() => envioFormulario("ajustar-stock/")}>
                        <AddShoppingCartRoundedIcon />
                      </ListItemIcon>
                      <ListItemText id="ajuste-stock" primary="Ajustar stock" onClick={() => envioFormulario("ajustar-stock/")} />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
            : ""}
          {Number(userContext.state.accesos.reportes) === 1 ?
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <Card className={classes.root}>
                <CardContent>
                  <List dense subheader={<ListSubheader ><TypographyBold variant="subtitle2">REPORTES</TypographyBold></ListSubheader>} >
                    <ListItem button>
                      <ListItemIcon onClick={() => envioFormulario("reporte-facturacion/")}>
                        <TodayRoundedIcon />
                      </ListItemIcon>
                      <ListItemText id="report" primary="Facturación emitidas" onClick={() => envioFormulario("reporte-facturacion/")} />
                    </ListItem>
                    <ListItem button>
                      <ListItemIcon onClick={() => envioFormulario("reporte-facturacion-pendiente/")}>
                        <TodayRoundedIcon />
                      </ListItemIcon>
                      <ListItemText id="report" primary="Facturación pendiente por cobrar" onClick={() => envioFormulario("reporte-facturacion-pendiente/")} />
                    </ListItem>
                    <ListItem button>
                      <ListItemIcon onClick={() => envioFormulario("reporte-facturacion-cobradas/")}>
                        <TodayRoundedIcon />
                      </ListItemIcon>
                      <ListItemText id="report" primary="Facturación cobradas" onClick={() => envioFormulario("reporte-facturacion-cobradas/")} />
                    </ListItem>

                    <ListItem button>
                      <ListItemIcon onClick={() => envioFormulario("reporte-inventario/")}>
                        <TodayRoundedIcon />
                      </ListItemIcon>
                      <ListItemText id="report" primary="Inventario de stock" onClick={() => envioFormulario("reporte-inventario/")} />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>

            : ""}

        </Grid>
      </Box>

    </>
  );
}
