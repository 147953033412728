import React, { useContext, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import DatosContrato from "./DatosContrato";
import Detalle from "./Detalle";

import {
  notificacionError,
  alertWarningError,
} from "../../Notificaciones";
import { BotonGris } from "../../../assets/styles/StyledButtons";
import ContratoContext from "../../../utils/contratoContext/ContratoContext";
import { useStyles } from "../../../assets/styles/CustomStyles";
import Alert from "@material-ui/lab/Alert";

import ExitToApp from "@material-ui/icons/ExitToApp";
import axios from "../../../utils/axios";
import { useHistory } from "react-router-dom";
import BackdropCustom from "../../BackdropCustom";

const defaultValue = {
  ruc: false,
  nroContrato: false,
  fechaInicio: false,
  fechaFin: false
};
export default function StepperContrato() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const contratoContext = useContext(ContratoContext);
  const [textValue, setTextValue] = useState(defaultValue);
  const [claveContrato, setClaveContrato] = useState("");
  const steps = getSteps();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  function getSteps() {
    return [
      "Datos del contrato",
      "Detalle",
      "Procesar"
    ];
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return DatosContratoStep(textValue);
      case 1:
        return DetalleStep(textValue);
      case 2:
        return ConfirmarStep();
      default:
        return "";
    }
  }


  const DatosContratoStep = (props) => {
    return (
      <>
        <DatosContrato select={props} />
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          className={classes.button}
          size="small"
        >
          Anterior
        </Button>
        <Button variant="contained" color="primary" size="small" onClick={handleNext}>
          Siguiente
        </Button>
      </>
    );
  };

  const DetalleStep = () => {
    return (
      <>
        <Detalle />
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          className={classes.button}
          size="small"
        >
          Anterior
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleGuardar()}
          size="small"
        >
          Guardar Contrato
        </Button>
      </>
    );
  };

  const ConfirmarStep = () => {
    return (
      <>
        <Typography variant="h6" component="h6">
          <Alert severity="success">{`El Contrato N° ${contratoContext.cabecera.nroContrato} fue guardado exitosamente.`}</Alert>
        </Typography>
        <Button
          variant="contained"
          color="primary"
          className={classes.DialogoTexto}
          onClick={() => handleConfirmar()}
          size="small"
        >
          Confirmar Contrato
        </Button>
      </>
    );
  };

  const handleNext = () => {
    if (
      contratoContext.cabecera.cliente.ruc !== "" &&
      contratoContext.cabecera.nroContrato !== "" &&
      contratoContext.cabecera.fechaInicio !== "" &&
      contratoContext.cabecera.fechaFin !== ""
    ) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    //validamos cada input requerido  
    let copy = { ...textValue };
    if (contratoContext.cabecera.cliente.ruc === "") {
      copy.ruc = true;
    } else {
      copy.ruc = false;
    }

    if (contratoContext.cabecera.nroContrato === "") {
      copy.nroContrato = true;
    } else {
      copy.nroContrato = false;
    }

    if (contratoContext.cabecera.fechaInicio === "") {
      copy.fechaInicio = true;
    } else {
      copy.fechaInicio = false;
    }

    if (contratoContext.cabecera.fechaFin === "") {
      copy.fechaFin = true;
    } else {
      copy.fechaFin = false;
    }

    setTextValue(copy);

  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    contratoContext.clearContrato();
    setActiveStep(0);
  };

  const handleGuardar = async () => {

    let dataObject = {
      nroContrato: contratoContext.cabecera.nroContrato,
      cliente: contratoContext.cabecera.cliente.id,
      fechaInicio: contratoContext.cabecera.fechaInicio,
      fechaFin: contratoContext.cabecera.fechaFin,
      facturar: contratoContext.cabecera.facturar,
      items: contratoContext.detalle.dataDetalle,
    };
    console.log(JSON.stringify(dataObject))
    
        try {
          setIsLoading(true);
    
          const response = await axios.post("contrato/funAgregar.php", dataObject);
    
          let data = response.data;
          let status = response.status;
          if(status === 200){
            setClaveContrato(data?.id)
          handleNext();
          }
          
        } catch (error) {
          if (error.response) {
            alertWarningError(error.response);
          } 
        } finally {
          setIsLoading(false);
        }
        
  };

  
  const handleConfirmar = async () => {

        try {
          setIsLoading(true);
    
          const response = await axios.delete(`/contrato/funActivar.php?id=` + Number(claveContrato));
          let status = response.status
          if (status === 200) {
            handleNext();
          }
          
        } catch (error) {
          if (error.response) {
            alertWarningError(error.response);
          } 
        } finally {
          setIsLoading(false);
        }
        
  };


  return (
    <>
      <BackdropCustom open={isLoading} />

      <div>
        <Stepper activeStep={activeStep} alternativeLabel >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel color="secondary">{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography variant="h6" component="h6">
                <Alert severity="success">{`El Contrato N° ${contratoContext.cabecera.nroContrato} fue activado exitosamente.`}  </Alert>
              </Typography>
              <Button onClick={handleReset} className={classes.DialogoTexto} size="small">Cargar nuevo contrato</Button>

             
            </div>
          ) : (
            <div>
              <Typography variant="h6" component="h6">
                {getStepContent(activeStep)}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
