import { useHistory } from "react-router-dom";
import React, { useEffect, useState, useContext } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "../../assets/styles/CustomStyles";
import axios from "../../utils/axios";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import {
  notificacionExitosaV2,
  alertWarningError, notificacionWarning
} from "../../components/Notificaciones";
import TypographyBold from "../../components/TypographyBold";
import BuscadorArticuloDialog from "../../components/BuscadorArticuloDialog";
import Paper from "@material-ui/core/Paper";
import Close from "@material-ui/icons/Close";
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Divider from "@material-ui/core/Divider";
import InputBase from "@material-ui/core/InputBase";
import Search from "@material-ui/icons/Search";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CardActions from '@material-ui/core/CardActions';
import SaveIcon from '@material-ui/icons/Save';
import AccesoDenegado from "../../components/AccesoDenegado";
import UserContext from "../../utils/user/UserContext";


const initialValue = {
  articulo: { id: "", codigo: "", descripcion: "" }
};

const initialValueDeposito = {
  deposito: []
};
const ProductoAlDeposito = () => {
  const history = useHistory();

  const classes = useStyles();
  const [articulo, setArticulo] = useState(initialValue);
  const [isLoadingArticulo, setIsLoadiongArticulo] = useState(false);
  const [openArticulo, setOpenArticulo] = useState(false);
  const [inputValues, setInputValues] = useState(initialValueDeposito);
  const [depositos, setDespositos] = useState({
    content: [],
  });
  const [isLoadingDeposito, setIsLoadingDeposito] = useState(false);
  const userContext = useContext(UserContext);

  useEffect(() => {
    if (Number(userContext.state.accesos.stock) === 1) {
      getDepositos();
    }
  }, []);


  const getDepositos = async (props) => {
    setIsLoadingDeposito(true);
    let url = props ? props : "deposito/funListadoGeneral.php";
    try {
      const response = await axios.get(url);

      const deposito = response.data;
      let status = response.status
      if (status === 200) {
        setDespositos({ ...depositos, content: deposito });

        setIsLoadingDeposito(false);
      }

    } catch (error) {
      setIsLoadingDeposito(false);
      if (error.response) {
        alertWarningError(error.response);
      }
    }
  };


  const handleArticulo = (value) => {
    console.log(value)
    let copyInput = {
      ...articulo,
      articulo: {
        id: value?.id,
        codigo: value?.codigo,
        descripcion: value?.descripcion,
      },
    };
    setArticulo(copyInput);
  };


  const handleChangeArticulo = (value) => {
    let copyInput = {
      ...articulo,
      articulo: {
        codArticulo: value.target.value,
        descripcion: "",
        id: "",
      },
    };
    setArticulo(copyInput);
  };

  const handleOpenArticulo = () => {
    setOpenArticulo(true);
  };

  const handleCloseArticulo = (value) => {
    setOpenArticulo(false);
  };

  const loadArticulo = async () => {
    let codArticulo = articulo.articulo?.codArticulo?.toUpperCase();
    if (codArticulo) {
      try {
        setIsLoadiongArticulo(true);

        const response = await axios.get(
          `producto/funConsultaIndividual.php?codigo=${codArticulo}`
        );

        let data = response.data;

        if (data.length < 0) {
          notificacionWarning(
            "No se encontró el artículo con código " + codArticulo
          );
          return;
        }

        if (data.length > 1) {
          notificacionWarning(
            "Se encontró más de un resultado para el artículo con código " +
            codArticulo
          );
          return;
        }


        handleArticulo(data[0]);

      } catch (error) {
        if (error.response) {
          alertWarningError(error.response);
        }
      } finally {
        setIsLoadiongArticulo(false);
      }
    }
  };


  const onSelectDepositoOrigen = (e, value) => {
    if (value && value.length !== inputValues.deposito.length) {
      let copyInput = { ...inputValues };
      let copyContent = [...inputValues.deposito];
      copyContent.splice(0, copyContent.length);
      value &&
        value.map((lista) => {
          copyContent.push(lista);
        });
      copyInput.deposito = copyContent;
      setInputValues(copyInput);
    }
  };

  const handleGuardar = async () => {
    let dataObject = {
      id: articulo.articulo.id,
      descripcion: articulo.articulo.descripcion,
      deposito: inputValues.deposito
    }
    try {
      const response = await axios.post("producto/funAgregarDepositoProducto.php", dataObject);

      let status = response.status
      if (status === 200) {
        notificacionExitosaV2({
          text: "Asignación exitosamente.",
          afterWork: () => {
            history.goBack();
          },
        });


      }

    } catch (error) {

      if (error.response) {
        alertWarningError(error.response);
      }
    }
  };

  return (
    <>
      {Number(userContext.state.accesos.stock) === 1 ?
        <>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ marginBottom: 10 }}

          >
            <Card className={classes.root}>
              <CardHeader
                action={<>

                  <IconButton aria-label="exit" onClick={(e) => {
                    history.goBack();
                  }}>
                    <Close />
                  </IconButton>

                </>

                }
                title={<Typography variant="body1" >
                  ASIGNAR PRODUCTO AL DEPOSITO
                </Typography>}
              />
              <CardContent >
                <Paper >
                  <Box p={1} mb={2} >
                    <Box ml={1} >
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                        <Grid container direction="row" spacing={4}>
                          <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                            <TypographyBold variant="body2" display="block" color="textSecondary">
                              BUSCAR PRODUCTO
                            </TypographyBold>
                            <Box mt={1} >
                              <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                  <Paper className={classes.rootPaper}>
                                    <InputBase
                                      className={classes.input}
                                      id="codArticulo"
                                      name="codArticulo"
                                      placeholder="COD. ARTICULO"
                                      fullWidth
                                      autoFocus
                                      onChange={(e) => handleChangeArticulo(e)}
                                      value={
                                        articulo.articulo.codigo &&
                                        articulo.articulo.codigo
                                      }
                                      onBlur={() => loadArticulo()}
                                      inputProps={{ "aria-label": "Chofer" }}
                                    />
                                    <Divider className={classes.divider} orientation="vertical" />
                                    {isLoadingArticulo ? (
                                      <CircularProgress color="primary" size={20} />
                                    ) : (
                                      <IconButton
                                        style={{
                                          color: "#396b99",
                                          textAlign: "center",
                                          minWidth: 45,
                                          padding: "1px 8px 1px 8px",
                                        }}
                                        className={classes.iconButton}
                                        aria-label="search"
                                        onClick={() => handleOpenArticulo()}
                                      >
                                        <Search />
                                      </IconButton>
                                    )}
                                  </Paper>
                                </Grid>

                                <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                                  <TextField
                                    id="standard-read-only-input"
                                    label="DESCRIPCION ARTICULO"
                                    name="articulo"
                                    variant="outlined"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    value={
                                      articulo.articulo.descripcion &&
                                      articulo.articulo.descripcion
                                    }
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                              </Grid>

                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                            <TypographyBold variant="body2" display="block" color="textSecondary">
                              SELECCIONAR DEPOSITO
                            </TypographyBold>
                            <Box mt={1} >
                              <Grid container direction="row" spacing={1}>
                                {/** cod articulo */}
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                                  <Autocomplete
                                    multiple
                                    id="idDepositos"
                                    onChange={onSelectDepositoOrigen}
                                    loading={isLoadingDeposito}
                                    size="small"
                                    options={depositos.content}
                                    value={inputValues.deposito || ""}
                                    getOptionLabel={(option) =>
                                      option.id
                                        ? option.id + " - " + option.descripcion
                                        : ""
                                    }
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                        name="deposito"
                                        label="DEPOSITOS"
                                        // inputRef={register({ required: false })}
                                        InputProps={{
                                          ...params.InputProps,
                                          endAdornment: (
                                            <>
                                              {isLoadingDeposito ? (
                                                <CircularProgress color="primary" size={20} />
                                              ) : null}
                                              {params.InputProps.endAdornment}
                                            </>
                                          ),
                                        }}
                                      />
                                    )}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>

                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Paper>

              </CardContent>
              <CardActions >
                <Box p={1} className={classes.botonDerecha}>
                  <Button
                    size="small"
                    color="secondary"

                    variant="contained"
                    startIcon={<SaveIcon />}
                    onClick={() => handleGuardar()}

                  >
                    GUARDAR
                  </Button>
                </Box>

              </CardActions>
            </Card>
          </Grid>

          <BuscadorArticuloDialog
            open={openArticulo}
            onClose={handleCloseArticulo}
            onSetData={handleArticulo}
          />
        </>
        : <AccesoDenegado />}
    </>
  );
};

export default ProductoAlDeposito;
