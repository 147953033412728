import React, {useContext} from "react";
import { useHistory } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { tableStyles } from "../../assets/styles/CustomStyles";
import StepperContrato from "../../components/contrato/editar/StepperContrato";
import IconButton from '@material-ui/core/IconButton';
import Close from "@material-ui/icons/Close";
import ContratoProvider from "../../utils/contratoContext/ContratoProvider";
import AccesoDenegado from "../../components/AccesoDenegado";
import UserContext from "../../utils/user/UserContext";

export default function EditarContrato() {
    const history = useHistory();
    const classes = tableStyles();

    const userContext = useContext(UserContext);
    return (
        <>
            {Number(userContext.state.accesos.contrato) === 1 ?
                <>
                    <ContratoProvider>
                        <Grid container direction="row" justify="center" alignItems="center" >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Card className={classes.root}>
                                    <CardHeader
                                        action={
                                            <>
                                                <IconButton aria-label="exit" onClick={(e) => { history.goBack(); }}>
                                                    <Close />
                                                </IconButton>
                                            </>
                                        }
                                        title={<Typography variant="body1" >
                                            MODIFICAR CONTRATO
                                        </Typography>}
                                    />
                                    <CardContent>

                                        <StepperContrato />
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </ContratoProvider>
                </>
                : <AccesoDenegado />}

        </>
    );
}
