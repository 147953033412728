import React, { useState } from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import { useStyles } from "../assets/styles/CustomStyles";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { useForm } from 'react-hook-form';

DialogFiltroReporte.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export default function DialogFiltroReporte(props) {
    const { open, onClose, onSetData } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [fechaHasta, setFechaHasta] = useState("");
    const [fechaDesde, setFechaDesde] = useState("");
    const [data, setData] = useState({
        fechaDesde: "",
        fechaHasta: ""
    });
    const { register, handleSubmit } = useForm();
    const classes = useStyles();

    const onsubmitFiltro = () => {
        //e.preventDefault();
        onClose()
        onSetData(data)
        setFechaDesde("")
        setFechaHasta("")

    }

    const handleChangeFechaHasta = (value) => {
        setData({ ...data, fechaHasta: value.target.value });


        let fechaFin = value.target.value.split("-");
        let anhoFin = fechaFin[0];
        let mesFin = fechaFin[1];
        let diaFin = fechaFin[2];
        let fechaHasta =
            diaFin + "-" + (mesFin < 0 ? mesFin + 1 : mesFin) + "-" + anhoFin;
        setFechaHasta(fechaHasta)

    };
    const handleChangeFechaDesde = (value) => {
        setData({ ...data, fechaDesde: value.target.value });
        let fecha = value.target.value.split("-");
        let anho = fecha[0];
        let mes = fecha[1];
        let dia = fecha[2];
        let fechaDesde = dia + "-" + (mes < 0 ? mes + 1 : mes) + "-" + anho;
        setFechaDesde(fechaDesde)

    }

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            fullWidth
            open={open}
            onClose={onClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title" color="primary" className={classes.DialogTitle} onClose={onClose}>
                <Typography variant="body1" display="block">
                    REPORTE DE FACTURACION POR FECHA
                </Typography>
            </DialogTitle>
            <Divider />

            <DialogContent>
                <Grid container spacing={2} justify="center" alignItems="center">
                    <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                        <TextField
                            autoFocus
                            variant="outlined"
                            id="fechaDesde"
                            name="fechaDesde"
                            label="FECHA DESDE"
                            type="date"
                            required
                            onChange={(e) => handleChangeFechaDesde(e)}
                            defaultValue={fechaDesde}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                        <TextField
                            variant="outlined"
                            id="fechaHasta"
                            name="fechaHasta"
                            label="FECHA HASTA"
                            type="date"
                            required
                            onChange={(e) => handleChangeFechaHasta(e)}
                            defaultValue={fechaHasta}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                    </Grid>


                </Grid>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Grid>
                    <Button
                        disabled={fechaHasta === "" || fechaDesde === "" ? true : false}
                        variant="contained" size="small" onClick={() => onsubmitFiltro()} color="secondary">
                        Buscar
                    </Button>
                </Grid>
            </DialogActions>


        </Dialog>
    )
}