import React, {useState} from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import { useStyles } from "../assets/styles/CustomStyles";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Paper from "@material-ui/core/Paper";
import { InputBase, Typography } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core/styles";
import axios from "../utils/axios";
import TablePagination from "@material-ui/core/TablePagination";
import { useForm } from "react-hook-form";
import Button from "@material-ui/core/Button";
import {
  notificacionError,
  alertWarningError,
} from "./Notificaciones";
import LinearProgress from "@material-ui/core/LinearProgress";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";

BuscadorClienteDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onSetData: PropTypes.func.isRequired,
};

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "#fff",
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const defaultValue = {
  content: [],
  totalElementos: 0,
};
export default function BuscadorClienteDialog(props) {
  /**
   * @param {boolean} open muestra el dialogo en pantalla
   * @param {boolean} onClose muestra el dialogo en pantalla
   * @param {object} onSetData usamos si el dialogo tiene formulario, por este parametro le enviamos al padre los datos insertado
   */
  const { open, onClose, onSetData } = props;

  const [cliente, setCliente] = useState(defaultValue);
  const [textSearch, setTextSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();

  const handleOnSetData = (props) => {
 
    onSetData(props);
    setCliente(defaultValue);
  };

  const onsubmitFiltro = async (props) => {
   
    console.log(textSearch)
    try {
      setIsLoading(true);
      
      const response = await axios.get(
        `cliente/funConsultaIndividualPorNombre.php?nombre=${textSearch.toUpperCase()}`
      );
      let status = response.status;
      if (status === 200) {
        let data = response.data;
        console.log(data)
        setCliente({
          ...cliente,
          content: data,
          totalElementos: data?.length,
        });
        setTextSearch("")
        
      } 
    } catch (error) {
      onClose();
      if (error.response) {
        alertWarningError(error.response);
         
      }
    } finally {
      setIsLoading(false);
    }
  };



  const handleChangeArticulo = (value) => {
;
    console.log(value.target.value);
    setTextSearch(value.target.value);
  };

  const Cliente = () => {
    return (
      <>
        <DialogTitle
          id="form-dialog-cliente"
          className={classes.DialogTitle}
          onClose={onClose}
        >
          <Typography variant="body1" display="block">
            BUSCAR CLIENTE
          </Typography>
        </DialogTitle>
        <Divider />

        <DialogContent>
          <>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={4}
            >
             
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
         
                  <Paper className={classes.rootPaper}>
                    <InputBase
                      className={classes.input}
                      id="codArticulo"
                      name="codArticulo"
                      placeholder="COD. CLIENTE"
                      fullWidth
                      autoFocus
                      onChange={(e) => handleChangeArticulo(e)}
                    value={
                      textSearch && textSearch
                      
                    }
                    />
                    <Divider className={classes.divider} orientation="vertical" />
                    
                      <IconButton
                        style={{
                          color: "#396b99",
                          textAlign: "center",
                          minWidth: 45,
                          padding: "1px 8px 1px 8px",
                        }}
                        className={classes.iconButton}
                        aria-label="search"
                    onClick={() => onsubmitFiltro()}
                      >
                        <Search />
                      </IconButton>
                    
                  </Paper>
               
        
              </Grid>
            </Grid>
            {/** lista de articulos */}
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={1}
            >
              <Grid key={2} item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Divider className={classes.DialogoTexto} />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TableContainer component={Paper} className={classes.container}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className={classes.tableHead}
                          align="justify"
                          width={"25%"}
                        >
                          RUC
                        </TableCell>
                        <TableCell
                          className={classes.tableHead}
                          align="justify"
                          width={"75%"}
                        >
                          DENOMINACION
                        </TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {cliente.content?.length > 0 ? 
                        cliente.content.map((lista) => {
                          return (
                            <TableRow
                              key={lista.id}
                              onClick={() => {
                                handleOnSetData(lista);
                                onClose();
                              }}
                            >
                              <TableCell align="justify">
                                {lista.ruc}
                              </TableCell>
                              <TableCell align="justify">
                                {lista.nombre}
                              </TableCell>
                            </TableRow>
                          );
                        }) : 
                          <TableRow>
                            <TableCell colSpan={12}>
                              <Typography align="center" variant="body2">No se encontraron registros para mostrar</Typography>
                            </TableCell>
                        </TableRow>
                        }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </>
        </DialogContent>
        <Divider />
      </>
    );
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <Cliente />

      {isLoading && <LinearProgress />}
    </Dialog>
  );
}
