import { useHistory } from "react-router-dom";
import React, { useEffect, useState, useContext } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "../../assets/styles/CustomStyles";
import axios from "../../utils/axios";
import Box from "@material-ui/core/Box";
import {
  alertaAceptarCancelar,
  alertWarningError,
} from "../../components/Notificaciones";
import TypographyBold from "../../components/TypographyBold";
import BackdropCustom from "../../components/BackdropCustom";
import Paper from "@material-ui/core/Paper";
import MaterialTable from "material-table";
import Localizacion from "../../components/MaterialTables/Localization";
import TableIcons from "../../components/MaterialTables/TableIcons";
import Close from "@material-ui/icons/Close";
import CardHeader from '@material-ui/core/CardHeader';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import BlockIcon from '@material-ui/icons/Block';
import AccesoDenegado from "../../components/AccesoDenegado";
import UserContext from "../../utils/user/UserContext";

const tableColumns = [
  {
    field: "descripcion", title: "N°", width: "5%", render: rowData => <Typography variant="caption" display="block" > {rowData.tableData.id + 1}</Typography>
  },
  { field: "descripcion", title: "Descripción" },
  { field: "precio", title: "Precio unitario", align: "right", width: "10%" },
  { field: "cantidad", title: "Cantidad", align: "right", width: "10%" },
];

const DetalleContratoPendiente = () => {
  const history = useHistory();

  const dataProps = history.location.state;
  const userContext = useContext(UserContext);
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [contrato, setContrato] = useState("");

  const localization = Localizacion;
  const tableIcons = TableIcons;

  useEffect(() => {
    getContrato();
  }, []);

  const getContrato = async () => {
    console.log(dataProps)
    try {
      setIsLoading(true);
      const response = await axios.get("contrato/funConsultaIndividual.php", {
        params: {
          id: dataProps.id,
        },
      });
      let status = response.status
      if (status === 200) {
        setIsLoading(false);
        setContrato(response.data[0]);
        console.log(response.data[0]);
      }

    } catch (error) {
      if (error.response) {
        alertWarningError(error.response);
      }
    } finally {
      setIsLoading(false);
    }
  };


  const handleAnular = (event, props) => {

    alertaAceptarCancelar({
      text:
        `¿Estás seguro de anular el contrato N° ${contrato?.nroContrato}?`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.delete(`/contrato/funEliminar.php?id=` + Number(contrato?.id));
          let status = response.status
          if (status === 200) {
            history.goBack();
          }

        } catch (error) {
          console.log(error);
          if (error.response) {
            alertWarningError(error.response);
          }
        }
      }
    });

  }

  const handleActivar = (event, props) => {

    alertaAceptarCancelar({
      text:
        `¿Estás seguro de activar el contrato N° ${contrato?.nroContrato}?`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.get(`/contrato/funActivar.php?id=` + Number(contrato?.id));
          let status = response.status
          if (status === 200) {
            history.goBack();
          }

        } catch (error) {
          console.log(error);
          if (error.response) {
            alertWarningError(error.response);
          }
        }
      }
    });
  }
  return (
    <>
      {Number(userContext.state.accesos.contrato) === 1 ?
        <>
          <BackdropCustom open={isLoading} />

          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ marginBottom: 10 }}

          >
            <Card className={classes.root}>
              <CardHeader
                action={<>

                  <IconButton aria-label="exit" onClick={(e) => {
                    history.goBack();
                  }}>
                    <Close />
                  </IconButton>

                </>

                }
                title={<Typography variant="body1" >
                  INFORMACION DEL CONTRATO
                </Typography>}
              />
              <CardContent >
                <Paper >
                  <Box p={1} mb={2} >
                    <Box ml={1} >
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TypographyBold variant="body2" display="block" color="textSecondary">
                          DATOS DEL CLIENTE
                        </TypographyBold>
                        <Grid container direction="row" >
                          <Grid item xs={12} sm={12} md={6} lg={6} xl={5}>
                            <Grid container>
                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                                <List dense>
                                  <ListItem>
                                    <ListItemIcon className={classes.buttonFiltro}>
                                      <TypographyBold variant="subtitle2" color="inherit" >
                                        Denominación:
                                      </TypographyBold>
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={contrato?.nombre}
                                    />
                                  </ListItem>
                                  <ListItem>
                                    <ListItemIcon className={classes.buttonFiltro}>
                                      <TypographyBold variant="subtitle2" color="inherit" >
                                        RUC:
                                      </TypographyBold>
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={contrato?.ruc}
                                    />
                                  </ListItem>

                                </List>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={12} md={6} lg={6} xl={5}>
                            <Grid container >
                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <List dense>
                                  <ListItem>
                                    <ListItemIcon className={classes.buttonFiltro}>
                                      <TypographyBold variant="subtitle2" color="inherit" >
                                        Domicilio:
                                      </TypographyBold>
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={contrato?.direccion}
                                    />
                                  </ListItem>

                                  <ListItem>
                                    <ListItemIcon className={classes.buttonFiltro}>
                                      <TypographyBold variant="subtitle2" color="inherit" >
                                        Para Facturar:
                                      </TypographyBold>
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={contrato?.facturar  === "1" ? "SI" : "NO"}
                                    />
                                  </ListItem>

                                </List>

                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>

                    <Box ml={1} mt={1}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TypographyBold variant="body2" display="block" color="textSecondary">
                          DATOS DEL CONTRATO
                        </TypographyBold>
                        <List dense>
                          <Grid container direction="row" >
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                              <ListItem>
                                <ListItemIcon className={classes.buttonFiltro}>
                                  <TypographyBold variant="subtitle2" color="inherit" >
                                    N° del contrato:
                                  </TypographyBold>
                                </ListItemIcon>
                                <ListItemText
                                  primary={contrato?.nroContrato}
                                />
                              </ListItem>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                              <ListItem>
                                <ListItemIcon className={classes.buttonFiltro}>
                                  <TypographyBold variant="subtitle2" color="inherit" >
                                    Fecha inicio contrato:
                                  </TypographyBold>
                                </ListItemIcon>
                                <ListItemText
                                  primary={contrato?.fechaInicio}
                                />
                              </ListItem>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                              <ListItem>
                                <ListItemIcon className={classes.buttonFiltro}>
                                  <TypographyBold variant="subtitle2" color="inherit" >
                                    Fecha fin contrato:
                                  </TypographyBold>
                                </ListItemIcon>
                                <ListItemText
                                  primary={contrato?.fechaFin}
                                />
                              </ListItem>
                            </Grid>

                          </Grid>
                        </List>
                      </Grid>
                    </Box>
                  </Box>
                </Paper>


                <MaterialTable

                  columns={tableColumns}
                  data={contrato?.items}
                  localization={localization}
                  icons={tableIcons}
                  options={{
                    // showTitle: false,
                    toolbar: false,
                    filtering: false,
                    search: false,
                    headerStyle: { position: "sticky", top: 0 },
                    maxBodyHeight: "55vh",
                    paging: false,
                    draggable: false,
                    rowStyle: {
                      fontSize: "small",
                    },
                  }}

                />

              </CardContent>
              <CardActions>
                <Box p={1} className={classes.botonDerecha}>

                  <Button
                    size="small"
                    color="secondary"
                    variant="contained"
                    className={classes.buttonFiltro}
                    startIcon={<AssignmentTurnedInIcon />}
                    onClick={() => handleActivar()}
                  >
                    Activar
                  </Button>
                  <Button size="small"
                    color="default"
                    variant="contained"
                    onClick={() => handleAnular()}
                    className={classes.buttonFiltro}
                    startIcon={<BlockIcon />}>
                    Anular
                  </Button>

                </Box>
              </CardActions>
            </Card>

          </Grid>

        </>
        : <AccesoDenegado />}

    </>
  );
};

export default DetalleContratoPendiente;
