import React, { useEffect, useState, useContext } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { useStyles } from "../../../assets/styles/CustomStyles";
import TextField from "@material-ui/core/TextField";
import RemisionContext from "../../../utils/remisionContext/RemisionContext";

const initialValue = {
  motivo_del_traslado: "",
  comprobante_de_venta: "",
  nro_comprobante_de_venta: "",
  nro_de_timbrado: "",
  fecha_de_expedicion: "",
  fecha_de_inicio_de_traslado: "",
  fecha_de_termino_de_traslado: "",
  direccion_partida: "", ciudad_partida: "",
  departamento_partida: "",
  direccion_llegada: "",
  ciudad_llegada: "",
  departamento_llegada: "",
  kilometros_de_recorrido: "",
  cambio_de_fecha: "",
  motivo: ""
}

export default function Traslado(props) {
  const {
    textComprobanteVenta,
  //  textNroComprobanteVenta,
    textFechaInicioTraslado,
    textFechaFinTraslado,
    textDireccionPartida,
    textCiudadPartida,
    textDepartamentoPartida,
    textDireccionLlegada,
    textCiudadLlegada,
    textDepartamentoLlegada,
  } = props.select;

  const classes = useStyles();
  const remisionContext = useContext(RemisionContext);
  const [inputValues, setInputValues] = useState(initialValue);


  useEffect(() => {
    setInputValues(remisionContext.cabeceraTraslado);

    // se utiliza para actualizar input values cuando el contexto ha sido actualizado
  }, []);


  // seteos de input
  const handleMotivoTraslado = (value) => {

    let copyInput = {
      ...inputValues,
      motivo_del_traslado: value.target.value,
    };

    setInputValues(copyInput);
    remisionContext.updateRemisionCabeceraTraslado(copyInput);
  };

  const handleComprobanteVenta = (value) => {
    let copyInput = {
      ...inputValues,
      comprobante_de_venta: value.target.value,
    };

    setInputValues(copyInput);
    remisionContext.updateRemisionCabeceraTraslado(copyInput);
  };

  const handleComprobanteVentaNro = (value) => {
    let copyInput = {
      ...inputValues,
      nro_comprobante_de_venta: value.target.value,
    };

    setInputValues(copyInput);
    remisionContext.updateRemisionCabeceraTraslado(copyInput);
  };

  const handleNroTimbrado = (value) => {
    let copyInput = {
      ...inputValues,
      nro_de_timbrado: value.target.value,
    };

    setInputValues(copyInput);
    remisionContext.updateRemisionCabeceraTraslado(copyInput);
  };

  const handleFechaExpedicion = (value) => {
    let copyInput = {
      ...inputValues,
      fecha_de_expedicion: value.target.value,
    };

    setInputValues(copyInput);
    remisionContext.updateRemisionCabeceraTraslado(copyInput);
  };

  const handleFechaInicioTraslado = (value) => {
    let copyInput = {
      ...inputValues,
      fecha_de_inicio_de_traslado: value.target.value,
    };

    setInputValues(copyInput);
    remisionContext.updateRemisionCabeceraTraslado(copyInput);
  };

  const handleFechaFinTraslado = (value) => {
    let copyInput = {
      ...inputValues,
      fecha_de_termino_de_traslado: value.target.value,
    };

    setInputValues(copyInput);
    remisionContext.updateRemisionCabeceraTraslado(copyInput);
  };

  const handleDireccionPartida = (value) => {
    let copyInput = {
      ...inputValues,
      direccion_partida: value.target.value,
    };

    setInputValues(copyInput);
    remisionContext.updateRemisionCabeceraTraslado(copyInput);
  };

  const handleCiudadPartida = (value) => {
    let copyInput = {
      ...inputValues,
      ciudad_partida: value.target.value,
    };

    setInputValues(copyInput);
    remisionContext.updateRemisionCabeceraTraslado(copyInput);
  };

  const handleDepartamentoPartida = (value) => {
    let copyInput = {
      ...inputValues,
      departamento_partida: value.target.value,
    };

    setInputValues(copyInput);
    remisionContext.updateRemisionCabeceraTraslado(copyInput);
  };


  const handleDireccionLlegada = (value) => {
    let copyInput = {
      ...inputValues,
      direccion_llegada: value.target.value,
    };

    setInputValues(copyInput);
    remisionContext.updateRemisionCabeceraTraslado(copyInput);
  };


  const handleCiudadLlegada = (value) => {
    let copyInput = {
      ...inputValues,
      ciudad_llegada: value.target.value,
    };

    setInputValues(copyInput);
    remisionContext.updateRemisionCabeceraTraslado(copyInput);
  };

  const handleDepartamentoLlegada = (value) => {
    let copyInput = {
      ...inputValues,
      departamento_llegada: value.target.value,
    };

    setInputValues(copyInput);
    remisionContext.updateRemisionCabeceraTraslado(copyInput);
  };

  const handleKilometro = (value) => {
    let copyInput = {
      ...inputValues,
      kilometros_de_recorrido: value.target.value,
    };

    setInputValues(copyInput);
    remisionContext.updateRemisionCabeceraTraslado(copyInput);
  };

  const handleFechaCambio = (value) => {
    let copyInput = {
      ...inputValues,
      cambio_de_fecha: value.target.value,
    };

    setInputValues(copyInput);
    remisionContext.updateRemisionCabeceraTraslado(copyInput);
  };

  const handleMotivo = (value) => {
    let copyInput = {
      ...inputValues,
      motivo: value.target.value,
    };

    setInputValues(copyInput);
    remisionContext.updateRemisionCabeceraTraslado(copyInput);
  };

  return (
    <>
      <Paper className={classes.padding2}>
        <Grid container direction="row" alignItems="center" justify="center" spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid container spacing={2} justify="flex-start" alignItems="flex-start">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  id="motivoTraslado"
                  label="MOTIVO DE TRASLADO"
                  name="motivoTraslado"
                  variant="outlined"
                  color="secondary"
                  onChange={handleMotivoTraslado}
                  value={
                    inputValues.motivo_del_traslado && inputValues.motivo_del_traslado
                  }
                  fullWidth
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  id="comprobante_de_venta"
                  label="COMPROBANTE DE VENTA"
                  name="comprobante_de_venta"
                  variant="outlined"
                  color="secondary"
                  onChange={handleComprobanteVenta}
                  error={textComprobanteVenta}
                  helperText={
                    textComprobanteVenta ? "Campo obligatorio" : null
                  }
                  value={
                    inputValues.comprobante_de_venta && inputValues.comprobante_de_venta
                  }
                  fullWidth
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  id="nro_comprobante_de_venta"
                  label="COMPROBANTE DE VENTA N°"
                  name="nro_comprobante_de_venta"
                  variant="outlined"
                  color="secondary"
                  onChange={handleComprobanteVentaNro}
                //  error={textNroComprobanteVenta}
                 // helperText={ textNroComprobanteVenta ? "Campo obligatorio" : null }
                  value={
                    inputValues.nro_comprobante_de_venta && inputValues.nro_comprobante_de_venta
                  }
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  id="nro_de_timbrado"
                  label="N° DE TIMBRADO"
                  name="nro_de_timbrado"
                  variant="outlined"
                  color="secondary"
                  onChange={handleNroTimbrado}
                  value={
                    inputValues.nro_de_timbrado && inputValues.nro_de_timbrado
                  }
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  id="fecha_de_expedicion"
                  label="FECHA DE EXPEDICION"
                  name="fecha_de_expedicion"
                  variant="outlined"
                  color="secondary"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleFechaExpedicion}
                  value={
                    inputValues.fecha_de_expedicion && inputValues.fecha_de_expedicion
                  }
                  fullWidth
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  id="fecha_de_inicio_de_traslado"
                  label="FECHA DE INICIO DE TRASLADO"
                  name="fecha_de_inicio_de_traslado"
                  variant="outlined"
                  color="secondary"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={textFechaInicioTraslado}
                  helperText={
                    textFechaInicioTraslado ? "Campo obligatorio" : null
                  }
                  onChange={handleFechaInicioTraslado}
                  value={
                    inputValues.fecha_de_inicio_de_traslado && inputValues.fecha_de_inicio_de_traslado
                  }
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  id="fecha_de_termino_de_traslado"
                  label="FECHA DE FIN DE TRASLADO"
                  name="fecha_de_termino_de_traslado"
                  type="date"
                  variant="outlined"
                  color="secondary"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={textFechaFinTraslado}
                  helperText={
                    textFechaFinTraslado ? "Campo obligatorio" : null
                  }
                  onChange={handleFechaFinTraslado}
                  value={
                    inputValues.fecha_de_termino_de_traslado && inputValues.fecha_de_termino_de_traslado
                  }
                  fullWidth
                  size="small"
                />
              </Grid>


            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid container spacing={2} justify="flex-start" alignItems="center">

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  id="direccion_partida"
                  label="DIRECCION DE PARTIDA"
                  name="direccion_partida"
                  variant="outlined"
                  color="secondary"
                  onChange={handleDireccionPartida}
                  error={textDireccionPartida}
                  helperText={
                    textDireccionPartida ? "Campo obligatorio" : null
                  }
                  value={
                    inputValues.direccion_partida && inputValues.direccion_partida
                  }
                  fullWidth
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  id="ciudad_partida"
                  label="CIUDAD"
                  name="ciudad_partida"
                  variant="outlined"
                  color="secondary"
                  onChange={handleCiudadPartida}
                  error={textCiudadPartida}
                  helperText={
                    textCiudadPartida ? "Campo obligatorio" : null
                  }
                  value={
                    inputValues.ciudad_partida &&
                    inputValues.ciudad_partida
                  }
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  id="departamento_partida"
                  label="DEPARTAMENTO"
                  name="departamento_partida"
                  variant="outlined"
                  color="secondary"
                  onChange={handleDepartamentoPartida}
                  error={textDepartamentoPartida}
                  helperText={
                    textDepartamentoPartida ? "Campo obligatorio" : null
                  }
                  value={
                    inputValues.departamento_partida &&
                    inputValues.departamento_partida
                  }
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  id="direccion_llegada"
                  label="DIRECCION DE LLEGADA"
                  name="direccion_llegada"
                  variant="outlined"
                  color="secondary"
                  onChange={handleDireccionLlegada}
                  error={textDireccionLlegada}
                  helperText={
                    textDireccionLlegada ? "Campo obligatorio" : null
                  }
                  value={
                    inputValues.direccion_llegada &&
                    inputValues.direccion_llegada
                  }
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  id="ciudad_llegada"
                  label="CIUDAD"
                  name="ciudad_llegada"
                  variant="outlined"
                  color="secondary"
                  onChange={handleCiudadLlegada}
                  error={textCiudadLlegada}
                  helperText={
                    textCiudadLlegada ? "Campo obligatorio" : null
                  }
                  value={
                    inputValues.ciudad_llegada &&
                    inputValues.ciudad_llegada
                  }
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  id="departamento_llegada"
                  label="DEPARTAMENTO"
                  name="departamento_llegada"
                  variant="outlined"
                  color="secondary"
                  onChange={handleDepartamentoLlegada}
                  error={textDepartamentoLlegada}
                  helperText={
                    textDepartamentoLlegada ? "Campo obligatorio" : null
                  }
                  value={
                    inputValues.departamento_llegada &&
                    inputValues.departamento_llegada
                  }
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                <TextField
                  id="kilometros_de_recorrido"
                  label="KM DE RECORRIDO"
                  name="kilometros_de_recorrido"
                  variant="outlined"
                  color="secondary"
                  onChange={handleKilometro}
                  value={
                    inputValues.kilometros_de_recorrido &&
                    inputValues.kilometros_de_recorrido
                  }
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  id="cambio_de_fecha"
                  label="CAMBIO DE FECHA DE TRASLADO Y/O PUNTO DE LLEGADA"
                  name="cambio_de_fecha"
                  variant="outlined"
                  color="secondary"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleFechaCambio}
                  value={
                    inputValues.cambio_de_fecha &&
                    inputValues.cambio_de_fecha
                  }
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  id="MOTIVOS"
                  label="MOTIVOS"
                  name="MOTIVOS"
                  variant="outlined"
                  color="secondary"
                  onChange={handleMotivo}
                  value={
                    inputValues.motivo &&
                    inputValues.motivo
                  }
                  fullWidth
                  size="small"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

    </>
  );
}
