import React from "react";
import { useRef } from "react";
import MaterialTable from "material-table";
import Localizacion from "./Localization";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import TableComponents from "./TableComponents";

export function MakeTables({
  title,
  columns,
  data,
  actions,
  icons,
  options,
  classes,
  detailPanel,
  editable,
  componentsAssets,
  isLoading,
  onRowClick,
  onSearchChange,
  onChangePage,
  onChangeRowsPerPage
}) {
  const { current: defaultIcons } = useRef({
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => (
      <FilterList {...props} fontSize="small" ref={ref} />
    )),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    ThumbDownIcon: forwardRef((props, ref) => (
      <ThumbDownIcon {...props} ref={ref} />
    )),
  });

  const { current: localization } = useRef(Localizacion);

  const { current: defaultOptions } = useRef({
    filtering: true,
    exportButton: true,
    exportAllData: true,
    grouping: true,
    pageSize: 10,
    pageSizeOptions: [5, 10, 20, 50, 100],
  });

  const components = TableComponents(componentsAssets);

  return (
    <MaterialTable
      title={title}
      columns={columns}
      data={data}
      isLoading={isLoading}
      icons={icons ? icons : defaultIcons}
      options={options ? options : defaultOptions}
      localization={localization}
      actions={actions}
      components={components}
      detailPanel={detailPanel}
      editable={editable}
      className={classes.root}
      onRowClick={onRowClick}
      onSearchChange={onSearchChange}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
    />
  );
}

export function GetLookups(lookups, name, origin) {
  if (lookups && name) {
    switch (origin) {
      case "lookup":
        var mapped = lookups.values[name].map((x) => {
          return { [x.value]: x.label };
        });
        return Object.assign({}, ...mapped);
      case "other":
        return Object.assign(
          {},
          ...[...new Set(lookups.map((value) => value[name]))].map((value) => {
            return { [value]: value };
          })
        );
      default:
        var mappedDefault = lookups.values[name].map((x) => {
          return { [x.value]: x.label };
        });
        return Object.assign({}, ...mappedDefault);
    }
  } else {
    return {};
  }
}

export default MakeTables;
