import React, { useState, useEffect, useContext } from "react";
import { MakeTables } from "../../components/MaterialTables/MakeTables";
import { alertWarningError } from "../../components/Notificaciones";
import { useStyles } from "../../assets/styles/CustomStyles";
import { useHistory } from "react-router-dom";

import { Grid, Typography } from "@material-ui/core";
import axios from "../../utils/axios";
import LinearProgress from "@material-ui/core/LinearProgress";
import IconButton from '@material-ui/core/IconButton';
import Filter from '@material-ui/icons/FilterList';
import Tooltip from '@material-ui/core/Tooltip';
import Box from "@material-ui/core/Box";
import TypographyBold from "../../components/TypographyBold";

import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Close from "@material-ui/icons/Close";
import Print from "@material-ui/icons/PictureAsPdf";
import CardHeader from '@material-ui/core/CardHeader';
import UserContext from "../../utils/user/UserContext";
import DialogFiltroReporte from "../../components/DialogFiltroReporte";
import AccesoDenegado from "../../components/AccesoDenegado";

export default function FacturacionCobradas() {
  const history = useHistory();

  const classes = useStyles();

  const [open, setOpen] = useState(true);

  const [openLoad, setOpenLoad] = useState(false);
  const [pdf, setPdf] = useState(true);
  const [data, setData] = useState([]);
  const userContext = useContext(UserContext);

  const [desdeFecha, setDesdeFecha] = useState("");
  const [hastaFecha, setHastaFecha] = useState("");


  useEffect(() => {


  }, []);




  const handleGenerar = async (dataFiltro) => {

    let url = `/facturacion/funListaFacturasCobradas.php?fecha_desde=${dataFiltro.fechaDesde}&fecha_hasta=${dataFiltro.fechaHasta}`;

    try {
      setOpenLoad(true);

      const response = await axios.get(url);
      const status = response.status;
      if (status === 200) {
        setHastaFecha(dataFiltro.fechaHasta)
        setDesdeFecha(dataFiltro.fechaDesde)
        let responseData = response.data;
        setData(responseData);
        handleClose();

        if (responseData[0]?.items?.length !== 0) {
          setPdf(false)
        } else {
          setPdf(true)
        }
      }
    } catch (error) {
      handleClose();
      if (error.response) {
        alertWarningError(error.response);
      }
    } finally {
      setOpenLoad(false);
      setOpen(false);
    }

  };

  const handleClickOpen = () => {
    setOpen(true);
  };


  const title = (
    <Typography variant="body1" display="block">
      LISTA FACTURACION

    </Typography>
  );
  const columns = [
    {
      title: "Fecha",
      field: "fecha",
      width: "10%",
    },
    {
      title: "N° Factura",
      field: "nro_factura",
      width: "10%"
    },
    {
      title: "RUC/CI",
      field: "ruc",
      width: "10%"
    },
    {
      title: "Denominación",
      field: "nombre",

    },
    {
      title: "Tipo pago",
      field: "tipo_de_pago",
      width: "10%",

    },
    {
      title: "Total IVA",
      field: "iva",
      width: "10%",

    },
    {
      title: "Total Venta",
      field: "total",
      width: "10%",

    },
  ];

  const actions = [
    {
      icon: "save",
      tooltip: "place-holder",
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
    {
      icon: "save",
      tooltip: "FreeActions-place-holder",
      isFreeAction: true,
      onClick: (event, rowData) => alert("You saved " + rowData.name),
      hidden: true,
    },
  ];

  const options = {
    toolbar: false,
    filtering: false,
    search: false,
    headerStyle: { position: "sticky", top: 0 },
    maxBodyHeight: "55vh",
    paging: false,
    draggable: false,
    rowStyle: {
      fontSize: "small",
    },
  };

  const handleClose = () => {
    setOpen(false);
    setOpenLoad(false);
  };

  const handlePDF = async () => {
    let url = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}extensiones/dompdf/pdfs/listaIngresosCobrados.php?desde=${desdeFecha}&hasta=${hastaFecha}&nombre=${userContext.state.nombreUsu}`
    var win = window.open(url, '_blank');
    win.focus();

  }

  return (
    <>
      {Number(userContext.state.accesos.stock) === 1 ?
        <>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ marginBottom: 10 }}

          >
            <Card className={classes.root}>
              <CardHeader
                action={<>
                  <IconButton disabled={pdf} aria-label="print" onClick={handlePDF}>
                    <Print />
                  </IconButton>
                  <IconButton aria-label="exit" onClick={(e) => {
                    history.goBack();
                  }}>
                    <Close />
                  </IconButton>

                </>

                }
                title={<Typography variant="body1" >
                  INFORMACION DEL REPORTE DE FACTURACION COBRADAS
                  <Tooltip title="Nuevo filtro">

                    <IconButton aria-label="filtro" onClick={handleClickOpen} >
                      <Filter fontSize="medium" color="action" />
                    </IconButton>
                  </Tooltip>
                </Typography>}
              />
              <CardContent >
                <Paper >
                  <Box p={1} mb={2} >
                    <Box ml={1} >
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                        <Grid container direction="row" >
                          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                            <TypographyBold variant="body2" display="block" color="textSecondary">
                              DATOS DEL REPORTE
                            </TypographyBold>
                          </Grid>

                          <Grid item xs={12} sm={12} md={6} lg={6} xl={5}>
                            <Grid container>
                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                                <List dense>
                                  <ListItem>
                                    <ListItemIcon className={classes.buttonFiltro}>
                                      <TypographyBold variant="subtitle2" color="inherit" >
                                        Desde:
                                      </TypographyBold>
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={desdeFecha}
                                    />
                                  </ListItem>
                                  <ListItem>
                                    <ListItemIcon className={classes.buttonFiltro}>
                                      <TypographyBold variant="subtitle2" color="inherit" >
                                        Hasta:
                                      </TypographyBold>
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={hastaFecha}
                                    />
                                  </ListItem>

                                </List>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={12} md={6} lg={6} xl={5}>
                            <Grid container >
                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <List dense>
                                  <ListItem>
                                    <ListItemIcon className={classes.buttonFiltro}>
                                      <TypographyBold variant="subtitle2" color="inherit" >
                                        Total facturación:
                                      </TypographyBold>
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={data[0]?.total}
                                    />
                                  </ListItem>

                                  <ListItem>
                                    <ListItemIcon className={classes.buttonFiltro}>
                                      <TypographyBold variant="subtitle2" color="inherit" >
                                        Total IVA:
                                      </TypographyBold>
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={data[0]?.iva}
                                    />
                                  </ListItem>
                                </List>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Paper>


                <MakeTables
                  //  onRowClick={detalle}
                  title={title}
                  columns={columns}
                  data={data[0]?.items}
                  actions={actions}
                  classes={classes}
                  options={options}
                  componentsAssets={{ classes, }}
                />
              </CardContent>
            </Card >
          </Grid >

          <DialogFiltroReporte open={open} onClose={handleClose} onSetData={handleGenerar} />
        </>
        : <AccesoDenegado />}
    </>
  );
}
